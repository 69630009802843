.sort {
  &__select-holder {
    position: relative;
    color: var(--text);
  }
  &__select {
    appearance: none;
    @extend %b_s_l;
    border: none;
    background-color: var(--sort-select-bg);
    border-radius: var(--br);
    color: var(--text);
    box-sizing: border-box;
    padding: 0.8rem 3rem 0.8rem 1rem;
    height: 3rem;
    transition: all 0.3s;
    display: block;
    width: 100%;
    cursor: pointer;
    outline: none;
    &:hover,
    &:focus {
      background-color: var(--sort-select-bg-hover);
    }

    option {
      font-size: 1.4rem;
      &:not(:checked) {
        background-color: var(--bg);
      }
    }
  }
  &__ico {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
    right: 1rem;
    width: 1rem;
    height: 1rem;
    transition: transform 0.2s;
    background-color: currentColor;
    mask-size: 1rem 0.8rem;
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' fill='none' viewBox='0 0 10 8'%3E%3Cpath stroke='%231C2A50' d='M4.55 7H0m7.27-3H0m10-3H0'/%3E%3C/svg%3E");
  }
  &:has([data-ico="asc"]:checked) &__ico {
    transform: scaleY(-1);
  }
}
