.acclist {
  &__tags {
    & + *,
    * + & {
      margin-top: 3rem;
    }
    & + & {
      margin-top: 1.5rem;
    }
  }
}
