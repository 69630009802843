.mainslider {
  --mainslider-mpb: 0;
  --mainslider-br: var(--br);
  position: relative;
  box-sizing: content-box;
  padding-bottom: var(--mainslider-mpb);
  border-radius: var(--mainslider-br);
  overflow: hidden;
  width: 100%;

  height: 23rem;
  height: clamp(23rem, 61.46vw, 30rem);
  @include media(md) {
    height: 37rem;
    height: clamp(23rem, 32vw, 37rem);
  }
  @include media(xxxxl) {
    height: 14.4535vw;
  }

  @include media(xxxxl) {
    height: 14.4535vw;
  }

  .productlist & {
    grid-column: start / end;
    margin: 0;
    // @include media(md-down) {
    //   --mainslider-mpb: 0;
    //   .mainslider__slider-controls {
    //     display: none;
    //   }
    // }
  }

  .hero & {
    --mainslider-br: 0;
  }

  @include media(md-down) {
    // --mainslider-mpb: 10.4rem;
  }
  // .mainslider-hold & {
  //   pointer-events: none;
  // }

  &__subslider {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: var(--bg-gray);
  }

  &__slider {
    // position: absolute;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // left: 0;
    position: relative;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: var(--bg-gray);
  }
  &__slide {
    position: relative;
    pointer-events: none;
    user-select: none;
  }
  &__slide-link {
    // display: none;
    // pointer-events: auto;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: var(--mainslider-mpb);
    left: 0;
    text-decoration: none !important;
    .swiper-slide-active & {
      // display: block;
      pointer-events: auto;
    }
    &:focus-visible {
      box-shadow: inset 0 0 1.4rem 0.3rem var(--primary);
    }
  }
  &__slide-pic {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--mainslider-br);
  }
  &__slide-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__slider-controls {
    position: absolute;
    z-index: 4;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    height: 6.4rem;
    background-color: var(--bg);
    border-radius: var(--br);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 27.5rem;
    @include media(md) {
      opacity: 0;
      transition: opacity 0.3s;
      .mainslider:hover & {
        opacity: 1;
      }
      // .mainslider:focus-within &,
      .mainslider:has(:focus-visible) & {
        opacity: 1;
      }
    }
    @include media(md-down) {
      opacity: 1;
      background: none;
      height: 0.4rem;
    }
  }
  &__slider-arrow {
    @extend %btn_reset;
    user-select: none;
    position: relative;
    transform: none;
    width: 5.2rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: var(--br);
    background-color: var(--bg);
    color: var(--swiper-theme-color);
    svg {
      width: 1.5rem;
      height: 2.6rem;
      fill: none;
      stroke: currentColor;
    }
    @include media(md-down) {
      display: none;
    }
  }
  &__slider-arrow-ico {
  }
  &__slider-pagination {
    --swiper-pagination-bottom: auto;
    --swiper-pagination-top: 50%;
    --swiper-pagination-bullet-horizontal-gap: 0.8rem;
    --swiper-pagination-bullet-size: 0.4rem;
    --swiper-pagination-bullet-inactive-opacity: 1;
    transform: translate(-50%, -50%) !important;

    .swiper-pagination-bullet {
      @include media(md-down) {
        //   border-radius: var(--br);
        //   transition-property: background-color, width !important;
        //   transition-delay: 1s !important;
        //   --swiper-pagination-bullet-size: 0.4rem;
        //   --swiper-pagination-bullet-width: 0.4rem;
        //   --swiper-pagination-bullet-height: 0.4rem;
        //   &.swiper-pagination-bullet-active {
        //     --swiper-pagination-bullet-width: 4rem;
        //   }
      }
    }
  }

  &__text-holder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: $maxWidth;
    padding: 2.4rem var(--sidegap);
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 2;
    color: #808080;
    mix-blend-mode: difference;
    filter: brightness(1.75);
    @include media(md-down) {
      display: none;
    }
    @include media(lg) {
      padding: 4rem var(--sidegap);
    }
  }
  &__text {
    width: 32rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    @include media(lg) {
      gap: 1.6rem;
    }
  }

  &__lead {
    @extend %h2;
  }
  &__title {
    @extend %h00a;
    font-weight: 700;
  }
  &__note {
    position: relative;
    max-width: 22rem;
    padding-top: 1.5rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    box-sizing: border-box;
    border-top: 1px solid rgba(80, 80, 80, 1);
    &:empty {
      display: none;
    }
  }
}

.swiper-creative .swiper-slide {
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}
