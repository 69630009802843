.compare {
  overflow: hidden;

  &__holder {
    --compare-pt: 2rem;
    --compare-pb: 1.6rem;
    --compare-head: 5rem;
    --compare-head-m: 1.5rem;
    --compare-vgap: 3rem;
    --compare-hgap: var(--sidegap);
    --compare-side-col: 20rem;
    --compare-side-width: calc(var(--compare-side-col) + var(--compare-hgap));
    --compare-title-height: 3rem;
    --compare-item-height: 3.3rem;
    // --compare-item-height: 3.8rem;

    --compare-item-real-width: calc(50vw - var(--sidegap) * 1.5);
    --compare-item-width: calc(var(--compare-item-real-width) + var(--compare-hgap));
    @include media(md) {
      --compare-hgap: 3rem;
      --compare-item-real-width: 25.6rem;
      --compare-title-height: 0rem;
    }
    @include media(xxl) {
      --compare-item-real-width: 28.5rem;
    }

    @extend %b_l_l;
    position: relative;
  }
  a {
    color: var(--secondary);
    text-decoration: none;
  }

  &__scrollplate {
    background-color: var(--bg);
    box-shadow: 0rem 1rem 1rem rgba(0, 0, 0, 0.06);
    padding: 3rem 0;
    z-index: 2;
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;

    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.2s;

    width: 100vw;
    left: 50%;
    position: absolute;
    translate: -50% var(--compare-srollbar-correction, 0);
    transition: translate 0.3s 0.1s;

    .m-compare-fixed & {
      @supports (translate: 0 0) {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__scrollplate-title {
    @include media(md-down) {
      opacity: 0;
      visibility: hidden;
    }
  }
  &__controls {
    margin: 0 0 1rem;
  }
  &__inner {
    position: relative;
    display: grid;
    // align-items: end;
    grid-template-columns: 0rem 1fr;
    // gap: 3rem;
    @include media(md) {
      grid-template-columns: var(--compare-side-width) 1fr;
    }
  }
  &__side {
    padding-top: calc(var(--compare-pt) + var(--compare-head) + var(--compare-head-m));
    padding-bottom: var(--compare-pb);
    width: var(--compare-side-width);
  }

  &__info {
  }
  &__info-list {
  }
  &__info-item {
    @include media(md-down) {
      white-space: nowrap;
    }
  }
  &__info-title {
  }
  &__main {
    min-width: 0;
  }
  &__list {
    display: flex;
    // gap: 3rem;
    @include scrollgaps;
    padding-top: var(--compare-pt);
    padding-bottom: calc(var(--compare-pb) - var(--compare-srollbar-compensate, 0rem));

    overflow: auto;
  }
  &__item {
    flex: 0 0 var(--compare-item-width);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    &:last-child {
      --compare-hgap: 0;
      --compare-item-width: var(--compare-item-real-width);
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    // flex: 1;
  }
}

.prop-card {
  --compare-prophead-side: -0.8rem;
  --compare-prophead-pic: 5rem;
  @include media(md-down) {
    --compare-prophead-side: -0.4rem;
  }
  &__head {
    height: var(--compare-head);
    margin: 0 0 var(--compare-head-m);
    position: relative;
    z-index: 3;
    padding-right: 2rem;
    margin-right: var(--compare-hgap);
    display: flex;
    gap: 0.5rem;
    translate: 0 var(--compare-srollbar-correction, 0);
    transition: translate 0.3s 0.1s;
    &::before {
      content: "";
      position: absolute;
      top: var(--compare-prophead-side);
      right: calc(100% - var(--compare-prophead-pic) + var(--compare-prophead-side));
      bottom: var(--compare-prophead-side);
      left: var(--compare-prophead-side);
      border-radius: 0.5rem;
      background-color: var(--cl-white);
      z-index: -1;
      transition: right 0.2s;
    }
    .m-compare-fixed & {
      &::before {
        right: var(--compare-prophead-side);
      }
    }
  }
  &__delete {
    @extend %btn_reset;
    @extend %extend8;
    background-color: var(--primary);
    position: absolute;
    z-index: 2;
    top: -0.2rem;
    right: -0.2rem;
    width: 2rem;
    height: 2rem;
    mask-size: 1.8rem 1.8rem;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18'%3E%3Cpath stroke='%23E3E9EC' stroke-linejoin='round' stroke-width='1.5' d='M4.13 4.45V15.5h9.75V4.45H4.12Z'/%3E%3Cpath stroke='%23E3E9EC' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M7.7 7.7v4.23M10.3 7.7v4.23M2.5 4.45h13M7.47 1.5h3.08'/%3E%3C/svg%3E");
  }
  &__photo {
  }
  &__pic {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--compare-prophead-pic);
    height: var(--compare-prophead-pic);
  }
  &__img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  &__title {
    @extend %b_l;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    word-break: break-word;
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.2s;
    height: 4.8rem;
    .m-compare-fixed & {
      opacity: 1;
      visibility: visible;
    }
    @include media(md-down) {
      font-size: 1.2rem;
      -webkit-line-clamp: 4;
      height: 5.5rem;
    }
  }
  &__note {
  }
  &__body {
  }
  &__ref {
  }
  &__sell {
  }
  &__price {
  }
  &__info {
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    // flex: 1;
  }
  &__info-list {
    &:first-child {
      @extend %b_l;
    }
  }
  &__info-item {
    @include media(md-down) {
      &::before {
        content: attr(title);
        display: block;
        color: var(--text-secondary);
        height: var(--compare-title-height);
        white-space: nowrap;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        width: 1rem;
      }
    }
  }
  &__info-title {
    opacity: 0;
    visibility: hidden;
  }
}
.compare {
  &__info-title {
    width: calc(100vw - var(--sidegap) * 2);
    max-width: calc(#{$maxWidth_md} - var(--sidegap) * 2);
    text-overflow: ellipsis;
  }
  &__info-item {
    @include media(md-down) {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: calc(90vw - var(--sidegap) * 4);
    }
  }
}
.prop-card {
  &__info-title {
    width: 1rem;
  }
}
.compare,
.prop-card {
  &__info-title {
    @extend %h4;
    white-space: nowrap;
    height: 3.7rem;
    overflow: hidden;
    box-sizing: border-box;
    // padding-right: var(--compare-hgap);
    // @include media(md) {
    //   width: var(--compare-side-width);
    // }
  }
  &__info-list {
    @extend %ul_reset;
    padding-right: var(--compare-hgap);
    padding-bottom: 1rem;
    box-sizing: border-box;
    // width: var(--compare-side-width);
    &:not(:last-child) {
      border-bottom: 1px solid var(--bg-gray);
      margin-bottom: var(--compare-vgap);
    }
  }
  &__info-item {
    height: calc(var(--compare-item-height) + var(--compare-title-height));
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-word;
    & + & {
      margin-top: 0.4rem;
    }
  }
  &__info-list:first-child &__info-item:first-child {
    // --compare-item-height: 6.4rem;
    -webkit-line-clamp: 4;
    @include media(md) {
      -webkit-line-clamp: 3;
      --compare-item-height: 4.8rem;
    }
    @include media(md-down) {
      &:is(.compare__info-item) {
        opacity: 0;
      }
      &::before {
        display: none;
      }
    }
  }
  &__info-list:last-child &__info-item:last-child {
    height: auto;
    overflow: visible;
    display: block;
    -webkit-box-orient: initial;
    -webkit-line-clamp: initial;
  }
}
