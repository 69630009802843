.breadcrumbs {
  padding-top: 1rem;
  position: relative;
  z-index: 2;
  overflow: hidden;

  &__list {
    @extend %ul_reset;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    body:not(.mousable) & {
      @include scrollgaps;
      @include hidescroll;
      flex-wrap: nowrap;
      overflow: auto;
    }
  }

  &__item {
    @extend %b_l_l;
    color: var(--cl-text-light);
    white-space: nowrap;
    display: flex;
    align-items: baseline;
    &:not(:last-child) {
      padding-right: 0.3em;
      &::after {
        content: "/";
        display: inline-block;
        margin-left: 0.3em;
      }
    }
  }
  &__text {
  }
  &__link {
    @extend %extend8;
    text-decoration: none;
    --link-color: var(--cl-blue);
    --link-color-hover: var(--cl-blue-hover);
  }
}
