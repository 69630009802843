/* Grid Layout */

.grid::before {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100%;
  box-sizing: border-box;
  max-width: $maxWidth;
  transform: translateX(-50%);
  z-index: 10001;
  content: "";
  pointer-events: none;

  --grid-color: rgb(0 255 0 / 0.042);
  --grid-side-left: var(--sidegap);
  --grid-side-right: var(--sidegap);
  --grid-gutter: var(--gridgap);
  --grid-columns: 4;
  --grid-column-width: calc((100% - var(--grid-gutter) * var(--grid-columns)) / var(--grid-columns));

  border: solid transparent;
  border-width: 0 calc(var(--grid-side-right) - var(--grid-gutter) / 2) 0 calc(var(--grid-side-left) - var(--grid-gutter) / 2);

  background-image: repeating-linear-gradient(to right, transparent 0, transparent calc(var(--grid-gutter) / 2), var(--grid-color) calc(var(--grid-gutter) / 2), var(--grid-color) calc(var(--grid-column-width) + var(--grid-gutter) / 2), transparent calc(var(--grid-column-width) + var(--grid-gutter) / 2), transparent calc(var(--grid-column-width) + var(--grid-gutter)));

  background-repeat: no-repeat;
}
@include media(md) {
  .grid::before {
    --grid-columns: 8;
  }
}
@include media(lg) {
  .grid::before {
    --grid-columns: 12;
  }
}
