.order {
  display: grid;
  gap: 3rem 7rem;
  @include media(lg) {
    align-items: start;
    grid-template-columns: 1fr 40rem;
  }
  &__info {
    display: grid;
    gap: 5rem;
  }

  &__head {
    display: grid;
    gap: 2.5rem;
    @include media(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__head-title {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  &__title {
    @extend %h2;
  }
  &__title-date {
    white-space: nowrap;
    color: var(--text-secondary);
  }
  &__head-total {
    @extend %b_l_m;
    .rouble {
      color: var(--text-secondary);
    }
  }

  &__list {
    display: grid;
    gap: 2.5rem;
  }
  &__controls {
    display: grid;
    gap: 3rem;
    @include media(lg) {
      position: sticky;
      top: 2rem;
    }
  }
  &__note {
    background-color: var(--bg-gray);
    padding: 2rem 1.5rem;
    border-radius: var(--br);
    display: grid;
    gap: 3rem;
    @include media(md) {
      padding: 2rem;
    }
  }
  &__note-btns {
    @include media(md-down) {
      display: grid;
    }
  }
}
.order-prodcard {
  display: grid;
  grid-template-columns: 7rem auto auto;
  align-items: start;
  gap: 1.5rem;
  @include media(md) {
    grid-template-columns: 7rem auto max-content 20%;
  }
  @include media(lg) {
    grid-template-columns: 7rem auto max-content 20%;
  }
  @include media(xl) {
    grid-template-columns: 7rem auto max-content 25%;
  }
  @include media(xxl) {
    grid-template-columns: 7rem auto max-content 35%;
  }

  &__pic {
    display: block;
    grid-row: span 2;
    background-color: rgba(0, 255, 0, 0.1);
    @include media(md) {
      grid-row: auto;
    }
  }
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 7rem;
    position: relative;
    padding: 0.5rem;
    margin: -0.5rem;
    background-color: var(--cl-white);
    border-radius: 0.4rem;
    box-sizing: content-box;
  }
  &__image-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  &__info {
    grid-column: span 2;
    min-width: 0;
    @include media(md) {
      grid-column: auto;
    }
  }

  &__name {
    @extend %b_l;
    text-decoration: none;
    color: var(--text);
  }
  &__amount {
    @extend %b_l;
    display: flex;
    @include media(md) {
      justify-content: center;
      padding-left: 2.4rem;
      text-align: right;
    }
  }
  &__price {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
  }
  &__price-current {
    @extend %b_l_m;
    white-space: nowrap;

    .rouble {
      color: var(--text-secondary);
    }
  }
}
