.mobnav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $z + 2;
  display: flex;
  justify-content: center;
  background-color: var(--mobnav-bg);
  height: var(--mobnav-height);
  box-sizing: border-box;
  border-top: 1px solid var(--border-color);
  box-sizing: border-box;
  @include media(md) {
    display: none !important;
  }
  &__list {
    @extend %ul_reset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    // padding: 0.8rem 0.2rem;
    // max-width: 37.6rem;
    padding: 0.8rem var(--sidegap);
  }
  &__item {
    --_mobnav-color: var(--mobnav-color);

    // flex: 1;
    min-width: 6rem;

    box-sizing: border-box;
    padding: 0 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.m-active {
      --_mobnav-color: var(--mobnav-primary);
    }
  }
  &__control {
    @extend %btn_reset;
    @extend %b_xs;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.2rem;
    text-decoration: none !important;
    transition: color 0.3s;
    position: relative;
    &,
    &:hover {
      color: var(--_mobnav-color);
    }
    &[data-counter] {
      @extend %counter;
      position: relative;
      &::before {
        position: absolute;
        top: -0.2rem;
        left: 50%;
        transform: translate(-50%);
        margin-left: 1.4rem;
      }
    }
  }
  &__ico {
    width: 2.8rem;
    height: 2.8rem;
    fill: none;
    stroke: currentColor;
  }
}
