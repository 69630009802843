.specs {
  display: grid;
  gap: 3rem;
  max-width: var(--prod-tech-maxw);
  @include media(md) {
    grid-template-columns: 1fr 1fr;
  }
  @include media(lg) {
    gap: 5rem;
  }
  &__block {
  }
  &__title {
    @extend %h4;
    margin: 0 0 1.5rem;
  }
  &__list {
    @extend %ul_reset;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__item {
    @extend %b_s_l;
    display: flex;
    justify-content: space-between;
    break-inside: avoid;
    align-items: flex-end;
  }
  &__name {
    display: flex;
    align-items: center;
  }
  &__val {
    flex: 1;
    display: flex;
    align-items: flex-end;
    text-align: right;
    justify-content: flex-end;

    &::before {
      content: "";
      display: block;
      flex: 1;
      margin: 0 1rem;
      border-top: 1px dashed var(--text-secondary);
      transform: translateY(-0.2rem);
      // min-width: 1rem;
    }
  }
  @include media(md-down) {
    &__list {
      gap: 0;
    }
    &__item {
      gap: 1rem;
      align-items: flex-start;
      font-size: 1.4rem;
      line-height: 1.6rem;
      padding: 1rem 0;
      & + & {
        border-top: 1px solid var(--border-color);
      }
    }
    &__name {
      color: var(--text-secondary);
    }
    &__val {
      font-weight: 500;
      &::before {
        display: none;
      }
    }
  }
}
