.specials {
  position: relative;
  padding: 0 0 36rem;
  @include media(md) {
    padding-bottom: 20rem;
  }
  @include media(lg) {
    padding: 7rem 0 16rem;
  }
  &__text {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
    box-sizing: border-box;
  }
  &__textbox {
    @extend %h2h0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    p {
      margin: 0 !important;
    }
    b {
      @extend %h1;
      font-weight: 500;
      @include media(md) {
        font-size: 3.5rem;
      }
    }
    &_spec {
      max-width: 42.3rem;
      @include media(md) {
        line-height: 1.23;
      }
    }
  }

  &__bg {
    @include scrollgaps;
    // position: relative;
    overflow: hidden;
    height: 34rem;
    pointer-events: none;
    user-select: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    &::before {
      content: "";
      width: 16.4rem;
      height: 16.4rem;
      background-color: var(--bg-gray);
      border-radius: 50%;
      z-index: -1;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-40%, 40%);

      box-shadow: 0rem 3rem 0 8rem var(--bg-gray), -28rem -4rem 0 0 var(--bg-gray);

      @include media(md) {
        width: 20rem;
        height: 20rem;
        transform: translate(30%, 40%);
        box-shadow: -3rem 9rem 0 11rem var(--bg-gray), -46rem -1rem 0 0 var(--bg-gray);
      }
      @include media(lg) {
        transform: translate(70%, 55%);
        box-shadow: 6rem 8rem 0 12rem var(--bg-gray), -83rem 3rem 0 0 var(--bg-gray);
      }
      @include media(xl) {
        transform: translate(50%, 70%);
        box-shadow: 6rem 8rem 0 14rem var(--bg-gray), -93rem 3rem 0 0 var(--bg-gray), 56rem -13rem 0 -2rem var(--bg-gray);
      }
    }

    @include media(md) {
      height: 31rem;
    }
    @include media(lg) {
      height: 53rem;
      overflow: visible;
    }
    @include media(xl) {
      height: 59rem;
    }
  }
  &__bg-pic {
    display: block;
    width: 49rem;
    height: 59rem;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -7%) scale(0.83);
    transform-origin: 50% 0%;
    @include media(md) {
      transform: translate(-16%, -7%) scale(0.9);
    }
    @include media(lg) {
      transform: translate(0%, 0%) scale(0.9);
    }
    @include media(xl) {
      transform: translate(-7%, 0%);
    }
  }
  &__bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.fivestar {
  display: block;
  width: 17rem;
  height: 3rem;
}
