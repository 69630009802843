.textoverflow {
  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--textoverlow-rows, 8);
    @include media(md) {
      display: block;
      -webkit-line-clamp: initial;
    }
  }
  &.m-expanded &__text {
    display: block;
    -webkit-line-clamp: initial;
  }

  &__trigger {
    @extend %btn_reset;
    margin-top: 1em;
    display: none;
    &::before {
      content: attr(data-show);
    }
    @include media(md) {
      display: none;
    }
  }
  &.m-expanded &__trigger {
    &::before {
      content: attr(data-hide);
    }
  }
  &.m-expandable &__trigger {
    display: flex;
  }
}
