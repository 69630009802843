// ======================
// MIND THE GAP
// Version: 1.2.0 (19 Apr 2024)
// by Teftelkin with love and stuff.
// Based on the solution by mr. Koldunsky and mr. Teftelkin
// from the good old kdx-days.
// ======================
// Let's create a few helpers for margins and paddings
// kinda .p-24 [ padding: 24px !important ]
// or .mt-n-40 [ margin-top: -40px !important ]
// or m-0 [ margin: 0 !important ]
// or my-auto [ margin-block: auto !important ]
// or etc...
// ======================

// ======================
// SETTINGS
// ======================
// DEFAULT: set a few gaps based on 8px system or whatever
$gap: 0; // start from zero
$gap-step: 8; // with 8px step
$gap-stop: $gap-step * 10; // stop at 80px

// CUSTOM: if we need some special gaps
// (even with some custom units)
$custom-gaps: (
  // just comment/delete these numbers if not
  10,
  //   160,
  //   320,
  //   2em // creates .m2em, m-2em, .mt2em and so on...
);
// NB: negative gaps will be created automatically
// (for margins only)

// ======================
// THE MAGIC
// ======================
@mixin make-layout-helper($class, $prop, $negative: "") {
  @while $gap <= $gap-stop {
    #{$class + "-" + $gap} {
      #{$prop}: #{($gap * 0.1) + "rem !important"};
    }
    @if $gap != 0 and $negative != "" {
      #{$class + "-n-" + $gap} {
        #{$prop}: #{"-n-" + ($gap * 0.1) + "rem !important"};
      }
    }
    $gap: $gap + $gap-step;
  }
  @each $custom-gap in $custom-gaps {
    $unit: "";
    $custom-gap-to-rem: $custom-gap;
    $num: unitless($custom-gap);
    @if $num {
      $unit: "rem";
      $custom-gap-to-rem: $custom-gap * 0.1;
    }
    #{$class + "-" + $custom-gap} {
      #{$prop}: #{$custom-gap-to-rem + $unit + " !important"};
    }
    @if $negative != "" {
      #{$class + "-n-" + $custom-gap} {
        #{$prop}: #{"-" + $custom-gap-to-rem + $unit + " !important"};
      }
    }
  }
  @if $negative != "" {
    #{$class + "-auto"} {
      #{$prop}: auto !important;
    }
  }
}

// ======================
// CREATE ALL THE STUFF
// ======================
// create margins
@include make-layout-helper(".m", "margin", "-");
@include make-layout-helper(".mt", "margin-top", "-");
@include make-layout-helper(".mr", "margin-right", "-");
@include make-layout-helper(".mb", "margin-bottom", "-");
@include make-layout-helper(".ml", "margin-left", "-");
@include make-layout-helper(".my", "margin-block", "-");
@include make-layout-helper(".mx", "margin-inline", "-");
// create paddings
@include make-layout-helper(".p", "padding");
@include make-layout-helper(".pt", "padding-top");
@include make-layout-helper(".pr", "padding-right");
@include make-layout-helper(".pb", "padding-bottom");
@include make-layout-helper(".pl", "padding-left");
@include make-layout-helper(".py", "padding-block");
@include make-layout-helper(".px", "padding-inline");
