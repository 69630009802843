.catalog {
  position: relative;
  --filter-pad: 1.5rem;
  --filter-pad-top: 2rem;
  --filter-width: 100%;
  @include media(sm) {
    --filter-width: 30rem;
  }
  @include media(md) {
    --filter-pad: 2rem;
  }
  @include media(xl) {
    display: grid;
    grid-template-columns: 1fr calc(30rem - var(--sidegap));
    // align-items: start;
    gap: 2.5rem;
  }

  &__filter-check {
    @extend %visuallyhidden;
    position: fixed;
  }

  &__filter {
    position: fixed;
    // transform: translateX(-100%);
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    z-index: $z + 5;
    background: var(--overlay);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.3s;

    display: flex;
    justify-content: flex-end;

    form {
      display: block;
      position: relative;
      height: 100%;
      @include media(sm-down) {
        width: 100%;
      }
    }
    @include media(xl) {
      position: relative;
      background: none;
      left: auto;
      top: auto;
      height: auto;
      right: auto;
      z-index: auto;
      opacity: 1;
      visibility: visible;
      order: 2;

      top: -2rem;
      margin-right: var(--sidegap-n);
      background-color: var(--filter-bg);
    }
  }

  &__filter-overlay-trigger {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include media(xl) {
      display: none;
    }
  }

  &__filter-inner {
    position: relative;
    background-color: var(--filter-bg);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    transition: transform 0.3s;
    padding: var(--filter-pad-top) var(--filter-pad) var(--filter-pad);
    box-sizing: border-box;
    width: var(--filter-width);

    @include media(xl-down) {
      overflow: auto;
    }
    @include media(xl) {
      width: var(--filter-width);
      height: auto;
      transform: none;
    }
  }

  #show-filter:checked ~ .catalog__filter {
    opacity: 1;
    visibility: visible;
    .catalog__filter-inner {
      transform: translateX(0);
    }
  }

  &__filter-trigger {
    cursor: pointer;
    display: block;
    position: relative;
    color: var(--primary);
    transition: color 0.3s;
    #show-filter:focus-visible ~ * & {
      @extend %fvisible;
    }
    &:hover {
      color: var(--primary-hover);
    }
    @include media(xl) {
      display: none;
    }
  }

  &__filter-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: calc(-1 * var(--filter-pad-top)) 0 1.5rem;
    padding: var(--filter-pad-top) 0 1rem;
    gap: 1.5rem;
    background: inherit;
    position: sticky;
    top: calc(-1 * var(--filter-pad-top));
    z-index: 2;
    @include media(xl) {
      top: auto;
      position: relative;
    }
  }
  &__filter-reset {
    @include media(xl) {
      order: 2;
    }
  }
  &__filter-title {
    @extend %h2;
    margin: 0;
    flex: 1;
  }

  &__filter-close {
    @extend %extend16;
    width: 1.8rem;
    height: 1.8rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    #show-filter:focus-visible ~ * & {
      @extend %fvisible;
    }
    @include media(xl) {
      display: none;
    }
  }
  &__filter-close-ico {
    width: 1.8rem;
    height: 1.8rem;
    fill: none;
    stroke: var(--text);
  }

  &__filter-body {
    flex: 1;
    // overflow: auto;
  }
  &__filter-foot {
    padding: var(--filter-pad) 0;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    position: sticky;
    bottom: calc(-1 * var(--filter-pad));
    margin: 0 0 calc(-1 * var(--filter-pad));
    z-index: 2;
    @include media(xl) {
      bottom: 0;

      display: none;
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    gap: 2.5rem 2rem;
    margin: 0 0 3.5rem;
    @include media(md-down) {
      gap: 1rem 2rem;
      margin: 0 0 1rem;
    }
    @include media(xl) {
      // padding-top: 2rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__head-top {
    display: flex;
    gap: 0.5rem 2rem;

    @include media(md-down) {
      flex-direction: column;
    }
  }

  &__title-holder {
    min-width: 9.2rem;
  }

  &__title {
    @extend %h2h1;
    & + * {
      margin-top: 1rem;
    }
    &_search {
      color: var(--text-secondary);
    }
  }
  &__title-highlight {
    color: var(--text);
    word-break: break-word;
  }
  &__subtitle {
    @extend %h4;
    color: var(--text-secondary);
  }

  &__tags {
    flex: 1;
    min-width: 20.3rem;
    align-self: flex-start;
    display: flex;
    align-items: center;
    min-height: 2.9rem;
    @include media(md-down) {
      flex: 0;
      width: 100%;
      overflow: auto;
      margin: 0.5rem var(--sidegap-n) -0.8rem;
      padding: 0 var(--sidegap);
      padding-bottom: 0.8rem;

      @include scrollgaps;
      @include hidescroll;
    }
  }

  @include media(md-down) {
    .tags__list {
      flex-wrap: nowrap;
    }
    .tags__tag {
      white-space: nowrap;
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.2rem;
    flex-shrink: 0;
  }
  &__sort-right {
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    flex-shrink: 0;
    gap: 2rem;
  }
}
