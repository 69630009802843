// ==============================
// GENERAL RULES
// ==============================
html {
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  &.popup_lock {
    overflow: hidden;
  }

  scroll-padding-top: 2.4rem;
}

html,
body {
  width: 100%;
  box-sizing: border-box;
}

body {
  @extend %b1;
  background-color: var(--bg);
  min-width: 360px;
  color: var(--text);
}

input,
select,
button,
textarea {
  font-family: inherit;
}

p {
  margin: 0;

  p + & {
    margin-top: 1.143em;
  }
}

a {
  color: var(--link-color, var(--primary));
  text-decoration: underline;
  transition: color 0.3s, text-decoration-color 0.3s;

  &:hover {
    color: var(--link-color-hover, var(--primary-hover));
  }

  svg {
    stroke: currentColor;
  }
}
%a_tdn {
  text-decoration-color: transparent;
  &:hover {
    text-decoration-color: currentColor;
  }
}

ol,
ul {
  margin: 3rem 0;
  padding: 0;
  padding-left: 1.2em;
  display: grid;
  gap: 1rem;
}

img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}
svg:not([class]) {
  fill: none;
  stroke: currentColor;
  width: 1.8rem;
  height: 1.8rem;
}

.ico {
  fill: none;
  stroke: currentColor;
  width: 1.8rem;
  height: 1.8rem;
  flex-shrink: 0;
  &_fill {
    fill: currentColor;
    stroke: none;
  }
  &.ico_15 {
    width: 1.5rem;
    height: 1.5rem;
  }
  &.ico_20 {
    width: 2rem;
    height: 2rem;
  }
}

b,
strong {
  font-weight: 700;
}

.main-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100dvh;
  box-sizing: border-box;
  padding-top: var(--header-height);
  transition: padding 0.2s;
  // overflow: hidden;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

%inside-wrapper,
.inside-wrapper,
%inside-wrapper-md,
.inside-wrapper-md,
%inside-wrapper-sm,
.inside-wrapper-sm {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: $maxWidth;
  padding: 0 var(--iw-sidegap);
  box-sizing: border-box;

  .inside-wrapper &:not(.m-save-sidegaps),
  .section__hero_extra &:not(.m-save-sidegaps) {
    --iw-sidegap: 0rem;
  }
  .section__hero:not(.section__hero_extra) & {
    --iw-sidegap: 2rem;
    @include media(md) {
      --iw-sidegap: 2.5rem;
    }
    @include media(xl) {
      --iw-sidegap: 5rem;
    }
  }
}
%inside-wrapper-md,
.inside-wrapper-md {
  max-width: $maxWidth_md;
}
%inside-wrapper-sm,
.inside-wrapper-sm {
  max-width: $maxWidth_sm;
}

.helpnote {
  @extend %h2;
  display: flex;
  // flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem 2.5rem;
  color: var(--text-secondary);
  @include media(md) {
    flex-direction: row;
    align-items: center;
  }
  a {
    text-decoration: none;
  }
  &__text {
    display: flex;
    flex-wrap: wrap;
    gap: 0 0.35em;
    // min-width: 0;
    @include media(xl-down) {
      flex-direction: column;
    }
  }
  &__social {
    flex-shrink: 0;
  }
}

body:not(.cancopy) {
  .js_copy {
    display: none !important;
  }
}

//
