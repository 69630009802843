.searchbox {
  position: relative;
  @include media(xl-down) {
  }
  &__input-holder {
    position: relative;
  }
  &__input {
    padding-right: 7.8rem !important;
  }

  &__btn {
    @extend %btn_reset;
    position: absolute;
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    transition: color 0.3s;
    color: var(--text-secondary);
    &:not(button):not(a):not(label) {
      cursor: default;
    }
  }
  &__btn-ico {
    width: 1.8rem;
    height: 1.8rem;
    fill: none;
    stroke: currentColor;
  }

  .form__input-clear {
    right: 4.8rem;

    &::after {
      content: "";
      width: 1px;
      height: 1.8rem;
      background-color: var(--border-color);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0rem, -50%);
      // opacity: 0;
      // visibility: hidden;
      pointer-events: none;
      transition: 0.2s 0.2s;
    }

    .m-filled ~ &,
    input:not(:placeholder-shown):not([type="hidden"]) ~ & {
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &_search {
    --placeholder: var(--searchbox-placeholder);
    --input-color: var(--searchbox-color);
    --input-bg: var(--searchbox-bg);
    --input-border: var(--searchbox-border);
    .searchbox__btn,
    .form__input-clear {
      color: var(--searchbox-btn-color);
    }
  }

  &_search:focus-within .searchbox__input,
  &_search .searchbox__input:focus {
    --placeholder: var(--searchbox-placeholder-focus);
    --input-color: var(--searchbox-color-focus);
    --input-bg: var(--searchbox-bg-focus);
    --input-border: var(--searchbox-border-focus);

    & ~ .searchbox__btn,
    & ~ .form__input-clear {
      color: var(--searchbox-btn-color-focus);
    }
  }

  &__suggest {
    position: absolute;
    top: calc(100% + 0.2rem);
    left: 0;
    right: 0;
    padding-top: 0.2rem;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-1rem);
    transition-property: opacity, visibility, transform;
    transition-duration: 0.3s;
    z-index: 5;
    @include media(xl) {
    }
    &.m-active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  &_plain {
    .searchbox__input {
      background-color: var(--bg);
      border-radius: 0;
    }
  }
}

.suggest {
  &__content {
    border-radius: var(--br);
    box-shadow: var(--bs15);
    background-color: var(--bg);
    // border-bottom: 1px solid var(--text);
    overflow: auto;
    overscroll-behavior: contain;
    scrollbar-width: thin;

    // max-height: calc(100vh - var(--header-height-top) - var(--header-height-bottom));
    // max-height: calc(100svh - var(--header-height-top) - var(--header-height-bottom));
    max-height: min(40vh, 25rem);
  }
  &__items-list {
    @extend %ul_reset;
  }
  &__item {
    display: flex;
    transition: background 0.3s;
    &.m-active,
    &:hover {
      background-color: var(--bg-gray);
    }
  }
  &__item-text {
    @extend %btn_reset;
    @extend %b_l;
    flex: 1;
    text-decoration: none;
    color: var(--text);
    display: block;
    padding: 1.3rem 1.6rem;
    span {
      color: var(--primary);
    }
  }
}

.locations {
  @extend %ul_reset;
  & + *,
  * + & {
    margin-top: 3rem;
  }
  column-count: 2;
  column-gap: 3rem;
  @include media(md) {
    column-count: 3;
  }
  &__item {
    break-inside: avoid;
    display: flex;
    margin-bottom: 1.5rem;
  }
  &__location {
    @extend %btn_reset;
    @extend %b_l;
    transition: color 0.3s;
    color: var(--text);
    &:hover {
      color: var(--primary);
    }
  }
}
