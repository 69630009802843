.cat-toggle {
  @extend %visuallyhidden;
  position: fixed;
  &:checked {
    & ~ * .mobnav__control[for="cat-toggle"] {
      --_mobnav-color: var(--mobnav-primary);
    }
  }
  &:not(:checked) {
    & ~ .mobcat {
      opacity: 0;
      visibility: hidden;
      transform: translateY(50%);
      pointer-events: none;
    }
  }
  @include media(md) {
    display: none !important;
  }
}

.mobcat {
  position: fixed;
  left: 0;
  right: 0;
  bottom: var(--mobnav-height);
  // height: calc(100vh - var(--mobnav-height));
  // height: calc(100dvh - var(--mobnav-height));
  top: 0;
  background: var(--mobnav-bg);

  z-index: $z + 4;

  // opacity: 0.5;

  transition-property: opacity, visibility, transform;
  transition-duration: 0.3s;

  @include media(md) {
    display: none !important;
  }

  &__close,
  &__subclose {
    @extend %btn_reset;
    width: 6rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: rgba(0, 255, 0, 0.2);
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    color: var(--secondary);
    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
  &__close {
    left: auto;
    right: 0;
  }

  &__holder {
    width: 100%;
    height: 100%;

    overflow-y: auto;
    // -ms-overflow-style: none;
    // scrollbar-width: none;

    overscroll-behavior: contain;
  }

  &__head,
  &__subhead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 6rem;
    border-bottom: 1px solid var(--border-color);
    position: sticky;
    top: 0;
    background: var(--mobnav-bg);
    z-index: 2;
    text-align: center;
    padding: 1rem 6rem;
    box-sizing: border-box;
  }
  &__title,
  &__subtitle {
    @extend %h4;
    font-weight: 500;
    flex: 1;
  }

  &__list,
  &__sublist {
    @extend %ul_reset;
    position: static;
    padding: 0 var(--sidegap);
  }
  &__item,
  &__subitem {
    position: static;
    display: flex;
    & + & {
      border-top: 1px solid var(--border-color);
    }
  }
  &__item {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    // &::after {
    //   content: "";
    //   display: block;
    //   position: relative;
    //   grid-area: 1 / 1 / 2 / 2;
    //   transition: 0s 0.1s;
    //   transition-property: opacity, visibility;
    //   cursor: pointer;
    // }
    // &:hover::after {
    //   z-index: 2;
    //   opacity: 0;
    //   visibility: hidden;
    // }
  }
  &__link,
  &__sublink {
    @extend %h4;
    text-decoration: none !important;
    color: inherit !important;
    padding: 0.8rem 0;
    display: flex;
    height: 5rem;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    gap: 2.4rem;
    flex: 1;
    position: relative;
  }
  &__link {
    grid-area: 1 / 1 / 2 / 2;
  }
  &__item:has(.mobcat__sub) &__link {
    &::after {
      content: "";
      margin-right: 0.7rem;
      width: 1.6rem;
      height: 1.6rem;
      background-color: currentColor;
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-image: var(--arr-ch-right);
    }
  }
  &__sub {
    // display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    // -ms-overflow-style: none;
    // scrollbar-width: none;

    overscroll-behavior: contain;
    background: var(--mobnav-bg);
    z-index: 2;

    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%);
    transition-property: opacity, visibility, transform;
    transition-duration: 0.3s;
  }
  // &__item:hover &__sub {
  &__item.m-active &__sub {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
  &__subhead {
    &::before {
      content: "";
      width: 6rem;
      height: 6rem;
      color: var(--secondary);
      background-color: currentColor;
      position: absolute;
      left: 0;
      top: 0;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='18' fill='none' viewBox='0 0 19 18'%3E%3Cpath fill='%23A3A3A3' fill-rule='evenodd' d='M5.31 1.02c.36.36.36.94 0 1.3L3.96 3.66h8.18a6.8 6.8 0 1 1 0 13.58H4.5a.92.92 0 0 1 0-1.83h7.64a4.96 4.96 0 0 0 4.96-5.15l.91-.04-.91.04a4.96 4.96 0 0 0-4.96-4.77H3.96l1.35 1.35a.92.92 0 1 1-1.3 1.3l-2.9-2.92a.92.92 0 0 1 0-1.3L4 1.03a.92.92 0 0 1 1.3 0Z' clip-rule='evenodd'/%3E%3C/svg%3E");
    }
  }
  &__subtitle {
  }
  &__sublist {
  }
  &__subitem {
  }
  &__sublink {
  }
}
