.hcat {
  position: static;
  &__list {
    @extend %ul_reset;
    display: flex;
    justify-content: space-between;
    @include media(xl-down) {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 2.5rem 0;
    }
    @include media(lg-down) {
      grid-template-columns: repeat(auto-fit, minmax(14.3rem, 1fr));
    }
  }
  &__item {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
  }
  &__link {
    @extend %b_m_l;
    @extend %extend8;
    --extend-size: -0.4vw;
    display: block;
    text-align: center;
    white-space: nowrap;
    width: min-content;
    min-width: 5rem;
    padding: 0 0.7rem;
    box-sizing: border-box;
    @include media(xxl) {
      --extend-size: -1.6rem;
    }
    &_thin {
      min-width: 4rem;
    }
  }
  &__link-ico {
    flex-shrink: 0;
    display: block;
    margin: 0 auto 1rem;
    height: 2.3rem;
    width: auto;
    stroke: none;
    &,
    path {
      fill: var(--hcat-ico-color, var(--secondary));
    }
  }

  &__sub {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--bg);
    box-sizing: border-box;
    padding: 3rem 0;
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility, transform;
    transition-duration: 0.3s;

    @include media(xl-down) {
      height: 0;
      overflow: hidden;
      padding: 0;
    }
    @include media(xl) {
      top: calc(100% - 1.5rem);
      padding-top: 4.5rem;
      box-shadow: var(--bs15a);
      pointer-events: none;
      transform: translateY(2rem);
      transition-delay: 0.2s;
    }
  }
  @include media(xl-down) {
    &__item.m-active &__sub {
      opacity: 1;
      visibility: visible;
      height: auto;
      overflow: auto;
      padding: 3rem 0;
    }
  }
  @include media(xl) {
    // &__item:focus-within &__sub,
    &__item:hover &__sub {
      transition-delay: 0.2s;
      opacity: 1;
      visibility: visible;
      z-index: 3;
      pointer-events: auto;
      transform: translateY(0);
    }
  }
}

.hcatsub {
  &__inner {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    @include media(xl) {
      display: grid;
      grid-template-columns: auto min-content;
      align-items: start;
    }
  }
  &__cat {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }

  &__head {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @include media(xl) {
      display: none;
    }
  }
  &__back {
    @extend %btn_reset;
    color: var(--primary);
  }
  &__title {
    @extend %h1;
  }

  &__catlist {
  }

  &__products {
    --card-min-width: 23.2rem;
    // --card-min-width: 25.2rem;
    display: grid;
    // grid-template-columns: repeat(3, 1fr);
    grid-template-columns: repeat(auto-fit, minmax(var(--card-min-width), 1fr));
    gap: 1rem;

    .prodcard {
      min-width: var(--card-min-width);
    }

    @include media(md, xl-down) {
      .prodcard:first-child:last-child {
        max-width: calc(50% - 0.5rem);
      }
    }
    @include media(lg-down) {
      .prodcard:nth-child(3) ~ .prodcard {
        display: none;
      }
    }
    @include media(lg) {
      // grid-template-columns: repeat(4, 1fr);
    }
    @include media(xl, xxl-down) {
      grid-template-columns: repeat(2, 1fr);
      .prodcard:nth-child(2) ~ .prodcard {
        display: none;
      }
    }
    @include media(xl) {
      // --card-min-width: 24.5rem;
      --card-min-width: 25.5rem;
    }
    @include media(xxl) {
      // grid-template-columns: repeat(auto-fit, minmax(var(--card-min-width), 1fr));
      display: flex;
    }
    @include media(md-down) {
      display: none !important;
    }
  }
}

.hcatsublist {
  @extend %ul_reset;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(22.8rem, 1fr));
  &__item {
    &_all {
      @include media(xl) {
        display: none;
      }
    }
  }
  &__link {
    @extend %b_l;
    // border: 1px solid var(--border-color);
    // color: var(--text);

    color: var(--hcatsub-color);
    border: 1px solid var(--hcatsub-border);
    background-color: var(--hcatsub-bg);

    text-decoration: none !important;
    display: flex;
    align-items: center;
    padding: 0.4rem 1rem 0.4rem 2.5rem;
    gap: 1em;
    border-radius: var(--br);
    min-height: 6.2rem;
    box-sizing: border-box;

    &:hover {
      color: var(--primary);
    }
  }
  &__item_brand &__link {
    padding: 0.4rem 1rem;
    justify-content: center;
  }
  &__pic {
    width: 5rem;
    height: 5rem;
    margin-left: -1.5rem;
    // border-radius: 0.4rem;
    // background-color: var(--cl-white);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__pic-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    font-size: 0.8rem;
    word-break: break-word;
  }
  &__text {
  }
  &__brandpic {
    display: block;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__brandpic-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}
