.btn-copy {
  @extend %btn_reset;
  @extend %extend16;
  flex-shrink: 0;
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  color: var(--text-secondary);
  transition: color 0.3s;
  &:hover,
  &:focus {
    color: var(--primary);
  }
}
.hascopytooltip {
  position: relative;
  .js_copy_text {
    position: absolute;
    // top: 100%;
    // left: 50%;
    // transform: translate(-50%, 0%);
    top: 100%;
    right: 0%;
    transform: translate(0.5rem, 1.5rem);

    padding: 0.8rem;
    border-radius: 0.4rem;
    box-shadow: var(--bs15);
    background-color: var(--bg);
    color: var(--text-secondary);
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 2;
  }
  &.m-copy-success {
    .js_copy_text {
      // transform: translate(-50%, 10%);
      transform: translate(0.5rem, 0.5rem);
      opacity: 1;
      visibility: visible;
    }
  }
}
