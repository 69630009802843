.searchsuggest {
  pointer-events: auto;
  position: absolute;
  top: calc(var(--header-top-height) + var(--header-search-height-fixed));
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-1rem);
  transition-property: opacity, visibility, transform;
  transition-duration: 0.3s;
  z-index: 4;

  box-shadow: var(--bs15);
  background-color: var(--bg);
  overflow: auto;

  height: calc(100vh - var(--header-top-height) - var(--header-search-height-fixed));
  height: calc(100dvh - var(--header-top-height) - var(--header-search-height-fixed));
  display: flex;
  flex-direction: column;
  @include media(md-down) {
    box-shadow: none;
    height: calc(100vh - var(--header-top-height) - var(--header-search-height-fixed) - var(--mobnav-height));
    height: calc(100dvh - var(--header-top-height) - var(--header-search-height-fixed) - var(--mobnav-height));
  }
  @include media(xl) {
    height: auto;
    top: var(--header-top-height);
    max-height: calc(100vh - var(--header-top-height));
    max-height: calc(100svh - var(--header-top-height));
  }
  &.m-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  &__inner {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 132rem;
  }
  &__content {
    @include media(md) {
      flex: 1;
      display: grid;
      grid-template-columns: 33.3rem 1fr;
    }
  }
  &__items {
    padding: 2.5rem 0;
    box-sizing: border-box;
  }
  &__items-list {
    @extend %ul_reset;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include media(md-down) {
      gap: 0;
    }
  }
  &__item {
    display: flex;
    @include media(md-down) {
      & + & {
        border-top: 1px solid var(--border-color);
      }
    }
  }
  &__item-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--suggest-link-color);
    &:hover {
      color: var(--suggest-link-color-hover);
    }
    @include media(md-down) {
      padding: 0.5rem 0;
    }
  }
  &__item-pic {
    display: block;
    height: 6rem;
    width: 6rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
    background-color: var(--cl-white);
    @include media(md-down) {
      width: 4rem;
      height: 4rem;
    }
  }
  &__item-img {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
    @include media(md-down) {
      width: 3rem;
      height: 3rem;
    }
  }
  &__items-btns {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    @include media(md-down) {
      margin-top: 0.5rem;
    }
  }
  &__tags {
    padding: 2.5rem 0;
    box-sizing: border-box;
    @include media(md) {
      padding: 2.5rem;
      border-left: 1px solid var(--border-color-light);
    }
    @include media(lg) {
      max-width: 35.3rem;
    }
    .tags__list {
      gap: 1.5rem;
    }
  }
  &__tags-item {
  }
}
