.features {
  @extend %ul_reset;
  @extend %b_l_l;
  display: grid;
  grid-template-columns: repeat(var(--fea-cols, 1), 1fr);
  gap: var(--fea-gap, 1rem);
  @include media(md) {
    --fea-cols: 3;
  }
  &__item {
    background-color: var(--fea-bg, none);
    display: flex;
    flex-direction: var(--fea-direction, column);
    align-items: var(--fea-align-items, center);
    // justify-content: center;
    text-align: var(--fea-text-align, center);
    gap: var(--fea-item-gap, 1rem);
    padding: var(--fea-padding, 2rem);
    box-sizing: border-box;
    border-radius: var(--br);
  }
  &__ico {
    width: auto;
    height: var(--fea-ico-size, 5rem);
    flex-shrink: 0;
  }

  &__name {
    min-height: var(--fea-name-mh, 0);
    display: flex;
    align-items: center;
  }

  &_ext {
    --fea-gap: 2rem;
    --fea-bg: var(--bg-gray);
    --fea-item-gap: 1.5rem;
    --fea-ico-size: 8rem;
    @include media(md) {
      --fea-gap: 3rem;
      --fea-item-gap: 2.4rem;
    }
  }
  &_hor {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2; /* 24px */

    --fea-gap: 2rem;
    --fea-item-gap: 2.5rem;
    --fea-bg: var(--bg-gray);
    --fea-ico-size: 8rem;
    --fea-direction: row;
    --fea-name-mh: var(--fea-ico-size);
    --fea-text-align: left;
    @include media(md) {
      --fea-cols: 2;
    }
    @include media(lg) {
      --fea-gap: 3rem;
    }
  }
  &_notes {
    @extend %b_l_m;
    --fea-bg: var(--bg-gray);
    --fea-gap: 2.5rem;
    --fea-item-gap: 1rem;
    --fea-ico-size: 8rem;
    --fea-align-items: flex-start;
    --fea-text-align: left;
    --fea-padding: 2rem 1.5rem;
    @include media(md) {
      --fea-cols: 3;
      --fea-padding: 2.5rem;
    }
    &_special {
      --fea-item-gap: 1.5rem;
      @include media(md, lg-down) {
        --fea-cols: 1;
      }
      @include media(md) {
        font-size: 2rem;
        font-weight: 400;
        line-height: 1.2; /* 24px */
      }
    }
  }
}
