.ordspecs {
  display: grid;
  gap: 2.5rem 3rem;
  @include media(md) {
    grid-template-columns: repeat(3, 1fr);
  }
  &__col {
  }
  &__title {
    @extend %b_l_m;
    margin: 0 0 1rem;
  }
  &__list {
    @extend %ul_reset;
    display: grid;
    gap: 1rem;
  }
  &__item {
    @extend %b_l;
  }
}
