.brandhead {
  padding: 5rem 0;
  display: flex;
  align-items: center;
  &__inner {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    text-align: center;
  }
  &__title {
    @extend %h1;
  }
  &__logo {
    height: 5rem;
  }
  &__logo-pic {
    display: block;
    height: 100%;
    width: auto;
  }
  &__logo-img {
    height: 100%;
    width: auto;
  }
}
