.cart {
  display: grid;
  gap: 3rem 4rem;
  @include media(lg) {
    align-items: start;
    grid-template-columns: 1fr 40rem;
  }
  @include media(xxl) {
    gap: 3rem 10rem;
  }
  &__list {
    display: grid;
    gap: 5rem;
    padding: 2rem 0;
  }
  &__controls {
    display: grid;
    gap: 3rem;
    @include media(lg) {
      position: sticky;
      top: 2rem;
    }
  }
  &__promo {
  }
  &__total {
    background-color: var(--bg-gray);
    padding: 2rem 1.5rem;
    border-radius: var(--br);
    @include media(md) {
      padding: 2rem;
    }
    @include media(lg) {
      order: -1;
    }
  }

  &__sticky {
    position: fixed;
    z-index: 5;
    bottom: var(--mobnav-height);
    left: 0;
    right: 0;
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-direction: row;
    background-color: var(--bg);
    padding: 1rem var(--sidegap);
    @include media(md) {
      display: none;
    }
  }
  &__sticky-total {
    @extend %b_l;
    color: var(--text-secondary);
    white-space: nowrap;
  }

  &__sticky-price {
    font-weight: 800;
    font-size: 3rem;
    white-space: nowrap;
  }
  &__sticky-btns {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    .btn {
      flex: 1;
      padding-inline: 0.4rem;
    }
  }
}
.cart-prodcard {
  display: grid;
  grid-template-columns: 9.5rem 8rem 1fr;
  align-items: start;
  gap: 1.5rem;
  @include media(md, lg-down) {
    grid-template-columns: 9.5rem auto max-content;
  }
  @include media(xl) {
    grid-template-columns: 9.5rem auto 12rem 14rem;
  }
  @include media(xxl) {
    grid-template-columns: 9.5rem auto 12rem 14rem max-content;
  }
  &__pic {
    display: block;
    grid-row: span 3;
    background-color: rgba(0, 255, 0, 0.1);
    @include media(md, lg-down) {
      grid-row: span 2;
    }
    @include media(xl) {
      grid-row: span 2;
    }
    @include media(xxl) {
      grid-row: auto;
    }
  }
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9.5rem;
    height: 9.5rem;
    position: relative;
    padding: 0.5rem;
    margin: -0.5rem;
    background-color: var(--cl-white);
    border-radius: 0.4rem;
    box-sizing: content-box;
  }
  &__image-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  &__info {
    grid-column: span 2;
    @include media(md, lg-down) {
      grid-column: auto;
    }
    @include media(xl) {
      grid-column: auto;
    }
  }
  &__id {
    @extend %b_s;
    color: var(--text-secondary);
    margin: 0 0 1.5rem;
    @include media(xl) {
      margin-bottom: 0.5rem;
    }
  }
  &__name {
    @extend %h3h2;
    text-decoration: none;
    color: var(--text);
  }
  &__amount {
    display: flex;
    align-items: center;
    height: 4.5rem;
    @include media(md, lg-down) {
      order: 3;
      justify-content: flex-end;
    }
    @include media(xl) {
      justify-content: flex-end;
    }
  }
  &__price {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    height: 4.5rem;
  }
  &__price-current {
    @extend %h1;
    white-space: nowrap;
    text-align: right;
  }
  &__controls {
    @extend %b_s;
    grid-column: span 2;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3rem;
    height: 4.5rem;
    @include media(md, lg-down) {
      grid-column: auto;
    }
    @include media(xl) {
      grid-column: auto;
    }
    @include media(xxl) {
      padding-left: 5.2rem;
    }
    .btn-link {
      svg {
        transition: stroke 0.3s;
      }
      &:not(:hover, :focus) {
        color: var(--text-secondary);
        svg {
          stroke: var(--border-color);
        }
      }
    }
  }
  &_nocontrols {
    .cart-prodcard__pic {
      grid-row: span 2;
    }
    @include media(md) {
      grid-template-columns: 9.5rem auto auto max-content !important;
      .cart-prodcard__id {
        margin-bottom: 0.5rem;
      }
      .cart-prodcard__info {
        grid-column: auto !important;
      }
      .cart-prodcard__pic {
        grid-row: auto !important;
      }
      .cart-prodcard__amount {
        order: initial !important;
        justify-content: center;
      }
      .cart-prodcard__price {
        padding-left: 1.5rem;
      }
    }
    @include media(xl) {
      // grid-template-columns: 9.5rem auto 10rem 12rem;
    }
    @include media(xxl) {
      // grid-template-columns: 9.5rem auto 10rem 12rem;
    }
  }
}

.total {
  display: grid;
  gap: 2.5rem;
  &__top {
  }
  &__title {
    @extend %b_l;
    color: var(--text-secondary);
    margin: 0 0 0.4rem;
  }
  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2.4rem;
    flex-wrap: wrap;
  }
  &__amount {
    @extend %h2;
  }
  &__price {
    @extend %h1;
    white-space: nowrap;
  }
  &__btns {
    display: grid;
  }
}
