.header-toggle {
  @extend %visuallyhidden;
  position: fixed;
  &:focus-visible ~ * .header__toggle-trigger {
    @extend %fvisible;
  }
  &:checked {
    @include media(xl-down) {
      & ~ .header {
        background-color: var(--bg);
        transition-delay: 0s;
        box-shadow: 0 0 1.6rem 0 rgba(0, 0, 0, 0.1);
        overflow: auto;
        position: fixed;
        pointer-events: auto;
      }
      & ~ * .header__nav,
      & ~ * .header__bottom {
        pointer-events: auto;
        opacity: 1;
        visibility: visible;
        transition-delay: 0.2s;
      }
      & ~ * .header__top {
        height: auto;
      }
      & ~ * .header__top-inner-wrapper {
        grid-template-rows: 1fr;
        transition-delay: 0s;
      }
    }
    @include media(xl) {
      & ~ * .hnav {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
    }

    @include media(md-down) {
      body:not(.hidesearchfield) & ~ * .searchsuggest {
        display: none;
      }
      body:not(.hidesearchfield) & ~ * .header__searchbox {
        padding: 0;
        max-height: 0;
        margin: 0;
        opacity: 0;
        visibility: hidden;
        transition-delay: 0s;
      }
      & ~ * .location-guess {
        height: 0;
        border-width: 0;
        transition-delay: 0s;
      }
      & ~ * .header__top {
        &::before {
          opacity: 1;
          transition-delay: 0s;
        }
      }
    }
  }
  &:not(:checked) {
    // @include media(md, xl-down) {
    //   & ~ * .header__searchbox {
    //     padding: 0;
    //     max-height: 0;
    //     margin: 0;
    //     opacity: 0;
    //     visibility: hidden;
    //     transition-delay: 0s;
    //   }
    // }
    // @include media(xl-down) {
    //   & ~ * .suggest {
    //     opacity: 0;
    //     visibility: hidden;
    //   }
    // }
  }
}

.header {
  @extend %b_l_l;
  background-color: var(--bg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $z + 1;
  overscroll-behavior: contain;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  --link-color: var(--header-color);
  --link-color-hover: var(--header-color);
  color: var(--header-color);
  a {
    text-decoration: none;
  }

  @include media(xl-down) {
    background-color: transparent;
    transition: background 0.2s 0.2s;
    height: 100vh;
    height: 100dvh;
    // overflow: auto;
    overscroll-behavior: contain;
    pointer-events: none;
  }
  @include media(md-down) {
    height: calc(100vh - var(--mobnav-height));
    height: calc(100dvh - var(--mobnav-height));
  }

  &__nav {
    order: 2;
    @include media(xl-down) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 1;
    }
    @include media(xl) {
      order: -1;
    }
  }
  &__nav-inner {
    @include media(xl-down) {
      padding-bottom: 2rem;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
    }
  }
  &__credits {
    @extend %b_l_l;
    display: flex;
    justify-content: space-between;
    padding-top: 5rem;
    @include media(xl) {
      display: none;
    }
    @include media(md-down) {
      display: none;
    }
  }
  &__copy {
  }
  &__by {
  }

  &__top {
    --link-color: var(--header-top-color);
    --link-color-hover: var(--header-top-color);
    color: var(--header-top-color);

    background: var(--header-top-bg);
    box-sizing: border-box;
    position: relative;
    pointer-events: auto;
    @include media(xl-down) {
    }
    @include media(xl) {
      order: -2;
      display: grid;
      align-content: center;
      z-index: 5;
    }
    @include media(md-down) {
      &::before {
        content: "";
        position: absolute;
        top: var(--header-top-height);
        // bottom: 5rem;
        height: 5.6rem;
        left: 0;
        right: 0;
        background-color: var(--header-plate-bg);
        opacity: 0;
        transition: opacity 0.2s;
        transition-delay: 0.2s;
      }
    }
  }
  &__top-wrapper {
    @include media(xl) {
      height: var(--header-top-height);
      display: grid;
      align-content: center;
      position: static;
    }
  }
  &__top-holder {
    display: grid;
    grid-template-columns: [start] 1fr min-content [end];
    grid-template-rows: var(--header-top-height) auto;
    align-items: center;

    @include media(md-down) {
      position: relative;
      grid-template-columns: [start] min-content 1fr min-content [end];
    }
    @include media(xl) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__logo {
    display: block;
    width: 12.5rem;
    filter: var(--logo-filter);
    @include media(md-down) {
      margin: auto;
      // width: 14rem;
      width: 13.8rem;
    }
  }
  &__logo-img {
    width: 100%;
    height: auto;
  }

  &__call {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.8rem;
    height: 3.8rem;
    margin-left: -0.8rem;
    margin-right: 1.7rem;
    @include media(md) {
      display: none !important;
    }
  }
  &__call-ico {
    width: 2.4rem;
    height: 2.4rem;
    fill: none;
    stroke: var(--header-top-control-color);
  }

  &__top-inner-wrapper {
    @include media(xl-down) {
      order: 2;
      // grid-column: span 2;
      grid-column: start / end;
      display: grid;
      grid-template-rows: 0fr;
      transition: grid-template-rows 0.2s 0.2s;
    }
    @include media(xl) {
      flex: 1;
      display: flex;
      align-items: center;

      // justify-content: space-between;
      // &::before {
      //   content: "";
      //   width: 0;
      //   height: 0;
      //   opacity: 0;
      // }
    }
    @include media(md-down) {
      body:not(.hidesearchfield) & {
        min-height: var(--header-search-height);
      }
    }
  }
  &__top-inner {
    overflow: hidden;
    @include media(xl-down) {
      display: grid;
      gap: 2.5rem;
      grid-template-columns: [start] 1fr auto 1fr [end];
    }
    @include media(md-down) {
      // overflow: visible; // don't do this
      gap: 0;
      grid-template-columns: [start] 1fr [end];
      > * + *:not(.header__location) {
        margin-top: 2rem;
      }
    }

    @include media(xl) {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.8rem 2.5rem;
      &::before {
        content: "";
        width: 0;
        height: 0;
        opacity: 0;
      }
    }
  }
  &__searchbox {
    flex: 1;
    @include media(md-down) {
      .hidesearchfield & {
        // margin-top: 1rem;
      }
      body:not(.hidesearchfield) & {
        grid-column: start / end;
        // padding-bottom: 1rem;
        // order: 2;
        transition-property: padding, margin, max-height, opacity, visibility;
        transition-duration: 0.3s;
        transition-delay: 0.2s;
        height: var(--header-search-height);
        max-height: var(--header-search-height);
        overflow: hidden;
        position: absolute;
        width: 100%;
      }
    }
    @include media(md, xl-down) {
      grid-column: start / end;
      padding-top: 1.5rem;
      // padding-bottom: 2.5rem;
      // order: 2;
      // transition-property: padding, margin, max-height, opacity, visibility;
      // transition-duration: 0.4s;
      // max-height: var(--header-search-height);
      // overflow: hidden;
    }
    @include media(md-down) {
      padding-top: 0.5rem;
    }
    @include media(xl) {
      max-width: 35rem;
    }
    @include media(xl, xlc-down) {
      max-width: 5.4rem;
      .searchbox {
        width: 100%;
        transition: width 0.3s;
      }
      .searchbox__input {
        transition: padding 0.3s;
      }
      &:focus-within {
        .searchbox {
          width: 35rem;
        }
      }
      &:not(:focus-within) {
        .searchbox__input {
          padding-inline: 0 !important;
          font-size: 0;
        }
        .searchbox__btn {
          pointer-events: none;
        }
        .form__input-clear {
          display: none;
        }
      }
    }
  }
  &__location {
    display: flex;
    align-items: center;
    @include media(md-down) {
      padding-top: 2rem;
      html:not([data-theme="dark"]) & {
        padding-bottom: 2rem;
      }
    }
  }
  &__location-city {
    @extend %btn_reset;
    @extend %b_l_l;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border: solid;
      border-width: 0.4rem 0.3rem 0;
      border-color: currentColor transparent;
    }
    @include media(md-down) {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.143; /* 16px */
    }
  }
  &__address {
    // display: flex;
    // align-items: center;

    @include media(md-down) {
      &:not(:has(.header__address-link)),
      &:empty {
        display: none;
      }
      order: 2;
      margin-top: 1rem !important;
    }
    @include media(md, xl-down) {
      justify-content: center;
    }
    @include media(xl) {
      max-width: 32rem;
      &:not(:has(.header__address-link)),
      &:empty {
        display: none;
      }
    }
  }
  &__address-link {
    display: flex;
    align-items: center;
    // white-space: nowrap;
    // @include media(md-down) {
    //   font-size: 1.4rem;
    //   font-weight: 500;
    //   line-height: 1.143; /* 16px */
    // }
  }
  &__address-ico {
    // fill: currentColor;
    width: 1.8rem;
    height: 1.8rem;
    fill: var(--header-ico-color);
    stroke: none;
    flex-shrink: 0;
    transform: translate(-0.2rem, -0.2rem);
    margin: -0.5rem 0;
    @include media(md, xl-down) {
      transform: translate(-0.2rem, 0rem);
    }
    @include media(md-down) {
      display: none;
    }
  }

  &__phone {
    @include media(md, xl-down) {
      text-align: right;
    }
  }
  &__phone-link {
    @extend %h4;
    white-space: nowrap;
    display: inline-flex;
    align-items: flex-start;
    gap: 0.3em;
    @include media(md-down) {
      align-items: baseline;
      &,
      & * {
        padding: 0;
        font-size: 1.7rem;
        font-weight: 700;
        line-height: 1.1176;
      }
    }
  }
  &__phone-small {
    @extend %b_s_l;
    padding: 0.1em 0;
  }

  // &__phone-link,
  // &__phone-text {
  //   @include media(md-down) {
  //     display: inline-block;
  //   }
  // }
  &__phone {
    @include media(md-down) {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem 0.5em;
      align-items: baseline;
    }
  }

  &__phone-text {
    @include media(md-down) {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 1.1875;
    }
  }

  //
  &__phone-text,
  &__address-text {
    @extend %b_l_l;
    // margin-top: 0.5rem;
    @include media(md) {
      display: none;
    }
  }

  // &__address-text {
  //   margin-top: 0;
  // }

  &__social {
    @include media(md, xl-down) {
      order: 2;
      margin-left: auto;
      padding-bottom: 1.5rem;
    }
    @include media(md-down) {
      display: none !important;
    }
  }

  &__controls {
    @include media(xl-down) {
      padding-bottom: 1.5rem;
    }
    @include media(md, xl-down) {
      grid-column: span 2;
    }
    @include media(md-down) {
      order: 3;
      padding-bottom: 0;
      height: 3rem;
      html[data-theme="dark"] & {
        margin-top: 4rem !important;
      }
    }
  }

  &__burger {
    margin-left: auto;
  }
  &__toggle-trigger {
  }

  &__nav,
  &__bottom {
    @include media(xl-down) {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition-property: opacity, visibility;
      transition-duration: 0.2s;
      // transition-delay: 0.2s;
    }
  }
  &__bottom {
    position: relative;
    // @include media(xl) {
    //   min-height: var(--header-bottom-height);
    // }
    .inside-wrapper {
      position: static;
    }
    @include media(md-down) {
      display: none;
    }
  }
  &__cat {
    padding: 2.5rem 0;
    @include media(md) {
      padding: 3rem 0;
    }
    @include media(xl) {
      padding: 1.5rem 0;
    }
  }

  .hsocial__link-ico {
    fill: var(--header-ico-color);
  }
}

.hnav {
  @include media(xl-down) {
    padding-top: 2.5rem;
    border-top: 1px solid var(--border-color);
    flex: 1;
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
  }
  @include media(xl) {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
    padding: 2.5rem 3rem;
    box-shadow: var(--bs15);
    background-color: var(--bg);
    transform: translateY(-1rem);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
  }
  @include media(md-down) {
    border: none;
    padding-top: 2rem;
  }
  &__list {
    @extend %ul_reset;
    display: grid;
    gap: 2rem;
    @include media(md, xl-down) {
      display: grid;
      gap: 2rem 2.5rem;
      grid-template-columns: repeat(3, 1fr);
    }
    @include media(xl) {
      gap: 1.5rem;
    }
    @include media(md-down) {
      gap: 0;
    }
  }
  &__item {
    &_cattrigger {
      .hnav__link {
        @extend %h2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &::after {
          content: "";
          margin-right: 0.7rem;
          width: 1.6rem;
          height: 1.6rem;
          background-color: currentColor;
          mask-repeat: no-repeat;
          mask-size: contain;
          mask-image: var(--arr-ch-right);
        }
      }
      @include media(md) {
        display: none !important;
      }
    }
    @include media(md-down) {
      & + & {
        border-top: 1px solid var(--border-color);
      }
    }
  }
  &__link {
    @extend %b_l_l;
    @include media(xl) {
      font-weight: 400;
    }

    @include media(md-down) {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.1875; /* 19px */

      text-decoration: none !important;
      color: inherit !important;
      padding: 0.8rem 0;
      display: flex;
      height: 5rem;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      gap: 2.4rem;
      flex: 1;
      position: relative;
    }
    &[data-counter] {
      @extend %counter;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.8rem;
      &::before {
        order: 2;
        margin: -0.4rem 0;
        translate: 0 -0.1rem;
      }
    }
  }
}

.hsocial {
  @extend %ul_reset;
  display: flex;
  gap: 2rem;
  @include media(xl) {
    gap: 3rem;
  }
  &__item {
    display: flex;
    flex-shrink: 0;
    &_hideme {
      @include media(xl) {
        display: none;
      }
    }
  }
  &__link {
    @extend %extend16;
    display: block;
  }
  &__link-ico {
    width: 2rem;
    height: 2rem;
    stroke: none;
    fill: currentColor;
    &_stroke {
      stroke: currentColor;
      fill: none;
    }
  }
}

.location-guess {
  height: var(--header-location-guess-height);
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: var(--bg-gray);
  border: 0 solid var(--border-color);

  pointer-events: auto;
  flex-shrink: 0;
  transition: height 0.2s, border 0.2s;

  box-sizing: border-box;
  &.m-active {
    border-width: 0.1rem 0;
    transition-delay: 0.2s;
  }
  &__inner {
    display: flex;
    align-items: center;
    gap: 0.8rem 3rem;
  }
  &__qu {
    @extend %b_l_l;
  }
  &__city {
    @extend %b_l;
  }
  &__options {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    font-weight: 400;
  }
}

.hcontrols {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  @include media(md-down) {
    justify-content: space-between;
  }
  &__item {
    @extend %btn_reset;
    align-items: center;
    gap: 0.6rem;
    flex-shrink: 0;
    &:not(.theme-switcher) {
      display: flex;
    }
    @include media(md-down) {
      &:not(.theme-switcher) {
        display: none;
      }
    }
    &[data-counter] {
      position: relative;
      @extend %counter;
      &::before {
        position: absolute;
        top: -0.8rem;
        right: -2.6rem;
        transform: translate(-50%);
      }
    }
  }
  &__ico {
    flex-shrink: 0;
    width: 1.8rem;
    height: 1.8rem;
    fill: none;
    // stroke: currentColor;
    stroke: var(--header-ico-color);
  }
}

.theme-switcher {
  @extend %extend16;
  // html:not([data-theme="dark"]) &__sun {
  //   display: none;
  // }
  // html[data-theme="dark"] &__moon {
  //   display: none;
  // }
}
