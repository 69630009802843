.cattabs {
  @extend %ul_reset;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  &__item {
    display: flex;
    flex: 1;
    min-width: 16rem;
    @include media(md) {
      min-width: 20.4rem;
    }
  }
  &__link {
    flex: 1;
    display: flex;
    background-color: var(--bg-gray);
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem;
    gap: 2rem;
    color: var(--text);
    text-decoration: none !important;
    box-sizing: border-box;
    &:hover,
    &:focus {
      color: var(--hcat-ico-color, var(--secondary));
    }
  }
  &__pic {
    height: 4.5rem;
  }
  &__ico {
    display: block;
    height: 100%;
    width: auto;
    stroke: none;
    &,
    path {
      fill: var(--hcat-ico-color, var(--secondary));
    }
  }
  &__name {
    @extend %b_l_l;
  }
}
