.pagination {
  * + &,
  & + * {
    margin-top: 2rem;
  }
  & + & {
    margin-top: 5.6rem;
  }
  @include media(md) {
    padding: 0 2.1rem;
  }
  &__list {
    @extend %ul_reset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.2rem;
    min-height: 8rem;
  }
  &__item {
    cursor: default;
    &.m-current {
      .pagination__link {
        min-width: 4rem;
        padding: 0 0.4rem;
        background: var(--bg-gray);
        cursor: default;
        &,
        &:hover,
        &:focus {
          color: var(--text);
        }
      }
    }
    &.m-hideme {
      @include media(md-down) {
        display: none;
      }
    }
    &.m-disabled,
    &[disabled] {
      pointer-events: none;
      opacity: 0.25;
    }
  }
  &__link {
    @extend %b_xl;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--br);
    color: var(--text);
    text-decoration: none !important;
    min-width: 4rem;
    height: 4rem;
    box-sizing: border-box;
    padding: 0 0.4rem;
    &.m-disabled,
    &[disabled] {
      pointer-events: none;
      opacity: 0.25;
    }
    @include media(md-down) {
      min-width: 0;
      padding: 0;
    }
  }
}
