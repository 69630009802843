.container {
  .page-head {
    margin: 0 0 2.4rem;
    &__title {
      @extend %h1;
    }
  }
  .catalog {
    &__list {
      display: grid;
      gap: 0 2.4rem;
      @include media(md) {
        gap: 2.4rem;
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
      }
      @include media(xlc) {
        // grid-template-columns: repeat(3, 1fr);
      }
    }
    &__item {
      position: relative;
      display: flex;
      @include media(md-down) {
        border-top: 1px solid var(--border-color);
        box-sizing: border-box;
        padding: 2.4rem 0;
      }
      @include media(md) {
        max-height: 59rem;
        overflow: hidden;
        &:hover {
          z-index: 2;
          flex-direction: column;
          overflow: visible;
          .prod-card {
            border-color: var(--primary);
            box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
  .like-cards {
  }
  .prod-card {
    --link-color: var(--text);
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    border-radius: var(--br);
    transition-property: border-color, box-shadow;
    transition-duration: 0.3s;
    @include media(md) {
      border: 1px solid var(--border-color);
      padding: 2.4rem 1.6rem;
      box-sizing: border-box;
      background-color: var(--bg);
    }

    a {
      text-decoration: none;
    }
    &__pict {
      height: 16rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 1.6rem;
      padding: 1rem;
      box-sizing: border-box;
      border-radius: var(--br);
      background-color: var(--cl-white);
      @include media(md) {
        height: 24rem;
      }

      picture {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &__img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
    &__desc {
      margin: 0 0 2.4rem;
    }
    &__title {
      @extend %h2;
      text-align: center;
    }

    ul {
      margin: 0;
    }
  }
}
