.visuallyhidden,
%visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ul_reset,
%ul_reset {
  padding: 0;
  margin: 0;
  list-style: none;
  list-style-image: none;
  display: block;
  gap: initial;
  > * {
    display: block;
    &:before {
      display: none;
    }
  }
}

.btn_reset,
%btn_reset {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  box-shadow: none;
  text-align: left;
  color: inherit;
}

.m-nowrap {
  white-space: nowrap;
}

%fvisible {
  // box-shadow: 0 0 0 .4rem var(--bg), 0 0 0 .6rem var(--btn-bg, var(--text));
  outline: var(--fv-width, 0.2rem) solid var(--btn-bg, var(--text));
  outline-offset: var(--fv-offset, 0.4rem);
}

.m-aic {
  display: flex;
  align-items: center;
}

%extend8,
%extend16,
%extend32,
.m-extend {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    inset: var(--extend-size, -0.8rem);
  }
}
%extend8 {
  --extend-size: -0.4rem;
}
%extend32 {
  --extend-size: -1.6rem;
}

@mixin scrollgaps {
  margin-inline: var(--sidegap-n);
  padding-inline: var(--sidegap);
}
@mixin hidescroll {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.m-hasico {
  display: inline-flex;
  align-items: center;
  gap: 0 0.7rem;
  svg {
    margin-block: -0.4rem;
  }
}

.m-overflow-hidden {
  overflow: hidden !important;
}

// counter

%counter {
  &::before {
    content: attr(data-counter);
    @extend %b_s;
    min-width: 1.8rem;
    height: 1.8rem;
    box-sizing: border-box;
    border-radius: 4rem;
    padding: 0 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--cl-white);
    background-color: var(--secondary);
  }
}

.text-light {
  color: var(--text-secondary);
}
.text-accent,
.text-highlight {
  color: var(--primary);
}
.text-center {
  text-align: center !important;
}
