.articles {
  &__tags {
    margin: 0 0 3rem;
  }
}

.artcard {
  position: relative;
  box-sizing: border-box;
  border: 1px solid var(--border-color);
  background-color: var(--bg);
  color: var(--text);
  border-radius: var(--br);
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s, color 0.3s;
  text-decoration: none !important;

  .slider & {
    // width: 30rem;
    width: 32.8rem;
    height: 100%;
    // @include media(md) {
    // }
  }

  &:hover,
  &:focus {
    box-shadow: var(--bs15);
    color: var(--text);
  }
  &__pic {
    margin: -1px -1px 0;
    display: block;
    position: relative;
    height: 28rem;
    overflow: hidden;
    border-radius: var(--br) var(--br) 0 0;
  }
  &__pic-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__title {
    @extend %h1;
    display: block;
    padding: 3rem 2rem;
    // padding-right: 1px;
    min-height: 5.7rem;
  }
}

.artlist {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(var(--artlist-cols, 1), 1fr);
  @include media(md) {
    --artlist-cols: 2;
  }
  @include media(md, xl-down) {
    .artcard.m-wide {
      grid-column: span 2;
    }
  }
  @include media(xl) {
    --artlist-cols: 3;
  }
  @include media(xxl) {
    --artlist-cols: 4;

    .artcard.m-wide {
      grid-column: span 2;
    }
  }
}

.article {
  padding: 0 0 3rem;

  .section__head + & {
    margin-top: -2rem;
  }
  &__hero {
    display: block;
    background-color: var(--bg-gray);
    width: 100%;
    height: 22rem;
    overflow: hidden;
    border-radius: var(--br);
    margin: 0 0 3rem;
    @include media(md) {
      height: 35rem;
    }
    @include media(xl) {
      height: 41.6rem;
    }
  }
  &__hero-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__content {
    @extend %inside-wrapper-sm;
    // max-width: 75rem;
    // margin-inline: auto;
  }
}
