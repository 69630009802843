.infobox {
  background-color: var(--bg-gray);
  border-radius: 2.5rem;
  padding: 3rem 1.5rem;
  @include media(md) {
    padding: 3rem 2rem;
  }
  @include media(xl) {
    padding: 5rem 4rem;
  }
  &__title {
    @extend %h0c;
    color: var(--primary);
    margin: 0 0 3rem;
    @include media(md) {
      margin: 0 0 4.5rem;
    }
  }
  &__list {
    @extend %ul_reset;
    display: grid;
    gap: 3rem;
    @include media(md) {
      grid-template-columns: [start] repeat(auto-fit, minmax(50rem, 1fr)) [end];
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border-radius: var(--br);
    background: var(--bg);
    padding: 2rem 1.5rem;
    @include media(md) {
      padding: 2.5rem;
    }
    &_fullwidth {
      @include media(md) {
        grid-column: start / end;
      }
    }
  }
  &__item-title {
    @extend %h1;
  }
  &__item-text {
    @extend %b_l;
  }
  &__item-btns {
    flex: 1;
    align-items: flex-end;
    flex-wrap: wrap;
    display: flex;
  }
}
