.howto {
  position: relative;
  padding: 2rem 1.5rem;
  box-sizing: border-box;
  background-color: var(--bg-gray);
  border-radius: 2rem;

  & + *,
  .textblock & + * {
    margin-top: 5rem;
    @include media(lg) {
      margin-top: 9.5rem;
    }
  }
  @include media(md) {
    padding: 3rem 2rem;
  }
  @include media(xl) {
    padding: 7rem;
  }
  &__head {
    max-width: 75rem;
    margin: 0 auto 3rem;
  }
  & &__title {
    @extend %h0c;
    color: var(--primary);
    margin: 0 0 2.6rem;
  }
  &__text {
    @extend %b_l;
    text-wrap: balance;
  }
  &__list {
    @extend %ul_reset;
    counter-reset: howto;
    max-width: 75rem;
    margin-inline: auto;
    display: grid;
    gap: 1rem;
  }

  &__item {
    @extend %h4;
    font-weight: 700;
    counter-increment: howto;
    gap: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg);
    border-radius: var(--br);
    box-sizing: border-box;
    padding: 2.5rem 1.5rem;
    @include media(md) {
      padding: 3rem 2.5rem;
    }
    @include media(xl) {
      padding: 5rem;
    }

    &::before {
      content: counter(howto);
      display: block;
      @extend %b_l;
      display: block;
      width: 4rem;
      height: 4rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #ebebeb;
      color: #000;
    }
  }
  &_mobcol &__item {
    @include media(md-down) {
      flex-direction: column;
      gap: 3rem;
      align-items: stretch;
      padding: 2rem 1.5rem;
    }
  }
  &__item-text {
    flex: 1;
  }
  &__item-title {
    @extend %h4;
    font-weight: 700;
  }
  &__item-txt {
    @extend %b_l;
    * + & {
      margin-top: 1rem;
    }
  }
  &__item-pics {
    // flex-shrink: 0;
    .paypics {
      gap: 1.6rem;
    }
    .paypics__pic {
      height: 5rem;
    }
  }
}
