.viewtype {
  display: flex;
  align-items: center;
  gap: 1rem;

  @include media(md-down) {
    display: none;
  }

  &__trigger {
    @extend %extend8;
    width: 1.6rem;
    height: 1.6rem;
    transition: 0.3s;
    cursor: pointer;
    position: relative;
    &_grid &-ico {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Crect width='5' height='5' x='1.5' y='1.5' fill='%239DBD49' rx='2'/%3E%3Crect width='5' height='5' x='9.5' y='1.5' fill='%239DBD49' rx='2'/%3E%3Crect width='5' height='5' x='1.5' y='9.5' fill='%239DBD49' rx='2'/%3E%3Crect width='5' height='5' x='9.5' y='9.5' fill='%239DBD49' rx='2'/%3E%3C/svg%3E");
    }
    &_list &-ico {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23DDE1E6' d='M0 2h2v2H0zM4 2h12v2H4zM0 7h2v2H0zM0 12h2v2H0zM4 7h12v2H4zM4 12h12v2H4z'/%3E%3C/svg%3E");
    }
    &:hover .viewtype__trigger-ico {
      background-color: var(--primary);
    }
  }
  &__trigger-ico {
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--cl-gray-200);
    position: relative;
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    mask-position: 50%;
    transition: background 0.3s;
  }

  #viewtype-list:not(:checked) ~ * &__trigger_grid {
    .viewtype__trigger-ico {
      background-color: var(--primary);
    }
  }
  #viewtype-list:checked ~ * &__trigger_list {
    .viewtype__trigger-ico {
      background-color: var(--primary);
    }
  }
  #viewtype-list:focus-visible ~ * &,
  #viewtype-grid:focus-visible ~ * & {
    @extend %fvisible;
  }
}
