.amount {
  display: flex;
  align-items: center;
  height: 4.5rem;
  width: 7.2rem;
  position: relative;
  flex-shrink: 0;
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 1.1rem;
    height: 1px;
    width: 1.6rem;
    transform: translateX(-50%);
    background-color: var(--border-color);
  }
  &__input {
    @extend %h4;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: textfield;
    color: var(--text);
    display: block;
    box-sizing: border-box;
    border: none;
    background: none;
    box-shadow: none;
    outline: none;
    width: 100%;
    text-align: center;
    padding: 0 1.4rem;
  }
  &__control {
    @extend %btn_reset;
    @extend %h2;
    @extend %extend16;
    font-weight: 300;
    position: absolute;
    top: 0.8rem;
    bottom: 0.8rem;
    width: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    mask-repeat: no-repeat;
    mask-position: 50% 50%;
    color: var(--secondary);

    &:hover,
    &:focus-visible,
    &.m-active {
    }
    &_minus {
      left: 0;
    }
    &_plus {
      right: 0;
    }
  }
}
