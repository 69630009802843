.section:has(.credit) {
  overflow: hidden;
}

.credit {
  &__head {
    position: relative;
    margin: 0 0 3rem;
    @include media(md) {
      margin-bottom: 3.4rem;
    }
    @include media(lg) {
      display: grid;
      gap: 2.5rem;
      grid-template-columns: 1fr 1fr 1fr;
      margin-bottom: 8rem;
    }
    @include media(xl) {
      margin-bottom: 8rem;
    }
  }

  &__head-bg-holder {
    @include scrollgaps;
    display: block;
    position: relative;
    overflow: hidden;
    height: 110vw;
    max-height: 56rem;
    margin-top: -4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: -1;

    pointer-events: none;
    user-select: none;
    @include media(md) {
      margin-top: -11rem;
      margin-bottom: 1rem;
    }
    @include media(lg) {
      margin-top: -10rem;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-65%);
      max-height: none;
      height: 65.4rem;
    }
    @include media(xl) {
      margin-top: -17rem;
      transform: translateX(-71%);
    }
  }
  &__head-bg {
    display: block;
    width: 100%;
    height: 100%;
    &_abs {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__head-bg-img {
    width: 100%;
    height: 100%;
    width: auto;
    max-width: none;
    transform: translateX(-7%);
    @include media(sm) {
      transform: translateX(-3%);
    }
  }
  &__head-content {
    @include media(lg) {
      grid-column: 3 / 4;
      margin-top: -6.8rem;
    }
    @include media(xl) {
      margin-top: -12.8rem;
    }
  }
  &__head-title {
    @extend %h0b;
    margin: 0 0 3.4rem;
    @include media(lg) {
      margin-bottom: 6.9rem;
    }
  }
  &__head-text {
    display: grid;
    gap: 1.5rem 5.5rem;
    @include media(md, lg-down) {
      grid-template-columns: 1fr 1fr;
    }
    @include media(lg) {
      gap: 2.6rem;
    }
  }
  &__head-textbox {
  }
  &__notes {
    margin: 0 0 3rem;
    @include media(xl) {
      margin-bottom: 9.6rem;
    }
  }
  &__needs {
    position: relative;
    padding-top: 2.5rem;
    padding-bottom: 35.5rem;
    @include media(md) {
      padding-bottom: 8.7rem;
    }
    @include media(xl) {
      padding-top: 0;
      padding-bottom: 10rem;
    }
  }
  &__needs-bg {
    display: block;
    position: absolute;
    // right: var(--sidegap-n);
    bottom: 0;
    z-index: -1;
    pointer-events: none;
    user-select: none;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
  }
  &__needs-bg-img {
    display: block;
    height: 32.5rem;
    width: auto;
    aspect-ratio: 598 / 672;
    // min-width: -moz-available;
    @include media(lg) {
      // height: 40.5rem;
      height: 39vw;
    }
    @include media(xl) {
      height: 67.2rem;
    }
  }
  &__needs-title {
    @extend %h1a;
    color: var(--primary);
    margin: 0 0 3rem;
    @include media(xl) {
      margin-bottom: 6.4rem;
    }
  }
  &__needs-list {
    @extend %ul_reset;
    display: grid;
    gap: 2.5rem;
    align-items: start;
    @include media(md) {
      grid-template-columns: 1fr 1fr 1fr;
      margin-bottom: 5.5rem;
    }
    @include media(lg) {
      max-width: 96.7rem;
      margin-bottom: 8rem;
    }
  }
  &__needs-item {
    @extend %h4;
    position: relative;
    padding-left: 7.5rem;
    min-height: 7rem;
    display: flex;
    align-items: center;
    @include media(md) {
      min-height: 0;
      // align-items: flex-start;
    }
  }
  &__needs-ico {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 5.5rem;
    @include media(md) {
      // top: 0;
      // transform: none;
    }
  }
  &__needs-name {
  }
  &__conditions {
    margin-top: 3rem;
    background-color: var(--bg-gray);
    border-radius: var(--br);
    padding: 2rem 1.5rem;
    box-sizing: border-box;
    @include media(md) {
      max-width: 40rem;
      padding: 3rem;
    }
    @include media(lg) {
      max-width: 55.4rem;
    }
  }
  &__conditions-title {
    @extend %b_l_m;
    margin: 0 0 1rem;
  }
  &__conditions-list {
    @extend %ul_reset;
    li {
      position: relative;
      padding-left: 1.8rem;
      &::before {
        content: "";
        display: block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background-color: var(--text-light);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      & + * {
        margin-top: 1rem;
      }
    }
  }

  &__howto {
    @include media(xxl) {
      position: relative;
      isolation: isolate;
      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 0;
        bottom: 0;
        width: 50vw;
        background-color: inherit;
      }
    }
  }
}
