// from design
:root {
  --font: #1c2a50;
  --green-new: #72bf42;
  --green-new-hover: #6bb33f;
  --font-light: #8f97ac;
  --blue-new: #1175cb;
  --blue-new-01: rgba(17, 117, 203, 0.1);
  --bg-grey-med-new: #e3e9ec;
  --1: #18191e;
  --wh: #fff;
  --4: #dde1e6;
  --6: #676767;
  --7: #f0efef;
  --bg-grey-light-new: #f7f9fa;
  --red: #d64849;
  --corner: 1rem;

  --font-family: "Roboto", sans-serif;
  --second-family: "Inter", sans-serif;
  --third-family: "SF Pro Display", sans-serif;
  --font3: "Basier Square", sans-serif;
}
// =========

// BREAKPOINTS
$sm: 576px;
$md: 768px;
$lg: 992px;
// $lg: 1056px;
$xl: 1280px;
$xlc: 1350px;
$xxl: 1600px;
$xxxl: 1920px;
$xxxxl: 2560px;

// common
$maxWidth: 160rem;
$maxWidth_md: calc(114rem + var(--iw-sidegap) * 2);
$maxWidth_sm: calc(75rem + var(--iw-sidegap) * 2);
$gridgap: 2rem;

//
$z: 42;
//

$ffamily: var(--font-family);
$ffamilyH: var(--font-family);

:root {
  --cl-white: var(--wh);
  --cl-black: #101116;
  // --cl-text: var(--font);
  --cl-text: #000;
  --cl-text-light: var(--font-light);
  --cl-black-1: var(--1);
  --cl-gray-100: var(--7);
  --cl-gray-200: var(--4);
  --cl-gray-200-hover: #c4c9ce;
  --cl-gray-300: var(--6);
  --cl-gray: var(--bg-grey-med-new);
  --cl-gray-light: var(--bg-grey-light-new);
  --cl-red: var(--red);
  --cl-green: var(--green-new);
  --cl-green-hover: var(--green-new-hover);
  --cl-blue: var(--blue-new);
  --cl-blue-hover: #0a599e;
  --cl-orange: #f2962b;
  --cl-orange-hover: #e57e09;

  --easing: cubic-bezier(0.4, 0, 0, 1);

  --bs15: 0px 1rem 4rem rgba(0, 0, 0, 0.15);
  --bs15a: 0px 2rem 2rem rgba(0, 0, 0, 0.1);

  --gr: linear-gradient(179deg, #7fbb5a 0%, #1175cb 100%);
  --gr-header: linear-gradient(115.88deg, #7fbb5a -28.35%, #1175cb 122.33%);

  --sidegap: 1.5rem;
  --sidegap-n: calc(-1 * var(--sidegap));
  --iw-sidegap: var(--sidegap);
  --gridgap: #{$gridgap};
  --gridgap-n: calc(-1 * var(--gridgap));

  --br: var(--corner);

  //
  --header-top-height: 6.6rem;
  --header-bottom-height: 7.8rem;

  // --header-search-height: 7.1rem;
  --header-search-height: 6.1rem;
  --header-search-height-fixed: 6.1rem;
  --header-location-guess-height: 0rem;

  --header-cat-height: 6.8rem;
  // --header-cat-height: 0rem;

  --prod-tech-maxw: 93rem;

  &:has(.location-guess.m-active) {
    --header-location-guess-height: 4.6rem;
  }
  &:has(.hidesearchfield) {
    --header-search-height: 0rem;
  }

  --header-height: calc(var(--header-top-height) + var(--header-location-guess-height) + var(--header-search-height) + var(--header-cat-height));

  --cartplate-height: 5.4rem;
  --mobnav-height: 6rem;

  //

  @media (min-width: $md) {
    --header-cat-height: 0;
    --header-height: calc(var(--header-top-height) + var(--header-location-guess-height));
    --sidegap: 2.5rem;
  }
  @media (min-width: $md) and (max-width: #{$xl - 0.02}) {
    --header-top-height: 7rem;
    --header-search-height: 8.6rem;
    --header-search-height-fixed: 8.6rem;
  }
  @media (min-width: $xl) {
    --header-top-height: 6.6rem;
    // --sidegap: 3rem;
    --header-height: calc(var(--header-top-height) + var(--header-location-guess-height) + var(--header-bottom-height) + 2rem);
  }

  --overlay: rgb(28, 42, 80, 0.8);

  --bg: var(--cl-white);
  --bg-gray: var(--cl-gray-light);
  --text: var(--cl-text);
  --text-secondary: var(--cl-text-light);
  --text-light: var(--cl-gray);
  --primary: var(--cl-green);
  --primary-hover: var(--cl-green-hover);
  --primary-active: var(--cl-green);
  --secondary: var(--cl-blue);
  --secondary-hover: var(--cl-blue-hover);
  --disabled: var(--cl-gray-light);
  --inverted: var(--cl-white);
  --error: var(--cl-red);
  --border-color: var(--cl-gray);
  --border-color-light: var(--cl-gray-200);
  --placeholder: var(--cl-text-light);

  --header-color: var(--text);
  --header-ico-color: currentColor;
  --header-top-color: var(--cl-white);
  --header-top-control-color: var(--cl-white);
  --header-top-bg: var(--gr-header);
  --logo-filter: grayscale(1) brightness(5);

  --header-plate-bg: var(--cl-gray-100);

  --searchbox-placeholder: var(--cl-white);
  --searchbox-color: var(--cl-white);
  --searchbox-bg: rgba(255, 255, 255, 0.12);
  --searchbox-border: rgba(255, 255, 255, 0.2);
  --searchbox-placeholder-focus: var(--cl-text);
  --searchbox-color-focus: var(--cl-text);
  --searchbox-bg-focus: rgba(255, 255, 255, 1);
  --searchbox-border-focus: rgba(255, 255, 255, 1);
  --searchbox-btn-color: var(--input-color);
  --searchbox-btn-color-focus: var(--cl-text-light);

  @media (max-width: #{$md - 0.02}) {
    --header-ico-color: var(--primary);
    --header-top-color: var(--cl-text);
    --header-top-control-color: var(--cl-blue);
    --header-top-bg: var(--bg);
    --logo-filter: none;

    --searchbox-placeholder: var(--cl-text-light);
    --searchbox-color: var(--cl-text);
    --searchbox-bg: var(--cl-gray-light);
    --searchbox-border: var(--cl-gray);
    --searchbox-placeholder-focus: var(--cl-text-light);
    --searchbox-color-focus: var(--cl-text);
    --searchbox-bg-focus: rgba(255, 255, 255, 1);
    --searchbox-border-focus: var(--cl-gray);
    --searchbox-btn-color: var(--cl-text-light);
    --searchbox-btn-color-focus: var(--cl-text-light);
  }

  --hcatsub-color: var(--cl-text);
  --hcatsub-border: var(--cl-gray);
  --hcatsub-bg: var(--cl-white);

  --suggest-link-color: var(--cl-black-1);
  --suggest-link-color-hover: var(--primary);

  --mobnav-bg: var(--cl-white);
  --mobnav-color: var(--cl-text-light);
  --mobnav-inverted: var(--cl-white);
  --mobnav-primary: var(--secondary);

  --swiper-theme-color: var(--secondary);
  --swiper-arrow-bg: #f9f9f9;
  --swiper-disabled: var(--cl-gray);
  --swiper-pagination-bullet-inactive-color: var(--cl-gray-200);

  @media (max-width: #{$md - 0.02}) {
    --swiper-pagination-bullet-inactive-color: #222222;
  }

  --prodcard-bg: var(--cl-white);
  --prodcard-border: var(--cl-gray);
  --prodcard-color: var(--cl-text);
  --prodcard-color-second: var(--cl-text-light);
  --prodcard-color-status: var(--cl-gray-300);

  --filter-bg: var(--cl-gray-light);
  --filter-block-bg: var(--cl-white);
  --filter-input-bg: var(--cl-gray-light);
  --filter-input-color: var(--cl-text);
  --filter-color: var(--text);
  --filter-color-secondary: var(--cl-text-light);
  --filter-label-color: var(--cl-text-light);

  --btnpill-color: var(--text);
  --btnpill-bg: var(--cl-gray-200);
  --btnpill-bg-hover: var(--cl-gray-200-hover);

  --form-check-border: rgba(9, 30, 66, 0.14);
  --form-check-disabled: var(--cl-text-light);

  --tag-color: var(--cl-black-1);
  --tag-color-hover: var(--cl-black-1);
  --tag-color-hover: var(--cl-black-1);
  --tag-color-active: var(--cl-black-1);
  --tag-bg: var(--cl-white);
  --tag-border: var(--cl-gray-200);
  --tag-more-bg: var(--cl-gray-200);
  --tag-more-bg-hover: var(--cl-gray-200-hover);

  --sort-select-bg: var(--cl-gray-200);
  --sort-select-bg-hover: var(--cl-gray-200-hover);

  --tabs-trigger: var(--text-secondary);
  --tabs-trigger-primary: var(--text);

  --tag-lightborder: var(--border-color);
  --tag-darkborder: transparent;
}

// ====================================
// ====================================
// ====================================

@mixin darktheme {
  --header-cat-height: 7.8rem;

  --overlay: rgb(0, 0, 0, 0.7);

  --bg: var(--cl-black);
  --bg-gray: #3b3b3b;
  --text: var(--cl-white);
  --text-secondary: #ececec;
  --text-light: var(--cl-gray);
  --primary: var(--cl-green);
  --primary-hover: var(--cl-green-hover);
  --primary-active: var(--cl-green);
  --secondary: var(--cl-green);
  --secondary-hover: var(--cl-green-hover);
  --disabled: var(--cl-gray-light);
  --inverted: var(--cl-black);
  --error: var(--cl-red);
  --border-color: var(--cl-gray-300);
  --border-color-light: var(--cl-gray-200);
  --placeholder: var(--text-secondary);

  --header-color: var(--text);
  --header-ico-color: currentColor;
  --header-top-color: var(--cl-white);
  --header-top-control-color: var(--cl-white);
  --header-top-bg: var(--gr-header);
  --logo-filter: grayscale(1) brightness(5);

  --header-plate-bg: transparent;

  --searchbox-placeholder: var(--cl-white);
  --searchbox-color: var(--cl-white);
  --searchbox-bg: rgba(255, 255, 255, 0.12);
  --searchbox-border: rgba(255, 255, 255, 0.2);
  --searchbox-placeholder-focus: var(--cl-text);
  --searchbox-color-focus: var(--cl-text);
  --searchbox-bg-focus: rgba(255, 255, 255, 1);
  --searchbox-border-focus: rgba(255, 255, 255, 1);
  --searchbox-btn-color: var(--input-color);
  --searchbox-btn-color-focus: var(--cl-text-light);

  --hcatsub-color: var(--cl-text);
  --hcatsub-border: var(--bg);
  --hcatsub-bg: var(--cl-white);

  --suggest-link-color: var(--text);

  --mobnav-bg: var(--cl-black);
  --mobnav-color: var(--cl-gray);
  --mobnav-inverted: var(--cl-black);
  --mobnav-primary: var(--primary);

  --swiper-theme-color: var(--secondary);
  --swiper-arrow-bg: var(--bg-gray);
  --swiper-disabled: var(--cl-gray-300);
  // --swiper-pagination-bullet-inactive-color: var(--cl-gray-300);

  --filter-bg: var(--cl-black);
  --filter-block-bg: #1f2229;
  --filter-input-bg: var(--cl-gray-light);
  --filter-input-color: var(--cl-text);
  --filter-color: var(--text);
  --filter-color-secondary: var(--cl-text-light);
  --filter-label-color: var(--cl-text-light);

  --btnpill-color: var(--text);
  --btnpill-bg: #0071ff;
  --btnpill-bg-hover: #0560d0;

  --form-check-border: var(--cl-text-light);
  --form-check-disabled: var(--cl-text-light);

  --tag-color: var(--text);
  --tag-color-hover: var(--text);
  --tag-color-active: var(--cl-white);
  --tag-bg: #1f2229;
  --tag-border: #3b3b3b;
  --tag-more-bg: #1f2229;
  --tag-more-bg-hover: #3b3b3b;

  --sort-select-bg: #1f2229;
  --sort-select-bg-hover: #3b3b3b;

  --tabs-trigger: rgba(255, 255, 255, 0.5);
  --tabs-trigger-primary: var(--text);

  --tag-lightborder: transparent;
  --tag-darkborder: var(--border-color);
}

@media (prefers-color-scheme: dark) {
  html:not([data-theme="light"]) {
    @include darktheme;

    @media (max-width: #{$md - 0.02}) {
      .theme-switcher-wrapper {
        transform: translateY(-2rem);
        &::after {
          content: attr(data-dark);
        }
        &::before {
          left: 50%;
          right: 0;
          transition-delay: 0.2s, 0s;
        }
        [data-settheme="dark"] {
          color: var(--cl-white);
          pointer-events: none;
        }
      }
    }
  }
  @media (min-width: $md) {
    [data-settheme="dark"] {
      display: none;
    }
    [data-settheme="light"] {
      display: flex;
    }
  }
}
@media (prefers-color-scheme: light) {
  @media (min-width: $md) {
    [data-settheme="light"] {
      display: none;
    }
    [data-settheme="dark"] {
      display: flex;
    }
  }
  html:not([data-theme="dark"]) {
    @media (max-width: #{$md - 0.02}) {
      .theme-switcher-wrapper {
        [data-settheme="light"] {
          color: var(--cl-white);
        }
      }
    }
  }
}

[data-theme="light"] [data-settheme="dark"],
[data-theme="dark"] [data-settheme="light"] {
  @media (min-width: $md) {
    display: flex;
  }
}
[data-theme="light"] [data-settheme="light"],
[data-theme="dark"] [data-settheme="dark"] {
  @media (min-width: $md) {
    display: none;
  }
}

[data-theme="light"] {
  @media (max-width: #{$md - 0.02}) {
    .theme-switcher-wrapper {
      [data-settheme="light"] {
        color: var(--cl-white);
      }
    }
  }
}
[data-theme="dark"] {
  @include darktheme;

  @media (max-width: #{$md - 0.02}) {
    .theme-switcher-wrapper {
      transform: translateY(-2rem);
      &::after {
        content: attr(data-dark);
      }
      &::before {
        left: 50%;
        right: 0;
        transition-delay: 0.1s, 0s;
      }
      [data-settheme="dark"] {
        color: var(--cl-white);
        pointer-events: none;
      }
    }
  }
}

// ====================================
// ====================================
// ====================================

// icons

:root {
  --ico-tick: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' fill='none' viewBox='0 0 12 10'%3E%3Cpath fill='%2372BF42' d='m.5 5.05.94-.82c1.1.5 1.8.88 3.04 1.72A25.94 25.94 0 0 1 11.19.5l.31.67C9.14 3.1 7.42 5.28 4.94 9.5A45.8 45.8 0 0 0 .5 5.05Z'/%3E%3C/svg%3E");

  --arr-ch-right: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23fff' stroke-width='2' d='m5 1 9 9-9 9'/%3E%3C/svg%3E");
}
