.contact {
  display: grid;
  gap: 5rem;
  &__info {
    display: grid;
    gap: 3rem;
    @include media(xl) {
      grid-template-columns: 39.5rem auto;
      gap: 5rem;
      min-height: 60rem;
    }
    @include media(xxl) {
      grid-template-columns: 63.8rem auto;
    }
  }
  &__text {
    padding: 2rem 1.5rem;
    background-color: var(--bg-gray);
    border-radius: var(--br);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 3rem;
    @include media(md) {
      padding: 3rem;
    }
  }
  &__text-block {
    @extend %h2;
    p + p {
      margin-top: 1rem;
    }
    a {
      text-decoration: none;
    }
    b {
      font-weight: 500;
    }
  }
  &__text-block-title {
    font-weight: 500;
    color: var(--text-secondary);
    margin: 0 0 1rem;
  }
  &__social {
  }
  &__map {
    width: 100%;
    height: 40.7rem;
    position: relative;
    border-radius: var(--br);
    overflow: hidden;
    > div,
    iframe {
      width: 100%;
      height: 100%;
      border: none !important;
    }

    @include media(md) {
      height: 51rem;
    }
    @include media(xl) {
      height: 100%;
    }
  }
  &__map-pic {
    display: block;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__photo {
    padding: 2rem 1rem;
    background-color: var(--bg-gray);
    border-radius: var(--br);
    box-sizing: border-box;
    @include media(md) {
      padding: 3rem;
    }
  }
  &__photo-title {
    @extend %h0;
    margin: 0 0 3rem;
    @include media(md-down) {
      padding: 0 1rem;
    }
  }
}

.steps {
  @extend %ul_reset;
  counter-reset: steps;
  display: grid;
  gap: 2rem;
  @include media(md) {
    grid-template-columns: repeat(auto-fit, minmax(58rem, 1fr));
  }
  &__item {
    counter-increment: steps;
    background-color: var(--bg);
    border-radius: var(--br);
    box-sizing: border-box;
    padding: 2rem 1.5rem;
    display: grid;
    gap: 3rem;
    @include media(md) {
      padding: 3rem 2.5rem;
    }
    @include media(xl) {
      padding: 5rem;
    }
  }
  &__text {
    @extend %h4;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 1.8rem;
    &::before {
      content: counter(steps);
      @extend %b_l;
      display: block;
      width: 4rem;
      height: 4rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #ebebeb;
      color: #000;
    }
  }
  &__pic {
    display: block;
    width: 100%;
    // height: 23.2rem;
    height: 62vw;
    overflow: hidden;
    border-radius: var(--br);
    @include media(md) {
      height: 34.5rem;
    }
    @include media(lg) {
      height: 40.2rem;
    }
  }
  &__pic-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
