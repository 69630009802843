.sliderprod,
.slidernewof {
  overflow: hidden;
}

.slider {
  margin: 0 calc(-1 * var(--gridgap) / 2);

  &__controls {
    display: flex;
    align-items: center;
    gap: 0;
    &_alt {
      @include media(xl) {
        position: absolute;
        top: calc(50% + 1.8rem);
        right: 0;
        left: 0;
        z-index: 3;
        height: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
    @include media(md-down) {
      display: none;
    }
  }
  &:hover .slider__controls {
    opacity: 1;
  }
  &__controls_alt &__arrow {
    @include media(xl) {
      border-radius: var(--br);
      background-color: var(--swiper-theme-color);
      color: var(--cl-white);
      transition: background 0.3s;
      &.swiper-button-disabled {
        background-color: var(--swiper-disabled);
        color: var(--cl-white);
        pointer-events: none;
      }
    }
  }

  &_prod {
    overflow: visible;
    .slider__slide {
      // width: 27rem;
      height: auto;
      .prodcard {
        width: 27rem;
        height: 100%;
      }
    }
    @include media(md-down) {
      &:not(.swiper-initialized) {
        margin-inline: 0;
        padding-inline: 0;
        .slider__head {
          padding-inline: 0;
        }
        .swiper-wrapper {
          box-sizing: border-box;
          width: auto;
          overflow: auto;

          margin-inline: var(--sidegap-n);
          padding-inline: calc(var(--sidegap) - var(--gridgap) / 2);

          @include hidescroll;
        }
        &.m-over-hidden .swiper-wrapper {
          overflow: hidden;
        }
      }
    }
  }

  &_newof {
    overflow: visible;
    margin: 0;
    .slider__head {
      padding-left: var(--sidegap);
      @include media(md) {
        padding-left: 0;
      }
    }
    .slider__controls {
      @include media(xl-down) {
        display: none;
      }
    }
    @include media(md-down) {
      margin: 0 var(--sidegap-n);
    }

    // @include media(md-down) {
    //   .swiper-wrapper {
    //     display: flex;
    //     flex-direction: column;
    //     gap: 1rem;
    //   }
    //   .slider__slide {
    //     width: 100%;
    //     height: auto;
    //     img {
    //       width: 100%;
    //       height: auto;
    //       max-width: none;
    //     }
    //   }
    // }
  }

  &__head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 1.5rem;
    gap: 1.5rem 4rem;
    padding: 0 calc(var(--gridgap) / 2);
  }
  &__title {
    @extend %h3;
    margin: 0;
  }

  &__tags {
    width: 100%;
  }

  &__slide {
    width: auto;
    padding: 0 calc(var(--gridgap) / 2);
    box-sizing: border-box;
  }

  &__arrow {
    @extend %btn_reset;
    user-select: none;
    position: relative;
    transform: none;
    width: 4.2rem;
    height: 5.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: var(--swiper-arrow-bg);
    color: var(--swiper-theme-color);
    transition: background 0.3s, color 0.3s;
    &.swiper-button-disabled {
      color: var(--swiper-disabled);
      pointer-events: none;
    }

    svg {
      width: 1rem;
      height: 1.6rem;
      fill: none;
      stroke: currentColor;
    }
    &_prev {
    }
    &_next {
    }
  }
}

.slidernewof {
  &__slide {
    padding: 0 1px 0 0;
  }
  &__item {
    display: block;
    position: relative;
    text-decoration: none !important;
    &,
    &:hover,
    &:focus {
      color: var(--cl-text);
    }
  }
  &__pic {
    display: block;
    position: relative;
    width: 58.5rem;
    max-width: 86vw;
    aspect-ratio: 585/340;
  }
  &__pic-img {
    width: 100%;
    height: auto;
  }
  &__content {
    position: absolute;
    left: 2rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5rem;
    width: 45%;
    @include media(md) {
      gap: 1.4rem;
    }
  }
  &__title {
    @extend %h2h1;
    display: block;
    min-height: 4.8rem;
    @include media(md) {
      min-height: 8.7rem;
    }
  }
  .btn {
    @extend %btn-special;
  }
}
