.social {
  @extend %ul_reset;
  display: flex;
  gap: 3rem;
  &__item {
    display: flex;
    flex-shrink: 0;
  }
  &__link {
    @extend %extend16;
    display: block;
    --link-color: var(--text);
    --link-color-hover: var(--primary-hover);
  }
  &__link-ico {
    width: 2.5rem;
    height: 2.5rem;
    stroke: none;
    fill: currentColor;
  }
}
