.theme-switcher-wrapper {
  &::after {
    @extend %h4;
  }
  @include media(md-down) {
    transition: transform 0.2s;
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    height: 3rem;
    padding: 0.6rem 0.8rem;
    gap: 1.8rem;
    border-radius: 4rem;
    background-color: var(--cl-gray);
    color: var(--primary);
    isolation: isolate;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 50%;
      top: 0;
      bottom: 0;
      z-index: -1;
      border-radius: 4rem;
      background-color: var(--primary);
      transition-property: left, right;
      transition-duration: 0.2s;
      transition-delay: 0s, 0.1s;
      // transition-timing-function: cubic-bezier(0.88, 0.18, 1, 0.67);
    }
    &::after {
      content: attr(data-light);
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(1rem, -50%);
      white-space: nowrap;
      color: var(--text);
    }
  }
}
.theme-switcher {
  @include media(md-down) {
    -webkit-tap-highlight-color: transparent;
    transition: color 0.2s;
    .hcontrols__ico {
      stroke: currentColor;
    }

    &[data-settheme="light"] {
      &::before {
        right: -5rem;
      }
    }
    &[data-settheme="dark"] {
      &::before {
        left: -5rem;
      }
    }
  }
}
