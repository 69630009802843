.productlist {
  position: relative;
  display: grid;
  grid-template-columns: [start] repeat(var(--productlist-cols, 1), var(--productlist-col-width, 1fr)) [end];
  gap: 2rem;
  & + & {
    margin-top: 2rem;
  }
  @include media(sm) {
    // grid-template-columns: [start] repeat(auto-fill, minmax(var(--prodcard-width, 25rem), 1fr)) [end];
    --productlist-cols: auto-fill;
    --productlist-col-width: minmax(var(--prodcard-width, 25rem), 1fr);
  }
  @include media(lg) {
    .catalog & {
      // --prodcard-width: 22rem;
      // grid-template-columns: [start] repeat(4, 1fr) [end];
      --productlist-cols: 4;
      --productlist-col-width: 1fr;
    }
  }
  @include media(xl) {
    // grid-template-columns: [start] repeat(5, 1fr) [end];
    --productlist-cols: 5;
    --productlist-col-width: 1fr;
  }

  .mainslider {
    order: -1;

    & ~ .prodcard:nth-child(2),
    & ~ .prodcard:nth-child(3),
    & ~ .prodcard:nth-child(4) {
      order: -2;
    }
    @include media(sm) {
      & ~ .prodcard:nth-child(5) {
        order: -2;
      }
    }
    @include media(md) {
      & ~ .prodcard:nth-child(6),
      & ~ .prodcard:nth-child(7) {
        order: -2;
      }
    }
    @include media(lg) {
      & ~ .prodcard:nth-child(8),
      & ~ .prodcard:nth-child(9) {
        order: -2;
      }
    }
    @include media(xl) {
      & ~ .prodcard:nth-child(10),
      & ~ .prodcard:nth-child(11),
      & ~ .prodcard:nth-child(12),
      & ~ .prodcard:nth-child(13) {
        order: -2;
      }
    }
  }
}

.prodcard {
  position: relative;
  padding: 1rem 2rem 2rem;
  box-sizing: border-box;
  border: 1px solid var(--prodcard-border);
  background-color: var(--prodcard-bg);
  color: var(--prodcard-color);
  border-radius: var(--br);
  display: flex;
  flex-direction: column;
  min-width: 0;
  transition: box-shadow 0.3s;
  min-width: 20rem;

  container-type: inline-size;

  &:hover,
  &:focus-within {
    @include media(md) {
      box-shadow: var(--bs15);
    }
  }
  &.m-outofstock {
    > * {
      transition: opacity 0.2s;
      opacity: 0.5;
    }
    &:hover,
    &:focus-within {
      > * {
        opacity: 1;
      }
    }
    .prodcard__btn {
      width: 100%;
    }
    .prodcard__actions {
      &::after {
        display: none;
      }
    }
    .prodcard__pic {
      height: 23.4rem;
    }
    .prodcard__name {
      margin-bottom: 0.5rem;
    }
    .actions {
      min-height: 0;
    }
    .prodcard__bottom {
      align-items: center;
    }
  }
  &__top {
  }

  $pic_pb: 0.8rem;
  &__pic {
    position: relative;
    height: 25.2rem;
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 0 0 #{$pic_pb + 1rem};
    // background-color: var(--bg);
  }
  &__img-triggers {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -#{$pic_pb};
    left: 0;
    z-index: 1;
    display: flex;
    align-items: stretch;
    justify-content: center;
    // opacity: 0;
    transition: opacity 0.2s;
  }
  &__img-trigger {
    flex: 1 1 0;
    // background-color: rgba(0, 255, 0, 0.5);
    z-index: 1;
    position: relative;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 0.1rem;
    //   right: 0.1rem;
    //   height: 0.5rem;
    //   background-color: var(--prodcard-border);
    //   // background-color: transparent;
    //   border-radius: 0.6rem;
    //   transition: background 0.2s;
    // }
    // &.m-active
    // // &:hover
    // {
    //   &::after {
    //     background-color: var(--primary);
    //   }
    // }
  }
  .touchable &__img-triggers,
  &:hover &__img-triggers {
    opacity: 1;
  }

  &__img-dots {
    position: absolute;
    bottom: -0.8rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.3rem 0.7rem;
    z-index: 2;
    pointer-events: none;
  }
  &:has(.prodcard__image:first-child:last-child) &__img-dots {
    display: none;
  }
  &__img-dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    transition: background 0.3s;
    background-color: var(--prodcard-border);
    &.m-active {
      background-color: var(--primary);
    }
  }

  &__img-holder {
    position: absolute;
    top: 0;
    right: -1rem;
    bottom: 0;
    left: -1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    display: none;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &.m-active {
      display: flex;
    }
  }
  &__image-img {
    // display: block;
    // width: 100%;
    // height: 100%;
    // object-fit: contain;
    display: block;
    width: auto;
    max-width: none;
    height: 100%;
    margin: 0 auto;
  }

  &__middle {
    @container (width < 21rem) {
      margin-inline: -0.5rem;
    }
  }
  &__name {
    @extend %b_l_l;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
    word-break: break-word;
    color: var(--prodcard-color);
    text-decoration: none;
    margin: 0 0 0.8rem;
    &:hover,
    &:focus {
      color: var(--cl-blue);
    }
  }
  &__moreinfo {
    // display: grid;
    display: none;
    // gap: 1rem;
    @include media(xl) {
      // grid-template-columns: 1fr 1fr;
      column-count: 2;
      column-gap: 1rem;
    }
  }
  &__moreinfo-row {
    display: flex;
    gap: 1rem;
    align-items: center;
    break-inside: avoid;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  &__moreinfo-title {
    @extend %b_l_l;
    color: var(--prodcard-color-second);
  }
  &__moreinfo-val {
    @extend %b_l_m;
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.8rem 0.4rem;
    flex: 1;
    align-items: flex-end;
    @container (width < 21rem) {
      margin-inline: -0.5rem;
    }
  }
  &__statusprice {
  }
  &__price {
    @extend %h2;
    white-space: nowrap;
    display: flex;
    align-items: baseline;
    gap: 0.3em;
    @container (width < 21rem) {
      gap: 0.2em;
      font-size: 1.9rem;
    }
  }
  &__discount {
    @extend %h2;
    color: var(--primary);
    @container (width < 22rem) {
      font-size: 1.4rem !important;
    }
  }

  &__status {
    @extend %b_xs;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    // white-space: nowrap;
    white-space: normal;
    max-width: 11rem;
    color: var(--prodcard-color-status);
    &.m-yes {
      color: var(--primary);
      &::before {
        content: "";
        width: 1.2rem;
        height: 1rem;
        background-color: currentColor;
        mask-size: 1.2rem 1rem;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-image: var(--ico-tick);
        flex: none;
      }
    }
  }
  &__actions {
    flex: 1;
    flex-wrap: wrap;

    &::before,
    &::after {
      content: "";
      width: 0;
      height: 0;
    }
    @container (width < 25rem) {
      justify-content: space-around !important;
      max-width: 7rem;
      &::before,
      &::after {
        display: none;
      }
    }
  }

  &__btn {
    @extend %btn-special;

    &-text-nw {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @container (width < 21rem) {
      padding-inline: 0.8rem;
    }
  }
  &__btn-text {
    display: none;
  }
}

.actions {
  --prodcard-actions-color: var(--prodcard-border);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 3.8rem;
  box-sizing: border-box;
  &__holder {
    flex-shrink: 0;
    position: relative;
    &:has(:focus) {
      z-index: 1;
    }
  }
  &__tooltip {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% + 1.2rem);
    white-space: nowrap;
    background-color: var(--cl-white);
    border-radius: 0.5rem;
    box-shadow: 0px 1rem 4rem rgba(0, 0, 0, 0.15);
    padding: 1.1rem 1.4rem;
    text-align: center;
    text-decoration: none;
    --link-color: var(--cl-text);

    transition-property: transform, opacity, visibility;
    transition-duration: 0.3s;

    &:not(.m-active) {
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%, 1.6rem);
    }
    #viewtype-list:checked ~ * .actions__holder + .actions__holder &,
    .m-outofstock .actions__holder + .actions__holder & {
      right: -3rem;
      left: auto;
      transform: translateX(0);
    }
  }
  &__item {
    @extend %btn_reset;
    @extend %extend8;
    color: var(--prodcard-actions-color);
    transition: color 0.3s;
    flex-shrink: 0;
    &:hover,
    &.m-active {
      --prodcard-actions-color: var(--primary);
    }
  }
  &__item-ico {
    width: 1.8rem;
    height: 1.8rem;
    fill: none;
    stroke: currentColor;
  }
  &__item_compare &__item-ico {
    fill: currentColor;
    stroke: none;
  }
  &__item_like.m-active &__item-ico {
    fill: currentColor;
  }
}

#viewtype-list:checked ~ * .productlist {
  @include media(md) {
    grid-template-columns: [start] 1fr [end];

    .prodcard {
      display: flex;
      flex-direction: row;
      gap: 3rem;
      padding: 2rem;
      &__top {
        align-self: center;
      }
      &__pic {
        width: 21.6rem;
        height: 15.7rem;
        flex-shrink: 0;
      }
      &__middle {
        flex: 1;
      }
      &__name {
        font-weight: 400;
        font-size: 2.5rem;
        line-height: 2.9rem;
        margin: 0 0 2.5rem;

        display: block;
        @include media(xl) {
          margin-bottom: 3.7rem;
        }
      }
      &__moreinfo {
        // display: grid;
        display: block;
      }

      &__bottom {
        min-width: 14rem;
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        flex: 0;
        align-items: flex-end;
        justify-content: space-between;
      }
      &__actions {
        order: -1;
        flex: 0;
        padding: 0.5rem 0;
        gap: 4.8rem;
        min-height: 0;
        --prodcard-actions-color: var(--prodcard-color-second);
        &::after,
        &::before {
          display: none;
        }
      }
      &__statusprice {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      &__btn {
        // align-self: stretch;
        align-self: flex-end;
        min-width: 11.5rem;
        width: min-content;

        &.btn_gray {
          font-size: 1.3rem;
          line-height: 1.1;
          &,
          .prodcard__btn-text-nw {
            white-space: normal;
            overflow: visible;
            text-overflow: initial;
          }
        }
      }
      &__btn-text {
        display: block;
        // white-space: nowrap;
      }
    }
  }
}
