.p404 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7.5rem 0;
  overflow: hidden;
  &__bg {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100vw;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: -1;
    @include media(md) {
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__bg-pic {
    max-width: 39vw;
    @include media(md) {
      max-width: 33vw;
    }
    @include media(lg) {
      max-width: 36vw;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
    &_left {
      transform: translate(-11%, -51%);
      @include media(md) {
        transform: translate(0, -38%);
      }
      @include media(lg) {
        transform: translate(0, -35%);
      }
      @include media(xl) {
        transform: translate(0, -31%);
      }
      img {
        opacity: 0;
        visibility: hidden;
        animation: p404_left 2s 0.5s forwards;
      }
    }
    &_right {
      transform: translate(19%, 0%);
      @include media(md) {
        transform: translate(4%, 21%);
      }
      @include media(lg) {
        transform: translate(3%, 40%);
      }
      @include media(xl) {
        transform: translate(0%, 42%);
      }
      img {
        opacity: 0;
        visibility: hidden;
        animation: p404_right 2s 0.5s forwards;
      }
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    gap: 3rem;
  }
  &__title {
    @extend %h00;
  }
  &__subtitle {
    @extend %h4h3;
  }
  &__btns {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

@keyframes p404_left {
  from {
    transform: translate(-100%, -15%);
    opacity: 0;
    visibility: hidden;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes p404_right {
  from {
    transform: translate(100%, 15%);
    opacity: 0;
    visibility: hidden;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
    visibility: visible;
  }
}
