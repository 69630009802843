::placeholder {
  opacity: 1;
  color: var(--placeholder);
}

.form {
  position: relative;
  &__row {
    position: relative;
    & + * {
      margin-top: 2rem;
    }
    & + .form__row_extragap {
      margin-top: 3rem;
    }
    & + .form__row_smallgap {
      margin-top: 1rem;
    }
    & + .form__row_middlegap {
      margin-top: 1.5rem;
    }
    &_sepa {
      border-bottom: 1px solid var(--border-color);
    }

    &_wide {
      flex: 1;
      @include media(xl-down) {
        flex: auto;
        width: 100% !important;
      }
    }
    &_short {
      width: 13.3rem !important;
    }
    &_full {
      width: 100% !important;
    }
  }
  &__input-holder {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      order: 2;

      & ~ .form__input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      & ~ .form__input-clear {
        right: 4.5rem;
      }
      :disabled ~ & {
        display: none;
      }
    }
    textarea ~ .btn {
      align-self: stretch;
      height: auto;
    }
    .form__input:has(~ .btn_cta) {
      &:not(:placeholder-shown) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .form__input:placeholder-shown {
      & ~ .btn_cta {
        display: none;
      }
    }
  }

  &__input-multi {
    display: flex;
    gap: 1rem;
  }
  &__codeinput {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: textfield;
    outline: none;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    width: 5rem;
    height: 6rem;
    box-sizing: border-box;
    background: var(--bg);
    border-radius: var(--br);
    border: 1px solid var(--border-color);
    text-align: center;
    font-weight: 700;
    font-size: 2.6rem;
    line-height: 3rem;
    color: var(--primary);
  }

  &__label {
    @extend %b_l;
    display: block;
    margin: 0 0 1.5rem;
    &_special {
      color: var(--text-secondary);
      margin: 0 0 1rem;
    }
  }

  &__overlabel {
    @extend %b_l_l;
    user-select: none;
    pointer-events: none;
    color: var(--placeholder);
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    left: 2rem;
    transition: transform 0.3s, font-size 0.3s;
    transform-origin: 0 50%;
    .m-filled ~ &,
    .m-focused ~ &,
    input:focus ~ &,
    input:not(:placeholder-shown):not([type="hidden"]) ~ & {
      transform: translateY(calc(-50% - 0.9rem)) scale(0.7857);
      @include media(md-down) {
        transform: translateY(calc(-50% - 1rem)) scale(0.7857);
      }
    }

    textarea ~ & {
      translate: none;
      top: 2.3rem;
    }
    textarea:focus ~ &,
    textarea:not(:placeholder-shown) ~ & {
      transform: translateY(-1.8rem);
      font-size: 1.1rem;
      right: 2rem;
      isolation: isolate;
      &::before {
        content: "";
        position: absolute;
        top: -0.4rem;
        left: 0;
        right: 0;
        height: 1.9rem;
        z-index: -1;
        background-color: var(--input-bg, var(--bg-gray));
      }
    }
  }

  &__input {
    @extend %b_l_l;
    color: var(--input-color, var(--text));
    display: block;
    box-sizing: border-box;
    width: 100%;
    flex: 1;
    padding: 1.6rem 2rem;
    height: 5rem;
    background-color: var(--input-bg, var(--bg-gray));
    border: 1px solid var(--input-border, transparent);
    border-radius: var(--br);
    outline: none;
    transition-property: color, border;
    transition-duration: 0.3s;

    @include media(md-down) {
      font-size: 1.6rem;
      &:not(textarea) {
        line-height: 1;
      }
    }

    &_haslabel {
      padding-top: 2.4rem;
      padding-bottom: 0.9rem;
      &::placeholder {
        opacity: 0;
        color: transparent;
      }
      &.form__input_md {
        padding-top: 2.2rem;
        padding-bottom: 0.7rem;
      }
      @include media(md-down) {
        padding-top: 2.2rem;
        &.form__input_md {
          padding-top: 2rem;
        }
      }
    }
    &_md {
      padding-top: 1.4rem;
      padding-bottom: 1.4rem;
      height: 4.6rem;
    }
    .is-error & {
      --input-border: var(--error);
    }

    &.m-filled,
    &:not(:placeholder-shown) {
      padding-right: 2.8rem;
    }
  }
  // &__textarea {
  //   resize: none;
  //   height: 16.9rem;
  //   // min-height: 16.9rem;
  //   // max-height: 34rem;
  // }
  &__textarea {
    min-height: 4.6rem;
    height: 10rem;
    max-height: 18rem;
    resize: vertical;
    &.js_textarea {
      height: 4.6rem;
    }
  }

  &__input-clear {
    @extend %btn_reset;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -1.9rem;
    cursor: pointer;
    width: 3.8rem;
    height: 3.8rem;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.2);

    color: var(--font-light);

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 1.8rem 1.8rem;
      background-color: currentColor;

      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18'%3E%3Cpath stroke='%238F97AC' stroke-linecap='round' stroke-linejoin='round' d='m5.25 5.25 7.5 7.5M5.25 12.75l7.5-7.5'/%3E%3C/svg%3E");
    }

    .m-filled:not(:disabled) ~ &,
    input:not(:placeholder-shown):not(:disabled):not([type="hidden"]) ~ &,
    textarea:not(:placeholder-shown):not(:disabled) ~ & {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }

  &__input-eye {
    @extend %btn_reset;
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -1.9rem;
    cursor: pointer;
    width: 3.8rem;
    height: 3.8rem;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.2);
    color: var(--font-light);
    display: flex;
    align-items: center;
    justify-content: center;
    .m-filled ~ &,
    input:not(:placeholder-shown):not([type="hidden"]) ~ & {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }

  &__note {
    @extend %b_s_l;
    a {
      text-decoration: underline;
    }
    * + & {
      margin-top: 1rem;
    }
  }

  &__alert {
    @extend %b_l_l;
    display: none;
    margin: 0.5rem 0 0 2rem;
    color: var(--error);
    transform: scale(0);
    transition: 0.3s;
    .radiocheck ~ & {
      margin-left: 2.5rem;
    }
    .is-error & {
      transform: scale(1);
      display: block;
    }
  }
  &__helper {
    @extend %b_l_l;
    margin: 1rem 0 0 2rem;
    &_special {
      color: var(--text-secondary);
      margin: 1rem 0 0;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    .btn {
      flex: 1;
    }
  }
}

.radiocheck,
%radiocheck {
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: var(--text);
  transition: color 0.3s;
  &:hover,
  &:focus,
  &:focus-within {
    // color: var(--primary);

    .radiocheck__text::before {
      border-color: var(--primary);
    }
  }

  &:has(:disabled) {
    cursor: default;
    pointer-events: none;
  }
  &__input {
    @extend %visuallyhidden;
    &:focus-visible {
      & ~ .radiocheck__text {
        @extend %fvisible;
        --fv-width: 0.1rem;
        --fv-offset: -0.1rem;
      }
    }
  }

  &__text {
    @extend %b_l;
    display: block;
    position: relative;
    padding-left: 2.5rem;
    color: currentColor;
    min-height: 1.8rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    min-height: 2rem;

    a {
      text-decoration: none;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0.2rem;
      top: 50%;
      transform: translateY(-50%);
      box-sizing: border-box;
      border: 0.15rem solid var(--form-check-border);
      width: 1.6rem;
      height: 1.6rem;
      transition: border 0.3s;
    }
    &::after {
      border-color: transparent;
      opacity: 0;
      border: none;
      transition: opacity 0.3s;
      background-position: 50%;
      background-size: 1.2rem 1rem;
      background-repeat: no-repeat;
      background-image: var(--ico-tick);
    }
    :checked ~ & {
      &::after {
        opacity: 1;
      }
    }

    .is-error &::before {
      border-color: var(--error);
    }

    .radiocheck_small & {
      padding-left: 2.2rem;
      &::before {
        width: 1rem;
        height: 1rem;
        border-width: 1px;
        left: 0.5rem;
      }
      &::after {
        background-size: 0.7rem auto;
      }
    }

    :disabled ~ & {
      &,
      * {
        color: var(--form-check-disabled);
      }
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    }
  }
  &_radio &__text {
    &::before {
      border-radius: 50%;
    }
    &::after {
      background: var(--primary);
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      border: none;
      left: 0.7rem;
    }
  }
  // &_radio :checked ~ &__text {
  //   &::before {
  //     border-color: var(--primary);
  //   }
  // }
}

.pills {
  &__list {
    @extend %ul_reset;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
  }
  &__item {
    display: flex;
  }

  &__pill {
    position: relative;
    display: flex;
  }
  &__input {
    @extend %visuallyhidden;
    &:focus-visible ~ .pills__pill-text {
      @extend %fvisible;
      --btn-bg: var(--primary);
    }
  }
  &__pill-text {
    @extend %btn_reset;
    @extend %b_l_l;
    color: var(--_pill-color, var(--text));
    background-color: var(--_pill-bg, var(--bg-gray));
    box-sizing: border-box;
    padding: 0.9rem 1.9rem;
    border: 1px solid var(--_pill-border, transparent);
    border-radius: var(--br);
    text-decoration: none !important;
    transition: border 0.3s, background-color 0.3s, color 0.3s;
    // display: flex;
    // gap: 1rem;
    // align-items: center;
  }
  &_alt &__pill-text {
    --_pill-bg: var(--bg);
    --_pill-bg-active: var(--bg);
    --_pill-border: var(--border-color);
    --_pill-border-hover: var(--primary);
    --_pill-border-active: var(--primary);
    --_pill-color-active: var(--text);

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.7rem 1.9rem;
    min-height: 5rem;
  }
  &__pill-text {
    &:hover,
    &:focus {
      --_pill-border: var(--_pill-border-hover, var(--primary));
    }
    :checked ~ & {
      --_pill-color: var(--_pill-color-active, var(--cl-white));
      --_pill-border: var(--_pill-border-active, transparent);
      --_pill-bg: var(--_pill-bg-active, var(--primary));
      --_pill-color-secondary: var(--cl-white);
      --_pill-border: var(--_pill-border-active, var(--primary));
    }
  }
  &__pill-smalltext {
    @extend %b_xs;
    color: var(--text-secondary);
    display: block;
    margin: 0 0 0.5rem;
    transition: color 0.3s;
  }
}

.kindaselect {
  position: relative;
  cursor: pointer;
  outline: none;
  border-radius: var(--br);
  &:focus-visible {
    @extend %fvisible;
    --btn-bg: var(--primary);
  }
  &::after {
    content: "";
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2rem;
    background-size: 1.8rem 1.8rem;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18'%3E%3Cpath stroke='%238F97AC' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M13.5 6.75 9 11.25l-4.5-4.5'/%3E%3C/svg%3E");

    transition: transform 0.2s;
  }
  &__input {
    pointer-events: none;
    user-select: none;
  }

  &.m-active,
  &:focus-visible {
    // &.m-active {
    &::after {
      transform: translateY(-50%) scaleY(-1);
    }
    .kindaselect__input {
      border-radius: var(--br) var(--br) 0 0;
    }
    .kindaselect__dropdown {
      opacity: 1;
      visibility: visible;
    }
  }

  &__dropdown {
    position: absolute;
    background-color: var(--input-bg, var(--bg-gray));
    z-index: 3;
    top: 100%;
    left: 0;
    right: 0;
    overflow: auto;
    max-height: 23.4rem;
    scrollbar-width: thin;
    border-radius: 0 0 var(--br) var(--br);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.3s;

    box-shadow: 0 0.3rem 0.2rem -0.1rem var(--bg);
  }

  &__list {
    @extend %ul_reset;
  }
  &__item {
  }
  &__btn {
    @extend %btn_reset;
    @extend %b_xl_l;
    width: 100%;
    padding: 1rem 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background 0.3s;
    gap: 2rem;
    outline: none;
    &:focus,
    &:hover {
      background-color: var(--border-color);
    }
  }
  &__name {
  }
  &__price {
    .rouble {
      color: var(--text-secondary);
    }
  }
}

//

.time-select {
  appearance: none;
  cursor: pointer;
  @extend %b_l_l;
  border: none;
  border: 1px solid var(--border-color);
  background-color: var(--bg);
  border-radius: var(--br);
  color: var(--text);
  box-sizing: border-box;
  padding: 0.7rem 1.9rem 0.7rem 4.8rem;
  min-height: 5rem;
  transition: border-color 0.3s;
  outline: none;
  width: auto;

  // &:focus-visible {
  //   @extend %fvisible;
  //   --btn-bg: var(--primary);
  // }
  background-position: 2rem 50%;
  background-repeat: no-repeat;
  background-size: 1.8rem 1.8rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18'%3E%3Cg stroke='%2372bf42' stroke-linejoin='round' stroke-width='1.5' clip-path='url(%23a)'%3E%3Cpath d='M9 16.63A6.87 6.87 0 1 0 9 2.88a6.87 6.87 0 0 0 0 13.75Z'/%3E%3Cpath stroke-linecap='round' d='M8.91 5.76v4.13l2.91 2.91M1.5 3.38 4.12 1.5M16.5 3.38 13.87 1.5'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h18v18H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  &.m-active,
  &:hover,
  &:focus {
    border-color: var(--primary);
  }

  &:not(.m-active) {
    max-width: 17rem;
  }
  option {
    font-size: 1.4rem;
  }
}

.overselect {
  position: relative;
  cursor: pointer;
  outline: none;
  border-radius: var(--br);
  min-width: 22rem;
  &:focus-visible {
    @extend %fvisible;
    --btn-bg: var(--primary);
  }
  &__select {
    opacity: 1;
    pointer-events: none;
    user-select: none;
  }
  &.m-active,
  &:focus-visible {
    .overselect__dropdown {
      opacity: 1;
      visibility: visible;
    }
  }

  &__dropdown {
    position: absolute;
    background-color: var(--input-bg, var(--bg-gray));
    z-index: 3;
    top: calc(100% + 0.4rem);
    left: 0;
    width: auto;
    min-width: 100%;
    overflow: auto;
    max-height: 23.4rem;
    scrollbar-width: thin;
    border-radius: var(--br);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.3s;

    box-shadow: 0 0.3rem 0.2rem -0.1rem var(--bg);
  }

  &__list {
    @extend %ul_reset;
  }
  &__btn {
    @extend %btn_reset;
    @extend %b_xl_l;
    width: 100%;
    padding: 1rem 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background 0.3s;
    gap: 2rem;
    outline: none;
    white-space: nowrap;
    &:focus,
    &:hover {
      background-color: var(--border-color);
    }
  }
}

input:invalid ~ .btn {
  // pointer-events: none;
  // filter: grayscale(1);
  // opacity: 0.3;
}
