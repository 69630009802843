.tradein {
  display: grid;
  gap: 3rem;
  @include media(md) {
    gap: 5rem;
  }
  @include media(xl) {
    gap: 7rem;

    .section__head:has(.section__bigtitle) ~ & {
      margin-top: -6rem;
    }
  }

  &__pic {
    display: block;
    border-radius: var(--br);
    overflow: hidden;
    @include media(xl) {
      height: 37.4rem;
    }
  }
  &__pic-img {
    width: 100%;
    height: auto;
    @include media(xl) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 0%;
    }
  }

  &__body {
    display: grid;
    gap: 2.5rem 5rem;
    @include media(md) {
      gap: 5rem;
      grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
    }
    @include media(xl) {
      gap: 7rem 5rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__icon-title-wrap {
    @extend %h2;
    min-height: 3rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  &__icon {
    display: block;
    max-height: 3rem;
    width: auto;
  }
  &__list {
    @extend %ul_reset;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__item {
    @extend %b_s_l;
    display: flex;
    justify-content: space-between;
    break-inside: avoid;
    align-items: flex-end;
    .arenda &:first-child {
      .tradein__cost {
        &::before {
          display: none;
        }
      }
    }
  }
  &__name {
    display: flex;
    align-items: center;
  }
  &__cost {
    flex: 1;
    display: flex;
    align-items: flex-end;
    text-align: right;
    justify-content: flex-end;

    &::before {
      content: "";
      display: block;
      flex: 1;
      margin: 0 1rem;
      border-top: 1px dashed var(--text-secondary);
      transform: translateY(-0.2rem);
      // min-width: 1rem;
    }

    .rouble {
      color: var(--text-secondary);
    }

    & + .tradein__cost {
      max-width: 30%;
      flex: auto;
    }
  }
}
