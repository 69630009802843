.product {
  position: relative;
  display: grid;
  gap: 1rem;
  align-items: start;
  @include media(md-down) {
    .section__head + & {
      margin-top: -2rem;
    }
  }
  @include media(lg) {
    gap: 5rem;
    grid-template-columns: 1fr 1fr;
  }
  @include media(xxl) {
    grid-template-columns: 50rem 1fr 41.8rem;
  }
  &__photo {
    min-width: 0;
    // @include media(md-down) {
    //   margin-bottom: 4rem;
    // }
  }

  &__about {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    @include media(lg, xxl-down) {
      grid-column: span 2;
    }

    @include media(xxl-down) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: start;
    }

    @include media(lg-down) {
      display: none;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }

  &__descr {
    @include media(xxl-down) {
      order: -1;
    }
  }

  &__controls {
    border: 1px solid var(--border-color);
    border-radius: var(--br);

    @include media(xxl) {
      order: 3;
    }
  }
  &__controls-box {
    padding: 2rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    & + & {
      border-top: 1px solid var(--border-color);
    }
    @include media(md) {
      padding: 2rem 2.5rem;
    }
  }

  &__price {
    @extend %h00a;
    white-space: nowrap;
    // .rouble {
    //   font-weight: 700;
    // }
  }

  &__btns {
    display: flex;
    gap: 1rem;
  }
  &__buy-btn {
    flex: 1;
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  &__options-label {
    @extend %b_l_l;
    margin: 0 0 0.5rem;
  }

  &__control-btns {
    display: flex;
    gap: 2rem;
    .btn {
      flex: 1;
    }
  }

  &__note {
    * + &,
    & + * {
      margin-top: 3rem;
      @include media(md) {
        margin-top: 5rem;
      }
    }
    @extend %b_s_l;
    padding: 2rem;
    background-color: var(--border-color);
    max-width: var(--prod-tech-maxw);
    box-sizing: border-box;
  }
}

.prodinfolist {
  padding: 1rem 0;
  @extend %ul_reset;
  display: grid;
  gap: 1.2rem;
  a {
    text-decoration: none;
  }

  &__item {
    @extend %b_l;
    display: flex;
    &.m-special {
      margin-top: 1rem;
      @extend %b_s_l;
      a {
        color: var(--secondary);
      }
    }
  }
}

.pspecs {
  & + *,
  * + & {
    margin-top: 1rem;
  }
  @extend %ul_reset;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &__item {
    @extend %b_l_l;
    display: flex;
    gap: 1rem;
    align-items: flex-end;
  }
  &__name {
    color: var(--text-secondary);
  }
  &__val {
    @extend %b_l_m;
  }
}
