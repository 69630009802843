.cartplate {
  --cartplate-color: var(--text-light);
  --cartplate-color-accent: var(--cl-white);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: $z;
  background: var(--gr-header);
  color: var(--cartplate-color);
  transition-property: transform, opacity, visibility;
  transition-duration: 0.3s;
  &:not(.m-active) {
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
  }
  @include media(md-down) {
    display: none;
  }
  @include media(md) {
    &.m-active ~ .footer {
      margin-bottom: var(--cartplate-height);
    }
  }
  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.4rem;
    justify-content: space-between;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    min-height: var(--cartplate-height);
    box-sizing: border-box;
    @include media(md) {
      justify-content: center;
      gap: 5rem;
    }
  }
  &__btn {
    @extend %btn-special;
  }

  &__text {
    display: flex;
    align-items: center;
    gap: 0 0.5rem;
    flex-wrap: wrap;
    min-width: 0;
    @include media(md) {
      flex-wrap: nowrap;
      gap: 1rem;
    }
  }
  &__info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    @include media(md) {
      gap: 1rem;
      min-width: 0;
    }
  }
  &__product,
  &__quantity {
    @extend %b_l;
    color: var(--cartplate-color-accent);
  }
  &__product {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    // max-width: 20rem;
    @include media(md-down) {
      display: none;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  &__quantity {
    white-space: nowrap;
    @include media(md) {
      display: none;
    }
  }

  &__label,
  &__price {
    @extend %b_l_l;
    white-space: nowrap;
  }
}
