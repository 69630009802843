.checkout {
  &__inner {
    display: grid;
    gap: 5rem 4rem;
    @include media(lg) {
      align-items: start;
      grid-template-columns: 1fr 40rem;
    }
    @include media(xl) {
      gap: 5rem 7rem;
    }
  }

  &__mainform {
  }

  &__controls {
    display: grid;
    gap: 3rem;
    // @include media(lg) {
    //   position: sticky;
    //   top: 2rem;
    // }
  }

  &__list {
    display: grid;
    gap: 2.5rem;
    background-color: var(--bg-gray);
    padding: 2rem 1.5rem;
    border-radius: var(--br);
    @include media(md) {
      padding: 2rem;
    }
  }
  &__list-title {
    @extend %h2;
  }

  &__order {
    &,
    > form {
      display: grid;
      gap: 5rem;
    }

    background-color: var(--bg-gray);
    padding: 2rem 1.5rem;
    border-radius: var(--br);
    @include media(md) {
      padding: 2rem;
    }
  }

  &__order-total {
    display: grid;
    gap: 2.5rem;
  }
  &__order-price {
    @extend %h1;
  }
  &__order-btns {
    display: grid;
    gap: 1.6rem;
    .btn {
      --btn-bg-disabled: var(--cl-gray);
    }
  }
}
.checkout-prodcard {
  display: grid;
  grid-template-columns: 7rem auto max-content;
  align-items: start;
  gap: 1.5rem;

  &__pic {
    display: block;
    background-color: rgba(0, 255, 0, 0.1);
  }
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 7rem;
    position: relative;
    padding: 0.5rem;
    margin: -0.5rem;
    background-color: var(--cl-white);
    border-radius: 0.4rem;
    box-sizing: content-box;
  }
  &__image-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  &__info {
  }

  &__name {
    @extend %b_l;
    text-decoration: none;
    color: var(--text);
  }

  &__price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__price-current {
    @extend %b_l_m;
    white-space: nowrap;
  }

  &__amount {
    @extend %b_m_l;
    margin-top: 0.5rem;
  }
}

.deliveryinfo {
  display: grid;
  gap: 2rem;
  &__title {
    @extend %h2;
  }
  &__item {
    display: grid;
    gap: 1rem;
  }
  &__label {
    @extend %b_l;
    color: var(--text-secondary);
  }
  &__val {
    @extend %b_l;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 1.5rem;
  }
}

//

.checkoutform {
  display: grid;
  gap: 5rem;
  &__section {
    display: grid;
    gap: 2.5rem;
  }

  &__title {
    @extend %h2;
  }
  &__pills {
  }
  &__inputsgroup {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    @include media(xl) {
      max-width: 85.8rem;
    }
    .form__row {
      margin: 0 !important;
      width: 26.6rem;
    }
  }
  &__text {
    max-width: 58.4rem;
  }
}
