.delivery {
  &__top {
    @include scrollgaps;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    padding-bottom: 3rem;
    @include media(xl) {
      padding-bottom: 6.4rem;
      min-height: 67.7rem;
    }
  }
  &__top-bg {
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(28%, 2.7rem);
    @include media(xl-down) {
      display: none;
    }
  }
  &__top-bg-img {
    width: 57.7rem;
    height: auto;
  }
  &__top-inner {
    display: grid;
    gap: 3rem;

    @include media(xl) {
      max-width: 73.7rem;
      gap: 9rem;
    }
    @include media(xxl) {
      max-width: 84.7rem;
    }
  }
  &__features {
    @extend %ul_reset;
    display: grid;
    gap: 2rem;
    @include media(md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__fetures-item {
    @extend %h2;
    padding: 2rem 1.5rem;
    border-radius: var(--br);
    background-color: var(--bg-gray);
    display: flex;
    align-items: center;
    @include media(md) {
      padding: 2.5rem;
    }
    .rouble {
      color: var(--text-secondary);
    }
  }
  &__table {
    display: grid;
    gap: 1.5rem;
  }
  &__table-title {
    @extend %h3;
  }
  &__pay {
    @include media(xl) {
      margin-bottom: 5rem;
    }
  }
}
