.filter {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__block {
    position: relative;
    padding: 1.9rem 2.4rem;
    border-radius: var(--br);
    background-color: var(--filter-block-bg);
    color: var(--filter-color);
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    transition: border 0.2s;
    &.m-highlight {
      border-color: var(--primary);
    }
  }
  &__search {
    padding: 0;
  }
  &__search-input {
    @extend %b_m_l;
    border-radius: var(--br);
    border: none;
    padding: 1.9rem 2.4rem;
    background-color: var(--filter-block-bg);
    color: var(--filter-color);
    outline: none;
  }

  &__block-trigger {
    @extend %btn_reset;
    @extend %extend16;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    padding-right: 1.4rem;
    &::after {
      content: "";
      background-color: var(--cl-gray-300);
      width: 0.8rem;
      height: 0.8rem;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      mask-size: 0.8rem 0.5rem;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' fill='none' viewBox='0 0 8 5'%3E%3Cpath stroke='%23676767' d='m1 .5 3 3 3-3'/%3E%3C/svg%3E");
    }
  }

  &__title {
    @extend %btn_reset;
    @extend %b_l_m;
    margin: 0;
  }

  &__selected {
    @extend %b_xxs;
    border-radius: 50%;
    background-color: var(--primary);
    color: var(--inverted);
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -0.8rem 0;
    flex-shrink: 0;
    text-align: center;
  }

  &__content-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    transition-property: grid-template-rows, padding;
    transition-duration: 0.2s;
    .filter__block.m-active & {
      grid-template-rows: 1fr;
      padding-top: 1rem;
    }
  }
  &__content {
    overflow: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.2s;
    .filter__block.js_toggle:not(.m-active) & {
      visibility: hidden;
      opacity: 0;
    }
  }

  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.1rem;
  }
  &__field-holder {
    position: relative;
    & + & {
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-right: 0.3rem;
        width: 0.5rem;
        height: 1px;
        background-color: var(--cl-gray-300);
      }
    }
  }
  &__field-label {
    @extend %b_s;
    color: var(--filter-label-color);
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    user-select: none;
    & ~ .filter__field {
      padding-left: 3rem;
    }
  }
  &__field {
    @extend %b_l;
    background-color: var(--filter-input-bg);
    outline: none;
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 1.6rem 1rem;
    border: none;
    border-radius: 0.5rem;
    height: 4.8rem;
    color: var(--filter-input-color);
    @include media(md-down) {
      font-size: 1.6rem;
      line-height: 1;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    margin: -0.3rem 0;
  }
  &__item {
    display: flex;
    cursor: pointer;
    padding: 0.3rem 0;
    // &:hover {
    //   .filter__item-text::before {
    //     border-color: var(--primary);
    //   }
    // }
  }

  &__item-text {
    @extend %b_l_m;
    color: var(--filter-color);
    display: flex;
    align-items: center;
    gap: 0.4rem;

    &::before,
    &::after {
    }
    &::after {
    }
    // :checked ~ & {
    //   &::after {
    //     opacity: 1;
    //   }
    // }
  }
  &__item_radio &__item-text {
    &::before {
    }
    &::after {
    }
  }

  &__item-text-option {
  }
  &__item-text-howmany {
    @extend %b_s;
    color: var(--filter-color-secondary);
  }
}

//

.range {
  &__fields {
  }
  &__field-holder {
  }
  &__field {
  }
  &__slider {
    // overflow: hidden;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
    margin-top: 1rem;
  }

  .noUi-target {
    background: none;
    border-radius: 0;
    border: none;
    box-shadow: none;
  }

  .noUi-handle {
    border: none;
    border-radius: 0.3rem;
    background: var(--primary);
    box-shadow: none;
    cursor: grab;
    &::before,
    &::after {
      display: none;
    }
    &::before {
      content: "";
      display: block;
      width: 3rem;
      height: 3rem;
      background: none;
      border: none;
      box-shadow: none;
      outline: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .noUi-horizontal {
    height: 0.8rem;
  }
  .noUi-horizontal .noUi-handle {
    width: 0.8rem;
    height: 0.8rem;
    right: -0.4rem;
    top: 0rem;
  }
  .noUi-connects {
    background: none;
    width: auto;
    margin-left: -0.4rem;
    margin-right: -0.4rem;
    &:before {
      content: "";
      height: 0.2rem;
      position: absolute;
      top: 0.3rem;
      left: 0;
      right: 0;
      background-color: var(--border-color);
    }
  }
  .noUi-connect {
    background: none;
    &:before {
      content: "";
      height: 0.2rem;
      position: absolute;
      top: 0.3rem;
      left: 0;
      right: 0;
      background: var(--primary);
      // opacity: 0.3;
      opacity: 1;
    }
  }
}
