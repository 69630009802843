@mixin table-default {
  width: 100%;
  border-collapse: collapse;
  tr {
    background-color: var(--bg);
    &:nth-child(even) {
      background-color: var(--bg-gray);
    }
  }

  th,
  td {
    padding: 1rem 2rem;
    & ~ * {
      padding-left: 0.5rem;
    }
    &:not([align]) {
      text-align: left;
    }

    // & ~ *:not([align]) {
    //   text-align: center;
    // }
    // &:last-child {
    //   text-align: right;
    // }
  }
  th {
    vertical-align: middle;
    font-weight: normal;
  }
  &.m-firstcol-center {
    th,
    td {
      &:first-child:not([align]) {
        text-align: center;
      }
    }
  }
  &.m-firstcol-right {
    th,
    td {
      &:first-child:not([align]) {
        text-align: right;
      }
    }
  }
  &.m-firstcol-left {
    th,
    td {
      &:first-child:not([align]) {
        text-align: left;
      }
    }
  }
  &.m-notfirstcol-center {
    th,
    td {
      & ~ *:not([align]) {
        text-align: center;
      }
    }
  }
  &.m-notfirstcol-right {
    th,
    td {
      & ~ *:not([align]) {
        text-align: right;
      }
    }
  }
  &.m-notfirstcol-left {
    th,
    td {
      & ~ *:not([align]) {
        text-align: left;
      }
    }
  }
  &.m-lastcol-center {
    th,
    td {
      &:not([align]):last-child {
        text-align: center;
      }
    }
  }
  &.m-lastcol-left {
    th,
    td {
      &:not([align]):last-child {
        text-align: left;
      }
    }
  }
  &.m-lastcol-right {
    th,
    td {
      &:not([align]):last-child {
        text-align: right;
      }
    }
  }
}
.table-default {
  @include table-default;
  @extend %b_s_l;
}
.searchbox ~ .table-wrapper table tr:nth-child(even) {
  background-color: var(--bg);
}
.searchbox ~ .table-wrapper table tr:nth-child(odd) {
  background-color: var(--bg-gray);
}

.textblock {
  --tb-gap: 5rem;
  @include media(lg) {
    --tb-gap: 7rem;
  }
  // clearfix just in case
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  //

  @extend %b_l;
  margin-inline: auto;
  p {
    margin: 0;
  }
  p + *:not(br) {
    margin-top: var(--tb-gap);
  }
  p + ol,
  p + ul {
    margin-top: 3rem;
  }
  p + p,
  * + p {
    margin-top: 2rem;
  }

  h1 {
    @extend %h00b;
    margin: 0 0 5rem;
    & + time {
      margin-top: -4.5rem;
    }
  }
  * + h1,
  * + .h1 {
    margin-top: 5rem;
  }
  time {
    @extend %h4;
    color: var(--text-secondary);
    display: block;
    margin: 0 0 5rem;
  }

  h2 {
    @extend %h1h0;
    margin: 0 0 3rem;
    a {
      text-decoration: none !important;
    }
  }
  h3 {
    @extend %h1;
    margin: 0 0 2rem;
    a {
      text-decoration: none !important;
    }
  }

  h4 {
    @extend %h2;
    margin: 0 0 1rem;
    a {
      text-decoration: none !important;
    }
    & + p {
      margin-top: 1rem;
    }
    & + ol,
    & + ul {
      margin-top: 1.5rem;
    }
  }

  * + h2,
  * + h3 {
    margin-top: var(--tb-gap);
  }
  * + h4,
  p + h4 {
    margin-top: 3rem;
  }

  > ul,
  > ol {
    margin-block: 3rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  p img,
  p picture,
  > img,
  > picture {
    margin-block: var(--tb-gap);
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: var(--br);
    &:last-child {
      margin-bottom: 0;
    }
    img {
      margin-block: 0;
    }

    &:has(+ .mt-0) {
      margin-bottom: 2rem;
    }
  }

  p {
    img,
    picture {
      margin-block: 0;
    }
    &:has(+ .mt-0) {
      margin-bottom: 2rem;
    }
  }

  > .row > * {
    > img,
    > picture,
    > picture img {
      border-radius: var(--br);
    }
  }

  * + .paypics {
    margin-top: 1.5rem;
  }

  .row {
    margin-block: var(--tb-gap);
    gap: 1rem 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    > * > img,
    > * > picture {
      margin: 0;
    }
  }
  .textblock__pics {
    @include scrollgaps;
    margin-block: var(--tb-gap) 5rem;
    padding-bottom: 2rem;
    display: grid;
    grid-template-columns: repeat(var(--artpics-cols, 2), 1fr);
    gap: var(--gridgap);
    overflow: auto;

    &:last-child {
      margin-bottom: 0;
    }

    > img,
    > picture {
      margin: 0;
      min-width: 22rem;
      border-radius: var(--br);
    }
  }

  table {
    @include table-default;
    @extend %b_s_l;
    // @include media(md) {
    //   table-layout: fixed;
    //   word-wrap: break-word;
    // }
    td,
    th {
      min-width: 8rem;
    }
  }
  .table-wrapper {
    @include scrollgaps;
  }
  > .table-wrapper,
  > table {
    margin-block: var(--tb-gap);
    &:last-child {
      margin-bottom: 0;
    }
    &:has(+ p) {
      margin-bottom: 3rem;
    }
    // table {
    //   margin: 0 !important;
    // }
  }
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    & + .table-wrapper,
    & + table {
      margin-top: 3rem;
    }
  }

  blockquote {
    @extend %h2;
    display: block;
    position: relative;
    max-width: 35.4rem;
    margin: 30px auto;
    padding: 3.3rem 1.7rem;
    text-align: center;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 1rem;
      right: 1rem;
      height: 2.3rem;
      background: radial-gradient(circle 2.3rem at center, var(--primary) 0.15rem, var(--bg) 0.15rem, var(--bg) 1.15rem, transparent 1.15rem), linear-gradient(to bottom, var(--bg) 1.1rem, var(--primary) 1.2rem, var(--bg) 1.2rem);
      background-size: 2.3rem 2.3rem, 100% 100%;
      background-repeat: no-repeat;
      background-position: center, center;
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
  }

  .plate {
    @extend %b_l;
    &::before,
    &::after {
      display: none;
    }
    padding: 2rem 1.5rem;
    background-color: var(--bg-gray);
    border-radius: var(--br);
    max-width: none;
    > p {
      max-width: 65.4rem;
      margin-inline: auto;
    }
    & + * {
      margin-top: 3rem;
    }
    @include media(md) {
      padding: 4rem 3.2rem;
      & + * {
        margin-top: 5rem;
      }
    }
  }
  * + .plate {
    margin-top: 3rem;
    @include media(lg) {
      margin-top: 5rem;
    }
  }

  .hugetext {
    display: block;
    margin: 3rem auto;
    text-align: center;
    font-size: 3.2rem;
    line-height: 1;
    font-weight: 700;
    &__highlight {
      font-size: 6rem;
      color: var(--primary);
    }
  }
}
.m-h370 {
  display: block;
  height: 37rem;
}
.m-h260 {
  display: block;
  height: 26rem;
}
.m-h370,
.m-h260 {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.companystats {
  @include media(lg) {
    .hugetext {
      margin-top: 0;
    }
  }
}

.m-highlight-headings {
  h1,
  h2 {
    color: var(--primary);
  }
}

.articles-description {
  @extend %h3;
  border-radius: var(--br);
  background-color: var(--bg-gray);
  padding: 2.4rem;
  margin: 0 0 5rem;
  * + & {
    margin-top: 5rem;
  }
  ol {
    list-style: disc;
    margin: 2.4rem 0 0;
    * {
      text-decoration: none;
    }
  }
}
.articles-description-title {
  @extend %h2;
  margin: 0;
}

.prodtextblock {
  @extend %b_l;
  max-width: 75rem;

  // .tabs & {
  //   padding-top: 2rem;
  // }

  p {
    margin: 0;
  }
  p + *,
  * + p {
    margin-top: 1.5rem;
  }
  p + p {
    margin-top: 1.143em;
  }

  h2,
  h3 {
    @extend %h2;
    margin: 0;
    & + * {
      margin-top: 1.5rem;
    }
    & + p {
      margin-top: 1rem;
    }
  }
  h3 {
    @extend %h3;
  }
  * + h2,
  * + h3 {
    margin-top: 3rem;
  }
}
.paypics {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  &:has(.paypics__figure) {
    gap: 2.4rem 5rem;
  }
  & + *,
  * + & {
    margin-top: 1.5rem;
  }
  &__pic {
    height: 3rem;
    flex-shrink: 0;
    img {
      height: 100%;
      width: auto;
    }
  }
  &__figure {
    display: flex;
    margin: 0;
    padding: 0;
    gap: 0.5rem 1.5rem;
    flex-wrap: wrap;
  }
  &__caption {
    @extend %b_l;
    margin: 0;
    padding: 0;
  }
}

.ul-special {
  @extend %ul_reset;
  display: flex;
  flex-direction: column;
  gap: 2.5rem 5rem;
  @include media(md) {
    flex-direction: row;
  }
  > li {
    @extend %h4;
    position: relative;
    padding-left: 1.8rem;
    &::before {
      content: "";
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background-color: var(--primary);
      position: absolute;
      left: 0;
      top: 0.6rem;
      display: block;
    }
    @include media(md) {
      flex: 1;
    }
  }
}

.table-wrapper {
  max-width: 100%;
  overflow: auto;
  scrollbar-width: thin;
}

.delivery-table {
  @extend %b_xl_l;
  max-height: 28.4rem;
  overflow: auto;
  scrollbar-width: thin;
  border: 1px solid var(--border-color);
  position: relative;
  border-radius: 0.5rem;
  table {
    @include table-default;
  }

  .searchbox {
    position: sticky;
    top: 0;
    border-bottom: 1px solid var(--bg-gray);
  }
  .rouble {
    color: var(--text-secondary);
  }
}

.ul-plates {
  @extend %ul_reset;
  @extend %h2;
  display: grid;
  gap: 2rem;
  &_cols {
    @include media(md) {
      grid-template-columns: 1fr 1fr;
      gap: 3rem;
    }
  }
  > li {
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background-color: var(--primary);
      position: absolute;
      left: 2.5rem;
      top: 3.3rem;
    }
    background-color: var(--bg-gray);
    padding: 2.5rem;
    padding-left: 4.3rem;
    border-radius: var(--br);
  }
}
