.section {
  position: relative;
  padding: 2rem 0;
  &:has(.slider_prod) {
    padding-bottom: 4rem;
    margin-bottom: -2rem;
  }
  &__controls {
    margin-bottom: 2rem;
  }
  &__head {
    margin-bottom: 4rem;
    &:has(.section__bigtitle) {
      margin-bottom: 3rem;
      @include media(md) {
        padding-top: 2rem;
      }
      @include media(xl) {
        margin-bottom: 9rem;
      }
    }

    @include media(md-down) {
      &:has(.status-mb) {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 0 2.4rem;
        align-items: start;
        > * {
          word-break: break-word;
        }
        > *:not(.status-mb, .section__subtitle) {
          grid-column: span 2;
        }
        .status-mb {
          grid-column: 2/3;
        }
        .section__subtitle + .status-mb {
          max-width: 50vw;
        }
      }
    }
  }
  &__title {
    @extend %h1;
    & + * {
      margin-top: 1rem;
    }
    &[data-counter] {
      &::after {
        content: attr(data-counter);
        color: var(--text-secondary);
        margin-left: 0.2em;
      }
    }
  }
  &__bigtitle {
    @extend %h0b;
    & + * {
      margin-top: 1rem;
    }
    &.text-highlight {
      font-weight: 400;
    }
  }

  &__subtitle {
    @extend %b_l_l;
    color: var(--text-secondary);
  }

  &__hero {
    position: relative;
    min-height: 21.5rem;
    box-sizing: border-box;
    margin: 0 0 3rem;
    display: flex;
    align-items: flex-end;
    padding: 2rem 0;
    @include media(md) {
      min-height: 28.6rem;
      padding: 2.5rem 0;
    }
    @include media(xl) {
      min-height: 41.6rem;
      margin-bottom: 7rem;
      padding: 5rem 0;
    }
    &_extra {
      align-items: flex-start;
      min-height: 34.4rem;
      margin: 0 0 3rem;
      padding: 0.6rem 0;
      @include media(md) {
        min-height: 43.8rem;
        margin: 0 0 5rem;
      }
      @include media(xl) {
        min-height: 54.6rem;
        margin: 0 0 7rem;
        padding: 5rem 0;
      }

      .section__hero-pic {
        &::after {
          display: none;
        }
        @include media(xxl-down) {
          right: var(--sidegap-n);
          left: var(--sidegap-n);
          border-radius: 0;
        }
      }
      .section__hero-title {
        @extend %h0b;
        color: var(--cl-text);
      }
    }
  }

  &__hero-pic {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--bg-gray);
    overflow: hidden;
    border-radius: var(--br);

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, rgba(28, 42, 80, 0.026) 56.68%, rgba(28, 42, 80, 0.2) 100%);
    }
    &_rightside .section__hero-pic-img {
      object-position: 92% 50%;
    }
  }
  &__hero-pic-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: var(--heropic-op-x, 50%) var(--heropic-op-y, 50%);
  }

  &__hero-title {
    @extend %h0a;
    position: relative;
    color: var(--cl-white);
  }
}
