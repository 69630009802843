.burger {
  @extend %btn_reset;
  display: block;
  position: relative;
  box-sizing: content-box;
  width: 3.8rem;
  height: 3.8rem;
  // padding: 1rem;
  // margin: 0 -1rem 0 1.6rem;
  // margin-left: 2rem;
  margin-right: -0.8rem;
  margin-left: 1.7rem;
  cursor: pointer;
  color: var(--header-top-control-color);
  z-index: 2;
  &,
  &:hover,
  &:active {
    // color: var(--inverted);
  }
  &:before,
  &:after,
  span {
    content: "";
    position: absolute;
    left: 0.8rem;
    top: 1.8rem;
    width: 2.2rem;
    height: 0.2rem;
    background: currentColor;
    transition: all 0.3s;
  }
  span {
    transition: all 0.3s 0.1s;
  }
  &:before {
    top: 1rem;
  }
  &:after {
    top: auto;
    bottom: 1rem;
  }
  &.m-active,
  #header-toggle:checked ~ * &[for="header-toggle"] {
    span {
      transform: translate(-50%);
      width: 0;
      opacity: 0;
      transition-delay: 0s;
    }
    &:before,
    &:after {
      // width: 2.4rem;
      // left: 0.6rem;
    }
    &:before {
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 1.8rem;
    }
    &:after {
      bottom: 1.8rem;
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
  // @include media(xl) {
  //   display: none;
  // }
}
