.account {
  display: grid;
  gap: 8rem 4rem;
  @include media(lg) {
    align-items: start;
    grid-template-columns: 28rem 1fr;
    grid-template-columns: clamp(28rem, 27vw, 31.58rem) 1fr;
  }
  @include media(xl) {
    gap: 8rem 7rem;
    align-items: start;
    grid-template-columns: 31.5rem 1fr;
  }

  &__form {
    @include media(md, lg-down) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 3rem;
      > .form__row {
        margin: 0 !important;
      }
      .form__row_twocols,
      .form__row_sepa {
        grid-column: span 2;
      }
    }
  }

  &__profile {
    display: grid;
    gap: 3rem;
  }

  &__profile-btns {
    display: grid;
  }
  &__orders {
    display: grid;
    gap: 3rem;
  }
  &__orders-list {
    display: grid;
    gap: 3rem;
  }
  &__orders-title {
    @extend %h2;
  }
  &__orders-btns {
    display: grid;
  }

  &__order {
    background-color: var(--bg-gray);
    border-radius: var(--br);
    padding: 2rem 1.5rem;
    display: grid;
    gap: 5rem;
    @include media(md) {
      padding: 3rem 2.5rem;
    }
  }

  &__order-head {
    display: grid;
    gap: 2.5rem;
    @include media(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__order-head-title {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  &__order-title {
    @extend %h2;
  }
  &__order-title-date {
    white-space: nowrap;
    color: var(--text-secondary);
  }
  &__order-head-total {
    @extend %b_l_m;
    .rouble {
      color: var(--text-secondary);
    }
  }

  &__order-list {
    display: grid;
    gap: 2.5rem;
  }
}
.account-prodcard {
  display: grid;
  grid-template-columns: 7rem auto auto;
  align-items: start;
  gap: 1.5rem;
  @include media(md) {
    grid-template-columns: 7rem auto max-content 20%;
  }
  @include media(lg) {
    grid-template-columns: 7rem auto max-content 20%;
  }
  @include media(xl) {
    grid-template-columns: 7rem auto max-content 25%;
  }
  @include media(xxl) {
    grid-template-columns: 7rem auto max-content 35%;
  }

  &__pic {
    display: block;
    grid-row: span 2;
    background-color: rgba(0, 255, 0, 0.1);
    @include media(md) {
      grid-row: auto;
    }
  }
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 7rem;
    position: relative;
    padding: 0.5rem;
    margin: -0.5rem;
    background-color: var(--cl-white);
    border-radius: 0.4rem;
    box-sizing: content-box;
  }
  &__image-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  &__info {
    grid-column: span 2;
    min-width: 0;
    @include media(md) {
      grid-column: auto;
    }
  }

  &__name {
    @extend %b_l;
    text-decoration: none;
    color: var(--text);
  }
  &__amount {
    @extend %b_l;
    display: flex;
    @include media(md) {
      justify-content: center;
      padding-left: 2.4rem;
      text-align: right;
    }
  }
  &__price {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
  }
  &__price-current {
    @extend %b_l_m;
    white-space: nowrap;

    .rouble {
      color: var(--text-secondary);
    }
  }
}
