.footer {
  @extend %b_l_l;
  padding: 2rem 0;
  --footer-color: var(--text);
  --link-color: var(--footer-color);
  --link-color-hover: var(--footer-color);
  color: var(--footer-color);
  position: relative;
  transition: margin 0.3s;
  @include media(md-down) {
    margin-bottom: var(--mobnav-height);
  }
  a {
    @extend %a_tdn;
  }
  &__inner {
    padding: 5rem 0 0;
    border-top: 0.2rem solid var(--border-color);
  }
  &__top {
    display: flex;
    flex-direction: column;
    gap: 5rem var(--gridgap);
    // @include media(xl) {
    //   display: grid;
    //   grid-template-columns: auto max-content;
    // }
    @include media(xl) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__navsoc {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    flex: 1;
    @include media(md) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0;
    }
    @include media(xl) {
      gap: var(--gridgap);
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 3rem 5rem;
    margin: 5rem 0 0;
    @include media(md) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__copy {
    @extend %b_l_l;
  }
  &__note {
    @extend %b_s_l;
    @include media(md) {
      flex: 1;
    }
  }
  &__by {
    @extend %b_l_l;
  }
}

.subscribe {
  @extend %b_l_l;
  display: flex;
  flex-direction: column;
  gap: 3rem var(--gridgap);
  flex: 1;
  // @include media(md, lg-down) {
  //   flex-direction: row;
  //   > * {
  //     flex: 1;
  //   }
  // }
  @include media(md) {
    flex-direction: row;
    > * {
      flex: 1;
    }
  }
  &__title {
    @extend %h1;
    margin: 0 0 1rem;
  }
  &__form {
  }
}
.subscrube {
  &__text {
  }
}

.fnav {
  --fnav-gap: 3rem;
  display: flex;
  flex-direction: column;
  gap: var(--fnav-gap);
  @include media(md) {
    &::after {
      content: "";
      width: 0;
      height: 0;
      opacity: 0;
    }
    --fnav-gap: 1.5rem 3rem;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;
  }
  @include media(xl) {
    &::after {
      display: none;
    }
    justify-content: center;
  }
  &__list {
    @extend %ul_reset;
    display: flex;
    flex-direction: column;
    gap: var(--fnav-gap);
  }
  &__item {
    display: flex;
  }
  &__link {
    @extend %h4;
    white-space: nowrap;
  }
}

.fsocial {
  @extend %ul_reset;
  display: flex;
  gap: 1.8rem;
  @include media(md) {
    padding: 0.6rem 0;
  }
  &__item {
    display: flex;
    flex-shrink: 0;
  }
  &__link {
    @extend %extend16;
    display: block;
    --link-color: var(--primary);
    --link-color-hover: var(--primary-hover);
  }
  &__link-ico {
    width: 1.8rem;
    height: 1.8rem;
    stroke: none;
    fill: currentColor;
  }
}

.main:has(.cart__sticky) ~ .footer {
  @include media(md-down) {
    margin-bottom: calc(var(--mobnav-height) + 5.8rem);
  }
}
.main:has(.product__sticky) ~ .footer {
  @include media(lg-down) {
    margin-bottom: 5.8rem;
  }
  @include media(md-down) {
    margin-bottom: calc(var(--mobnav-height) + 5.8rem);
  }
}
