/* roboto-300 - cyrillic_latin */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-v30-cyrillic_latin-300.woff2") format("woff2");
}

/* roboto-regular - cyrillic_latin */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-v30-cyrillic_latin-regular.woff2") format("woff2");
}

/* roboto-500 - cyrillic_latin */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/roboto-v30-cyrillic_latin-500.woff2") format("woff2");
}

/* roboto-700 - cyrillic_latin */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-v30-cyrillic_latin-700.woff2") format("woff2");
}

/* roboto-900 - cyrillic_latin */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/roboto-v30-cyrillic_latin-900.woff2") format("woff2");
}

// ===================================
// ===================================
// ===================================

// @font-face {
//   font-display: swap;
//   font-family: 'Roboto';
//   src: url('../fonts/roboto-thin.woff2') format('woff2');
//   font-weight: 100;
//   font-style: normal;
// }
@font-face {
  font-display: swap;
  font-family: "Roboto";
  src: url("../fonts/roboto-light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  src: url("../fonts/roboto-regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  src: url("../fonts/roboto-medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  src: url("../fonts/roboto-bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  src: url("../fonts/roboto-black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

// ===================================
// ===================================
// ===================================

// /* inter-300 - cyrillic_latin */
// @font-face {
//   font-display: swap;
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 300;
//   src: url("../fonts/inter-v13-cyrillic_latin-300.woff2") format("woff2");
// }

// /* inter-regular - cyrillic_latin */
// @font-face {
//   font-display: swap;
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 400;
//   src: url('../fonts/inter-v13-cyrillic_latin-regular.woff2') format('woff2');
// }

// /* inter-700 - cyrillic_latin */
// @font-face {
//   font-display: swap;
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 700;
//   src: url("../fonts/inter-v13-cyrillic_latin-700.woff2") format("woff2");
// }

// /* SF Pro Display Bold */
// @font-face {
//   font-display: swap;
//   font-family: "SF Pro Display";
//   src: url("sf-pro-display-bold.woff2") format("woff2");
//   font-weight: 700;
//   font-style: normal;
// }
// /* SF Pro Display Bold Regular */
// @font-face {
//   font-display: swap;
//   font-family: "sf_pro";
//   src: url("sf-pro-display-regular.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }

// /* Basier Square Bold */
// @font-face {
//   font-family: "Basier Square";
//   src: url("basier_square_bold.woff2") format("woff2");
//   font-weight: 700;
//   font-style: normal;
// }
