// changed+fixed:

.hcat__link-ico {
  @include media(xl) {
    height: 3.6rem;
    // width: 4rem;
    max-width: none;
  }
}
.prodcard__image-img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.prodslider__pic {
  height: auto;
  @include media(lg) {
    height: auto;
  }
}

.prodslider__pic-img {
  max-height: 24.6rem;
  @include media(md) {
    max-height: 45rem;
  }
  @include media(lg) {
    max-height: 50rem;
  }
}

.hcat__link {
  &::after {
    content: "";
    width: 0.4rem;
    height: 0.4rem;
    display: block;
    background: var(--hcat-ico-color, var(--secondary));
    margin: 1rem auto -1.4rem;
    border-radius: 0.5rem;
    position: relative;
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: 0.3s;
    @include media(xl-down) {
      display: none !important;
    }
  }
  &:hover {
    color: var(--hcat-ico-color, var(--secondary)) !important;
    &::after {
      width: 0.4rem;
      height: 0.4rem;
      margin: 1rem auto -1.4rem;
      opacity: 1;
      visibility: visible;
    }
  }
}

// added:

.product .btn-favorite.m-active svg {
  fill: currentColor;
}

.product .add-to-compare.m-active svg {
  fill: currentColor;
}

.product__addr {
  position: absolute;
  right: calc(100% + 3.5rem);
  top: 50%;
  width: 70rem;
  border-radius: var(--br);
  transform: translateY(-50%);
  box-shadow: 0 0.9rem 2.9rem rgba(0, 0, 0, 0.2);
  padding: 3rem;
  box-sizing: border-box;
  background-color: var(--bg);
  color: var(--text);
  visibility: hidden;
  opacity: 0;
  transition-property: opacity, visibility, transform, right;
  transition-duration: 0.3s;
  z-index: 2;
  display: grid;
  gap: 1.5rem;

  [data-theme="dark"] & {
    background-color: var(--bg-gray);
  }

  @include media(xlc-down) {
    padding: 2rem;
    top: 100%;
    max-width: 100%;
    width: 42rem;
    right: auto;
    left: 0;
    transform: translate(0, 3rem);
  }
}

.product-avail__item:hover .product__addr {
  opacity: 1;
  visibility: visible;
  right: calc(100% + 15px);
  @include media(xlc-down) {
    right: auto;
    transform: translate(0, 0);
  }
}

.product__arr-row {
}

.product-avail__item {
  position: relative;
}

.product__addr-line {
  font-size: 16px;
  & + & {
    margin-top: 1.5rem;
  }
}

.product__addr-title {
  font-weight: bold;
}

.product__addr-line--row {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  .product__addr-line {
    margin: 0;
  }
  @include media(xlc-down) {
    flex-direction: column;
  }
}

.product__arr-link-inner {
  text-align: right;
  margin-top: 1.2em;
  pointer-events: none;
  margin-top: -1.2em;
  .product__addr-link {
    pointer-events: auto;
  }
  @include media(xlc-down) {
    margin-top: 1.5rem;
  }
}

.compare__holder .tags__tag {
  --_tag-bg: var(--btn-bg, var(--cl-gray-300));
}

.compare__holder .tags__tag.m-active {
  --_tag-color: var(--_tag-color-active, var(--tag-color-active));
  --_tag-border: var(--_tag-border-active, var(--primary));
  --_tag-bg: var(--_tag-bg-active, var(--tag-bg));
}

// @media (max-width: 992px) {
//   .order1 {
//     order: 1;
//   }

//   .order2 {
//     order: 2;
//   }

//   .order3 {
//     order: 3;
//   }

//   .order4 {
//     order: 4;
//   }
// }
