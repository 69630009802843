.tags {
  position: relative;
  &_donowrap {
    @include media(lg-down) {
      body:not(.mousable) & {
        .tags__list {
          flex-wrap: nowrap;
          overflow: auto;

          @include scrollgaps;
          @include hidescroll;
        }
        .tags__label,
        .tags__tag {
          white-space: nowrap;
        }
      }
    }
  }

  &__showmore-check {
    @extend %visuallyhidden;
    &:not(:checked) ~ * .tags__item:nth-child(8) ~ *:not(.tags__item_more) {
      @include media(md) {
        display: none;
      }
    }
    &:checked ~ * .tags__item_more {
      @include media(md) {
        display: none;
      }
    }
    &:focus-visible ~ * .tags__tag_more {
      @extend %fvisible;
    }
  }
  &__list {
    @extend %ul_reset;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--tgas-gap, 0.3rem);
  }
  &__item {
    display: flex;
  }
  &__label {
    @extend %b_l_l;
  }
  &__tag {
    @extend %btn_reset;
    @extend %b_s_l;
    color: var(--_tag-color, var(--tag-color));
    background-color: var(--_tag-bg, var(--tag-bg));
    box-sizing: border-box;
    padding: var(--tag-pad, 0.4rem 0.9rem);
    border: 1px solid var(--_tag-border, var(--tag-border));
    border-radius: var(--br);
    text-decoration: none !important;
    transition: border 0.3s, background-color 0.3s, color 0.3s;
    display: flex;
    gap: 1rem;

    &:hover,
    &:focus {
      --_tag-color: var(--_tag-color-hover, var(--tag-color-hover));
      --_tag-border: var(--_tag-border-hover, var(--primary));
      --_tag-bg: var(--_tag-bg-hover, var(--tag-bg));
    }
    align-items: center;
    &.m-active {
      --_tag-color: var(--_tag-color-active, var(--tag-color-active));
      --_tag-border: var(--_tag-border-active, var(--primary));
      --_tag-bg: var(--_tag-bg-active, var(--tag-bg));
    }
    &_more {
      --_tag-border: transparent;
      --_tag-bg: var(--tag-more-bg);
      --_tag-border-active: transparent;
      --_tag-bg-active: var(--tag-more-bg-hover);
      @include media(md-down) {
        display: none;
      }
    }

    &:focus-visible {
      @extend %fvisible;
      --btn-bg: var(--primary);
    }
  }
  &__color {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: var(--tc-color);
    &_lightborder {
      border: 1px solid var(--tag-lightborder);
    }
    &_darkborder {
      border: 1px solid var(--tag-darkborder);
    }
  }

  &_md {
    --tgas-gap: 1rem;
    --tag-pad: 0.9rem 1.9rem;
    --_tag-border: transparent;
    --_tag-bg: var(--bg-gray);

    // --_tag-color-hover:
    // --_tag-bg-hover:
    --_tag-border-hover: var(--primary);
    --_tag-bg-hover: var(--bg-gray);

    --_tag-border-active: transparent;
    --_tag-bg-active: var(--primary);
    --_tag-color-active: var(--cl-white);
  }
  &_md &__tag {
    @extend %b_l_l;
  }

  &_lg {
    --tgas-gap: 1rem;
    --tag-pad: 1.1rem 1.9rem;
    --_tag-border: var(--border-color-light);
    --_tag-bg: transparent;

    --_tag-border-hover: var(--secondary);
    --_tag-border-active: var(--secondary);
    --_tag-bg-active: transparent;
  }
  &_lg &__tag {
    @extend %b_l_l;
    &.m-active {
      --_tag-color: var(--cl-white);
      --_tag-bg-active: var(--secondary);
    }
  }
}
