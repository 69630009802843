@use "sass:math";
// ======================
// Fluid Adaptive Multi-Grid With Fixed/Fluid Gaps [Flexbox Edition]
// Version: 1.0.2 (25 Mar 2022)
// by Teftelkin with love and stuff.
// Based on the solution by mr. Koldunsky and mr. Teftelkin
// from the good old kdx-days.
// ======================

// grid naming
$gridcontainer_name: "row";
$grid_name: "col";

// ======================================
// Settings
// ======================================
// Mobile first example
$grid-map: (
  // This demonstration is in mobile >> desktop direction,
  // but you can do WHATEVER YOU WANT о_О
  default:
    (
      class-prefix: "",
      min-width: null,
      max-width: null,
      container: 100%,
      columns: 12,
      gap: $gridgap,
    ),
  small: (
    class-prefix: "-sm",
    min-width: $sm,
    max-width: null,
    container: 100%,
    columns: 12,
    gap: $gridgap,
  ),
  medium: (
    class-prefix: "-md",
    min-width: $md,
    max-width: null,
    container: 100%,
    columns: 12,
    gap: $gridgap,
  ),
  large: (
    class-prefix: "-lg",
    min-width: $lg,
    max-width: null,
    container: 100%,
    columns: 12,
    gap: $gridgap,
  ),
  xl: (
    class-prefix: "-xl",
    min-width: $xl,
    max-width: null,
    container: 100%,
    columns: 12,
    gap: $gridgap,
  ),
  xxl: (
    class-prefix: "-xxl",
    min-width: $xxl,
    max-width: null,
    container: 100%,
    columns: 12,
    gap: $gridgap,
  )
);

// ======================================
// Grid function
// ======================================
@mixin make-grid($map) {
  // ======================================
  // Grid settings
  // ======================================
  $prefix: map-get($map, class-prefix);
  $min-width: map-get($map, min-width);
  $max-width: map-get($map, max-width);
  $container: map-get($map, container);
  $columns: map-get($map, columns);
  $gap: 0px;

  @if map-get($map, gap) % 2 == 0 or unit(map-get($map, gap)) != "px" {
    $gap: map-get($map, gap);
  } @else {
    $gap: map-get($map, gap) + 1;
  }

  $column_width: math.div($container, $columns);

  // ======================================
  // Container
  // ======================================
  .#{$gridcontainer_name} {
    --gridgap-n: calc(-1 * var(--gridgap));
    width: if($container == 100%, auto, $container);
    margin-left: calc(var(--gridgap-n) / 2) !important;
    margin-right: calc(var(--gridgap-n) / 2) !important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    &_vgap {
      gap: var(--gridgap) 0 !important;
    }
    &_extragap {
      --gridgap: min(calc(var(--sidegap) * 2), 5rem);
    }
  }

  // ======================================
  // Grid
  // ======================================
  [class^="#{$grid_name}-"],
  [class*=" #{$grid_name}-"] {
    padding-left: calc(var(--gridgap) / 2);
    padding-right: calc(var(--gridgap) / 2);
    width: 100%;
  }

  .#{$grid_name}#{$prefix} {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .#{$grid_name}#{$prefix}-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  @for $i from 1 through $columns {
    .#{$grid_name}#{$prefix}-#{$i} {
      -ms-flex: 0 0 $column_width * $i;
      flex: 0 0 $column_width * $i;
      max-width: $column_width * $i;
    }
  }
  @for $i from 1 to $columns {
    .prefix#{$prefix}-#{$i} {
      margin-left: $column_width * $i;
    }
  }
  @for $i from 1 to $columns {
    .suffix#{$prefix}-#{$i} {
      margin-right: $column_width * $i;
    }
  }
  @for $i from 1 to $columns {
    .push#{$prefix}-#{$i} {
      left: $column_width * $i;
    }
  }
  @for $i from 1 to $columns {
    .pull#{$prefix}-#{$i} {
      left: -($column_width * $i);
    }
  }

  .order#{$prefix}-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order#{$prefix}-last {
    -ms-flex-order: $columns + 1;
    order: $columns + 1;
  }

  @for $i from 1 through $columns {
    .order#{$prefix}-#{$i} {
      -ms-flex-order: #{$i};
      order: #{$i};
    }
  }

  .prefix#{$prefix}-0 {
    margin-left: 0;
  }
  .suffix#{$prefix}-0 {
    margin-right: 0;
  }
  .push#{$prefix}-0 {
    left: 0;
  }
  .pull#{$prefix}-0 {
    left: 0;
  }

  // ======================================
  // Reset grid option
  // ======================================
  .nogrid#{$prefix} {
    left: auto;
    right: auto;
    margin: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: none;
    -ms-flex-order: 0;
    order: 0;
    // No padding reset! Container has negative margin still.
    // padding: 0;
  }
}

// ======================================
// Default grid styles
// ======================================
.#{$gridcontainer_name} {
  position: relative;
}
[class^="#{$grid_name}-"],
[class*=" #{$grid_name}-"] {
  position: relative;
  min-height: 1px;
  box-sizing: border-box;
}

// ======================================
// Big bada loop
// ======================================
@each $label, $map in $grid-map {
  $min-width: map-get($map, min-width);
  $max-width: map-get($map, max-width);
  $min-width-str: if($min-width == null, "", " and (min-width: #{$min-width}) ");
  $max-width-str: if($max-width == null, "", " and (max-width: #{$max-width}) ");
  $media-str: "all" + $min-width-str + $max-width-str;

  @if $min-width or $max-width {
    // make responsive grid
    @media #{$media-str} {
      @include make-grid($map); // One grid, please
    }
  } @else {
    // make default grid
    @include make-grid($map); // One grid, please
  }
}
// ======================================
// ======================================
// ======================================
