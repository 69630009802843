.btn {
  @extend %btn_reset;
  @extend %b_xl_l;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;

  padding: 1.5rem 1.9rem;
  height: 5rem;
  border-radius: var(--br);

  border: 1px solid var(--btn-border, transparent);
  color: var(--btn-color, var(--cl-white)) !important;
  background-color: var(--btn-bg, var(--cl-gray-300));

  user-select: none;
  text-decoration: none !important;
  transition: all 0.3s var(--easing);
  max-width: 100%;

  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;

  // &:focus:not(:focus-visible) {
  //   outline: none;
  //   box-shadow: none;
  // }

  outline: none;
  box-shadow: none;

  svg {
    width: 1.8rem;
    height: 1.8rem;
  }

  &:hover {
    --btn-border: var(--btn-border-hover, transparent);
    --btn-color: var(--btn-color-hover, var(--cl-white));
    --btn-bg: var(--btn-bg-hover, var(--primary));
  }
  &:focus {
    --btn-border: var(--btn-border-hover, transparent);
    --btn-color: var(--btn-color-hover, var(--cl-white));
    --btn-bg: var(--btn-bg-hover, var(--primary));
  }
  &:focus-visible {
    @extend %fvisible;
  }
  &:active {
    --btn-border: var(--btn-border-active, transparent);
    --btn-color: var(--btn-color-active, var(--cl-white));
    --btn-bg: var(--btn-bg-active, var(--primary));
  }

  &.disabled,
  &[disabled] {
    cursor: default;
    pointer-events: none;
    --btn-border: var(--btn-border-disabled, var(--disabled));
    --btn-color: var(--btn-color-disabled, var(--cl-text));
    --btn-bg: var(--btn-bg-disabled, var(--disabled));
  }

  &_cta {
    --btn-border: transparent;
    --btn-color: var(--cl-white);
    --btn-bg: var(--primary);

    --btn-border-hover: transparent;
    --btn-color-hover: var(--cl-white);
    --btn-bg-hover: var(--primary-hover);

    --btn-border-active: transparent;
    --btn-color-active: var(--cl-white);
    --btn-bg-active: var(--primary-active);

    --btn-border-disabled: transparent;
    --btn-color-disabled: var(--cl-text);
    --btn-bg-disabled: var(--disabled);
  }
  &_orange {
    --btn-border: transparent;
    --btn-color: var(--cl-white);
    --btn-bg: var(--cl-orange);

    --btn-border-hover: transparent;
    --btn-color-hover: var(--cl-white);
    --btn-bg-hover: var(--cl-orange-hover);

    --btn-border-active: transparent;
    --btn-color-active: var(--cl-white);
    --btn-bg-active: var(--cl-orange-active);

    --btn-border-disabled: transparent;
    --btn-color-disabled: var(--cl-text);
    --btn-bg-disabled: var(--disabled);
  }
  &_light {
    --btn-border: transparent;
    --btn-color: var(--cl-green);
    --btn-bg: var(--cl-gray-light);

    --btn-border-hover: transparent;
    --btn-color-hover: var(--cl-green);
    --btn-bg-hover: var(--cl-gray);

    --btn-border-active: transparent;
    --btn-color-active: var(--cl-green);
    --btn-bg-active: var(--cl-gray);

    --btn-border-disabled: transparent;
    --btn-color-disabled: var(--cl-text);
    --btn-bg-disabled: var(--disabled);

    &.btn_sm {
      font-size: 1.4rem;
    }
  }
  &_outline {
    --btn-border: var(--cl-gray);
    --btn-color: var(--text);
    --btn-bg: transparent;

    --btn-border-hover: var(--primary);
    --btn-color-hover: var(--text);
    --btn-bg-hover: transparent;

    --btn-border-active: var(--primary-primary);
    --btn-color-active: var(--text);
    --btn-bg-active: transparent;

    --btn-border-disabled: var(--cl-gray);
    --btn-color-disabled: var(--text);
    --btn-bg-disabled: var(--disabled);
  }

  &_sm {
    padding: 0.9rem 1.4rem;
    height: 3.8rem;
  }

  &_md {
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    height: 4.6rem;
  }

  &_nexttoinput {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &_notext {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    min-width: 4.5rem;
  }
}

%btn-special {
  padding: 0.9rem 1.4rem;
  height: 3.8rem;
}

.btn-link {
  @extend %btn_reset;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: var(--primary);
  transition: 0.3s;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.7rem;
  flex-shrink: 0;
  &_text {
    color: var(--text);
  }
  &_tdu {
    text-decoration: underline;
  }
  &:hover {
    color: var(--primary-hover);
  }

  &_orange {
    color: var(--cl-orange);
    &:hover {
      color: var(--cl-orange-hover);
    }
  }
}

.btn-pill {
  @extend %btn_reset;
  @extend %b_s_l;
  padding: 0.5rem 1rem;
  border-radius: 4rem;
  color: var(--btnpill-color);
  background-color: var(--btnpill-bg);
  transition: background 0.3s, color 0.3s;
  text-decoration: none;
  display: inline-flex;
  &:hover,
  &:focus {
    color: var(--btnpill-color);
    background-color: var(--btnpill-bg-hover);
  }
  &_cta {
    color: var(--cl-white);
    background-color: var(--secondary);
    &:hover,
    &:focus {
      color: var(--cl-white);
      background-color: var(--secondary-hover);
    }
  }
  &_large {
    padding: 0.8rem 1rem;
    border-radius: var(--br);
  }
}
