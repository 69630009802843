.prodslider {
  background-color: var(--cl-white);
  border-radius: var(--br);
  overflow: hidden;
  padding-top: 1.6rem;
  margin-top: -1.6rem;

  &__slider {
    @include media(md-down) {
      padding-bottom: 2.4rem;
    }
  }
  &__slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__pic {
    display: block;
    height: 45rem;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    // @include media(md) {
    //   height: 33rem;
    // }
    @include media(lg) {
      height: 50rem;
    }
  }
  &__pic-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    // height: 100%;
    // width: auto;
  }

  &__thumbs-wrapper {
    position: relative;
    width: 100%;
    margin: 2rem auto;
    padding: 2rem;
    height: 11rem;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    box-sizing: border-box;
    padding: 2rem 7rem;
    @include media(md-down) {
      display: none;
    }
  }

  &__arrows {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    pointer-events: none;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
  }
  &__arrow {
    @extend %btn_reset;
    pointer-events: auto;
    width: 6.4rem;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s;
    &.swiper-button-disabled {
      opacity: 0.2;
    }
  }
  &__arrow-ico {
    width: 1.5rem;
    height: 2.6rem;
    stroke: var(--secondary);
    fill: none;
  }

  &__slider-pagination {
    --swiper-pagination-bottom: 1rem;
    --swiper-pagination-top: auto;
    --swiper-pagination-bullet-horizontal-gap: 0.8rem;
    --swiper-pagination-bullet-size: 0.4rem;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-inactive-color: var(--cl-gray);
    @include media(md) {
      display: none !important;
    }
  }
}

.thumbs {
  &__slide {
    cursor: pointer;
    // width: 7rem;
    // height: 7rem;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__pic {
    display: inline-block;
    width: auto;
    height: 7rem;
    overflow: hidden;
    box-sizing: border-box;
    padding: 1rem;
    border: 1px solid transparent;
    transition: border 0.2s;
    .swiper-slide-thumb-active & {
      border-color: var(--cl-gray);
    }
  }
  &__pic-img {
    width: auto;
    height: 100%;
  }
}
