.tabs {
  &__tags {
    display: flex;
    justify-content: center;
    margin: 0 0 2rem;
  }
  &__select {
    @extend %ul_reset;
    margin: 0 var(--sidegap-n) 2rem;
    position: relative;
    display: flex;
    gap: 1.5rem;
    overflow: auto;
    padding: 0 var(--sidegap) 1rem;
    scrollbar-width: thin;
    @include media(xl) {
      gap: 3rem;
    }
  }
  &__select-item {
    display: flex;

    // &:not(:first-child) {
    //   padding-left: var(--sidegap);
    //   margin-left: calc(3rem - var(--sidegap));
    // }

    &_acc {
      @include media(lg-down) {
        display: none;
      }
      @include media(lg) {
        order: -1;
        // padding-right: var(--sidegap);
        // margin-right: calc(3rem - var(--sidegap));
        // padding-left: 0 !important;
        // margin-left: 0 !important;
      }
    }
    &_about {
      @include media(lg-down) {
        order: -1;
      }
    }
  }
  &__select-trigger {
    @extend %btn_reset;
    @extend %h4h1;
    color: var(--tabs-trigger);
    display: block;
    white-space: nowrap;
    transition: color 0.3s;
    @include media(lg, xl-down) {
      font-size: clamp(2rem, 2.2vw, 2.5rem);
    }
    &.m-active,
    &:hover {
      color: var(--tabs-trigger-primary);
    }
  }
  &__tabs {
    position: relative;
  }
  &__tab {
    &:not(.m-active) {
      position: absolute;
      height: 0;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
    }
    &_acc {
      @include media(lg-down) {
        position: relative !important;
        height: auto !important;
        opacity: 1 !important;
        visibility: visible !important;
        overflow: visible !important;
        margin-top: 4rem;
      }
      @include media(md-down) {
        .productlist {
          display: flex;
          overflow: auto;

          @include scrollgaps;
          @include hidescroll;
        }
        .prodcard {
          min-width: 27rem;
        }
      }
    }
  }

  &__tab-title {
    @extend %h3h1;
    display: none;
    margin: 0 0 1.5rem;
    @include media(md) {
      margin-bottom: 3rem;
    }
  }
  &__tab_acc &__tab-title {
    @include media(lg-down) {
      display: block;
    }
  }
}
