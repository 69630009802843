// BREAKPOINTS

// Just choose your resolutions
$breakpoints: (
  "sm-down": (
    max-width: $sm - 0.02,
  ),
  "sm": (
    min-width: $sm,
  ),
  "md-down": (
    max-width: $md - 0.02,
  ),
  "md": (
    min-width: $md,
  ),
  "lg-down": (
    max-width: $lg - 0.02,
  ),
  "lg": (
    min-width: $lg,
  ),
  "xl-down": (
    max-width: $xl - 0.02,
  ),
  "xl": (
    min-width: $xl,
  ),
  "xlc-down": (
    max-width: $xlc - 0.02,
  ),
  "xlc": (
    min-width: $xlc,
  ),
  "xxl-down": (
    max-width: $xxl - 0.02,
  ),
  "xxl": (
    min-width: $xxl,
  ),
  "xxxl-down": (
    max-width: $xxxl - 0.02,
  ),
  "xxxl": (
    min-width: $xxxl,
  ),
  "xxxxl-down": (
    max-width: $xxxxl - 0.02,
  ),
  "xxxxl": (
    min-width: $xxxxl,
  ),
);

@mixin media($name, $name2: false) {
  @if $name2 {
    @if map-has-key($breakpoints, $name) and map-has-key($breakpoints, $name2) {
      $media-str: "#{inspect(map-get($breakpoints, $name))} and #{inspect(map-get($breakpoints, $name2))}";
      @media #{$media-str} {
        @content;
      }
    } @else {
      @warn "Couldn't find a breakpoint named #{$name} or #{$name2}.";
    }
  } @else if map-has-key($breakpoints, $name) {
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  } @else {
    @warn "Couldn't find a breakpoint named #{$name}.";
  }
}
// USAGE
// -------------
// .element {
//   color: red;
//   @include media(md) {
//     color: green;
//   }
//   @include media(lg, xl-down) {
//     color: blue;
//   }
// }
