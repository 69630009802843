.bnrs2 {
  &__list {
    @extend %ul_reset;
    display: grid;
    gap: 0.1rem;
    @include media(md) {
      grid-template-columns: 1fr 1fr;
    }
    @include media(xl) {
      grid-template-columns: 1fr 1fr 37.8rem;
    }
  }
  &__item {
    --_bnrs2-pi: 3rem;
    --_bnrs2-pb: 2.5rem;
    @include media(lg-down) {
      --_bnrs2-pi: 3rem;
      --_bnrs2-pb: 3rem;
    }
    @include media(sm-down) {
      --_bnrs2-pi: 1.5rem;
      --_bnrs2-pb: 3rem;
    }
    display: flex;
    position: relative;
    --link-color: var(--cl-text);
    --link-color-hover: var(--cl-text);
    &_cert {
      --_bnrs2-op-y: 100%;
      @include media(sm-down) {
        --_bnrs2-title-mw: 60%;
        --_bnrs2-jc: flex-start;
        --_bnrs2-op-x: 40%;
      }
      @include media(md, xl-down) {
        grid-column: span 2;
      }
    }
    &_tradein {
      --_bnrs2-op-x: 25%;
      @include media(sm-down) {
        --_bnrs2-pb: 4rem;
      }
    }
    &_repair {
      color: var(--cl-white);
      background-color: var(--cl-black);
      --link-color: var(--cl-white);
      --link-color-hover: var(--cl-white);
      --_bnrs2-jc: center;
      --_bnrs2-pi: 4rem;
      --_bnrs2-title-mw: 14rem;
      @include media(sm-down) {
        --_bnrs2-pi: 3rem;
      }
    }
  }
  &__link {
    position: relative;
    display: flex;
    width: 100%;
    // &,
    // &:hover,
    // &:focus {
    //   color: var(--cl-text);
    // }
  }
  &__pic {
    display: block;
    width: 100%;
    height: 36rem;
    position: relative;
    @include media(sm) {
      height: 42rem;
    }
    @include media(xl) {
      height: 30.6rem;
    }
  }
  &__pic-video,
  &__pic-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: var(--_bnrs2-op-x, 50%) var(--_bnrs2-op-y, 50%);
    // @include media(md) {
    //   object-position: 0% 50%;
    // }
    // @include media(xl) {
    //   object-position: 20% 70%;
    // }
  }
  // &__item_special &__pic-img {
  //   object-position: 50% 50%;
  // }
  &__content {
    position: absolute;
    bottom: var(--_bnrs2-pb);
    left: var(--_bnrs2-pi);
    right: var(--_bnrs2-pi);
    top: var(--_bnrs2-pb);
    display: flex;
    flex-direction: column;
    justify-content: var(--_bnrs2-jc, flex-end);
    gap: 1rem;
  }

  &__title {
    @extend %h1;
    max-width: var(--_bnrs2-title-mw, none);
  }
  &__text {
    @extend %h3;
    max-width: var(--_bnrs2-text-mw, 25rem);
  }
}
