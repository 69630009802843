.offers {
  &__list {
    @extend %ul_reset;
    display: grid;
    gap: 1.5rem 2.4rem;
    @include media(xl) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &__item {
    display: flex;
  }
  &__link {
    display: flex;
    width: 100%;
  }
  &__pic {
    display: block;
    width: 100%;
    height: auto;
    // @include media(xl) {
    //   height: 20rem;
    // }
  }
  &__pic-img {
    display: block;
    width: 100%;
    height: auto;
    // @include media(xl) {
    //   height: 100%;
    //   object-fit: cover;
    // }
  }
}
