.popup {
  --popinner-width: 80rem;
  &_wide {
    --popinner-width: 96rem;
  }
  &_tight {
    --popinner-width: 40rem;
  }
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 0;
  z-index: $z + 10;
  background-color: var(--overlay);

  opacity: 0;
  visibility: hidden;
  transition-duration: 0.3s;
  transition-property: opacity, visibility;

  overscroll-behavior: contain;
  &.m-active {
    opacity: 1;
    visibility: visible;
  }

  &__fadeclose {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  &__inner {
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: var(--bg);
    overflow: auto;
    padding: 3rem var(--sidegap);
    box-sizing: border-box;
    overscroll-behavior: contain;
    @include media(md) {
      border-radius: var(--br);
      width: var(--popinner-width);
      max-width: calc(100% - var(--sidegap) * 2);
      height: auto;
      max-height: 95%;
      // padding: 3rem 2.5rem;
      border: 1px solid var(--border-color);
    }

    @include media(md) {
      &:has(.suggest) {
        overflow: visible;
        max-height: none;
      }
    }
    .popup_wide & {
      @include media(md, lg-down) {
        border-radius: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        border: none;
      }
    }
  }

  &__close {
    @extend %btn_reset;
    @extend %extend16;
    width: 1.8rem;
    height: 1.8rem;
    cursor: pointer;
    position: absolute;
    right: 2.5rem;
    top: 3rem;
    z-index: 3;
    color: var(--text);
    transition: color 0.3s;
    margin: 0 !important;
    &:focus,
    &:hover {
      color: var(--primary);
    }
    @include media(md-down) {
      right: 1.7rem;
      top: 2.4rem;
    }
  }
  &__close-ico {
    width: 1.8rem;
    height: 1.8rem;
    stroke: currentColor;
    fill: none;
  }

  &__title {
    @extend %h2h1;
    padding-right: 4rem;
    margin: 0 0 3rem;
    display: flex;
    align-items: flex-start;
    gap: 0 1.5rem;
    .btn-link {
      &:not(:hover) {
        color: var(--text-secondary);
      }
    }
  }

  &__added {
    display: grid;
    gap: 3rem;
  }

  .cart-prodcard,
  &__prodinfo {
    order: -1;
  }

  &__products {
    min-width: 0;
    .productlist {
      --productlist-cols: 3;
      --productlist-col-width: 1fr;

      @include media(md-down) {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        @include scrollgaps;
        body:not(.mousable) & {
          @include hidescroll;
        }
      }
    }
    .prodcard {
      @include media(md-down) {
        min-width: 22rem;
      }
    }
  }
  &__products-title {
    @extend %h3;
    margin: 0 0 1.5rem;
  }
  &__products-tags {
    margin: 1.5rem 0;
  }

  &__prodinfo {
    // border: solid var(--border-color);
    // border-width: 0 0 1px;
    display: grid;
    align-items: start;
    gap: 2rem 3rem;
    @include media(md) {
      grid-template-columns: max-content 1fr;
      padding: 0 0 2rem;
      border: solid var(--border-color);
      border-width: 0 0 1px;
      align-items: center;
    }
    @include media(lg) {
      border-width: 1px 0;
      padding: 2rem 0;
    }
  }
  &__prodbtns {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;
    @include media(lg-down) {
      display: none;
    }
    @include media(lg) {
    }
    .btn {
      @include media(md-down) {
        min-width: 31.5rem;
        &.btn_outline {
          order: -1;
        }
      }
    }
  }

  &__prodbtns-out {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media(md) {
      position: sticky;
      margin-bottom: -3rem;
      bottom: -3rem;
      background-color: var(--bg);
      padding: 2rem 0;
      border-top: 1px solid var(--border-color);
      @include scrollgaps;
    }
    @include media(lg) {
      @media (min-height: 1000px) {
        padding: 3rem 0;
        margin-inline: 0;
      }
    }
    &-btns {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 2rem;
      @include media(lg) {
        display: none;
      }
    }

    @include media(md-down) {
      padding: 3rem 0;
      margin-top: -1rem;
      border: solid var(--border-color);
      border-width: 1px 0;
      order: -1;
      &,
      &-btns {
        flex-direction: column-reverse;
        gap: 1.5rem;
      }
      .btn {
        min-width: 31.5rem;
      }
    }
  }
}
