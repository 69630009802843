@mixin status-mb {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.182; /* 13px */

  background-color: var(--blue-new-01);
  color: var(--cl-blue);
  display: inline-flex;
  padding: 0.2rem 0.5rem 0.4rem;
  box-sizing: border-box;
  border-radius: 0.3rem;
}

// =========

// THEME SWITCHER
.header__top-wrapper {
  @include media(md-down) {
    transition: padding 0.2s linear;
    padding-bottom: 1rem;
    html[data-theme="dark"] .header-toggle:checked ~ * & {
      padding-bottom: 2rem;
    }
  }
}
// .header__nav-inner {
//   @include media(md-down) {
//     padding-bottom: 7rem;
//   }
// }
.header__controls {
  // @include media(md-down) {
  //   transition-property: opacity, visibility;
  //   transition-duration: 0.3s;
  //   position: fixed;
  //   bottom: 6rem;
  //   left: 0;
  //   right: 0;
  //   // left: 50%;
  //   // transform: translateX(calc(-50% - 5rem)) !important;
  //   z-index: 5;
  //   background-color: var(--bg);
  //   padding: 2rem 0;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   gap: 2.4rem;
  //   flex-wrap: wrap;
  //   margin: 0 !important;
  //   #header-toggle:not(:checked) ~ * & {
  //     opacity: 0;
  //     visibility: hidden;
  //     pointer-events: none;
  //   }
  // }
  @include media(md-down) {
    display: none;
  }
}
.theme-switcher-wrapper {
  // @include media(md-down) {
  //   transform: none !important;
  //   margin-right: 11rem;
  // }
  &_nav {
    @include media(md) {
      display: none !important;
    }
    @include media(md-down) {
      transform: none !important;
      margin-right: 11rem;
      align-self: center;
    }
  }
}
// /THEME SWITCHER

// BURGER | LOGO | PHONE
.header__burger {
  @include media(md-down) {
    order: -3;
    .burger {
      margin: 0;
    }
  }
}
.header__logo {
  @include media(md-down) {
    order: -2;
    width: 15.6rem;
  }
}
.header__call {
  @include media(md-down) {
    order: -1;
    margin: 0;
  }
}
// /BURGER | LOGO | PHONE

// HEADER CATALOG
.location-guess {
  @include media(md-down) {
    order: 1;
  }
}
.header__bottom {
  @include media(md-down) {
    display: block;
    opacity: 1;
    visibility: visible;
    background-color: var(--bg);
    pointer-events: auto;
    overflow: auto;
    scrollbar-width: none;
    height: var(--header-cat-height);
    transition: height 0.2s, border 0.2s;
    transition-delay: 0.2s;
    flex-shrink: 0;
    margin-top: -1rem;

    .header-toggle:checked ~ * & {
      height: 0;
      border-width: 0;
      transition-delay: 0s;
      overflow: hidden;
    }

    .header__cat {
      padding-block: 1.5rem;
      margin-inline: -1rem;
    }
    .hcat__list {
      display: flex;
      gap: 1rem;
    }
    .hcat__item {
      min-width: 4rem;
    }
    .hcat__link {
      br {
        display: none;
      }
    }
    .hcat__link-ico {
      height: 2.3rem;
    }
  }
}

// /HEADER CATALOG

// CATALOG

.catalog {
  @include media(md-down) {
    .tags__tag {
      padding: 0.9rem;
    }
    .catalog__sort {
      flex-direction: row-reverse;
    }

    .productlist {
      display: grid;
      grid-template-columns: [start] 1fr [end];
      gap: 0;
    }
    .prodcard {
      display: grid;
      align-items: center;
      grid-template-columns: 8rem 1fr;
      grid-template-rows: min-content 1fr;
      // grid-template-rows: 1fr min-content;
      gap: 0.6rem 0.5rem;
      padding: 1rem 0;
      border-width: 0;

      &:not(:first-child) {
        // border-top-width: 1px;
        margin-top: 0.8rem;
      }
      [data-theme="dark"] & {
        padding-inline: 0.4rem;
      }
    }
    [data-theme="light"] & {
      .prodcard {
        border-radius: 0;
        &:not(:first-child) {
          border-top-width: 1px;
          margin-top: 0.5rem;
        }
      }
    }
    .mainslider + .prodcard:nth-child(2) {
      border-top-width: 0;
      margin-top: 0;
    }
    .prodcard__top {
      grid-row: span 2;
    }
    .prodcard__pic {
      height: 8.8rem !important;
      margin: 0;
    }
    .prodcard__img-holder {
      left: 0;
      right: 0;
    }

    .prodcard__name {
      margin: 0 !important;
      font-weight: 400;
      font-size: 1.6rem;

      /* line-height: 1.9rem; */
      line-height: 1.1875;
    }
    .actions {
      --prodcard-actions-color: var(--font-light);
    }
    .actions__tooltip {
      transform: translateX(-80%);
    }

    .prodcard__bottom {
      min-width: 0;
      margin-bottom: -1rem;
    }

    .m-outofstock {
      .prodcard__bottom {
        // display: flex;
        // flex-direction: column-reverse;
        // justify-content: flex-start;
        // align-items: flex-start;
        // gap: 1.5rem;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        gap: 0.4rem 1rem;
        align-self: start;
      }
      .prodcard__statusprice {
        display: none;
      }
      .prodcard__btn {
        width: auto;
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 1.9rem;
      }
      // .prodcard__btn-text-nw {
      //   white-space: normal;
      // }
      .prodcard__actions {
        flex: 1;
        max-width: 4.6rem;
        justify-content: space-between;
        &::before {
          display: none;
        }
      }
      .actions__tooltip {
        left: auto;
        right: 0;
        transform: none;
      }
    }
    .mainslider {
      margin-block: 0.5rem 0;
      [data-theme="dark"] & {
        margin-block: 0.8rem 0;
      }
      @include media(sm) {
        & ~ .prodcard:nth-child(6) {
          border-width: 0;
        }
      }

      @include media(sm-down) {
        & ~ .prodcard:nth-child(5) {
          border-width: 0;
        }
      }
    }
    .prodcard__bottom:has(.prodcard__status:not(.m-no)) {
      padding-top: 1.8rem;
      position: relative;
      .prodcard__statusprice {
        align-self: center;
      }
    }
    .prodcard__status:not(.m-no) {
      @include status-mb;
      position: absolute;
      top: 0%;
      max-width: 100%;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;

      &::before,
      &::after {
        display: none;
      }
    }
  }
}
.catalog__filter-trigger {
  @extend %b_s;
  @include media(xl-down) {
    background-color: var(--primary);
    color: var(--cl-white);
    padding: 0.8rem 1rem;
    border-radius: var(--br);
    position: relative;
    gap: 0.8rem;
    display: flex;
    align-items: center;
    &:hover,
    &:focus {
      background-color: var(--primary-hover);
      color: var(--cl-white);
    }
    &::after {
      content: "";
      width: 1.4rem;
      height: 1.4rem;
      background-color: currentColor;
      mask-size: 1.4rem 1.4rem;
      mask-repeat: no-repeat;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3E%3Cpath stroke='%23fff' d='M2 .76v6.5m5-6.5v4.5m5-4.5v7.5m-10 1v4.5m5-6.5v6.5m5-3.5v3.5M0 9.26h4m1-4h4m1 5h4'/%3E%3C/svg%3E");
    }
  }
}

//

// PRODUCT PAGE
.product__sticky {
  display: flex;
  flex-direction: column;
  gap: 1.5rem 1rem;
  @include media(lg-down) {
    position: fixed;
    z-index: 5;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: row;
    background-color: var(--bg);
    padding: 1rem var(--sidegap);
    .product__price {
      font-size: 3rem;
      white-space: nowrap;
    }
    .product__btns {
      flex: 1;
      gap: 0.8rem;
      .btn {
        & ~ * {
          display: none;
        }
      }
    }
  }
  @include media(md-down) {
    bottom: var(--mobnav-height);
  }
}
.product__btns {
  &_md,
  &_lg {
    padding-top: 1.5rem;
    border-top: 1px solid var(--border-color);
  }
  &_md {
    @include media(md) {
      display: none;
    }
  }
  &_lg {
    @include media(lg) {
      display: none;
    }
  }
}

// .acclist,
.product__options {
  @include media(md-down) {
    .tags__tag {
      background-color: var(--bg-gray);
      color: var(--text);
      padding: 1.1rem 1.9rem;
      &.m-active {
        background-color: var(--bg-gray);
        color: var(--text);
        padding: 1rem 1.8rem;
        border-width: 0.2rem;
      }
    }
  }
}

.product__controls {
  // @include media(md-down) {
  .btn_light {
    --btn-border: var(--border-color);
    --btn-color: var(--cl-text);
    --btn-bg: var(--cl-gray-light);

    --btn-border-hover: var(--border-color);
    --btn-color-hover: var(--cl-text);
    --btn-bg-hover: var(--cl-gray);

    --btn-border-active: var(--border-color);
    --btn-color-active: var(--cl-text);
    --btn-bg-active: var(--cl-gray);

    --btn-border-disabled: var(--border-color);
    --btn-color-disabled: var(--cl-text);
    --btn-bg-disabled: var(--disabled);

    .ico {
      color: var(--cl-green);
    }
  }
  // }
}

.tight-prodcards {
  @include media(md-down) {
    .productlist {
      gap: 0;
    }
    .slider_prod:not(.swiper-initialized) .swiper-wrapper {
      // margin-inline: 0;
      padding-inline: var(--sidegap);
    }
    .slider__slide {
      padding-inline: 0;
      // margin-right: -1px;
    }
    .prodcard {
      padding: 1rem 1rem 2rem;
      width: 15.2rem !important;
      min-width: 15.2rem;
      margin-right: -1px;
      .prodcard__pic {
        height: 17.2rem;
      }
      .prodcard__img-holder {
        left: 0;
        right: 0;
      }
      .prodcard__middle,
      .prodcard__bottom {
        margin-inline: 0;
      }
      .prodcard__actions {
        display: none;
      }
      .prodcard__btn:not(.btn_gray) {
        // padding-inline: 1.4rem;
        flex: 1;
        max-width: 4.8rem;
        margin-left: auto;
      }
      .btn_gray {
        font-size: 1.2rem;
        padding-inline: 0.4rem;
      }
      .prodcard__status.m-no {
        display: none;
      }
      &.m-outofstock .prodcard__bottom {
        align-items: flex-end;
      }
      .prodcard__status {
        max-width: 9rem;
      }
    }
  }
}

.prodinfolist__item_md {
  @include media(md) {
    display: none;
  }
}
.prodinfolist__item_lg {
  @include media(lg) {
    display: none;
  }
}

.btn-credit-text {
  @extend %btn_reset;
  color: var(--cl-orange);
  display: flex;
  gap: 0.4rem;
  align-items: center;
  .ico {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.status-mb {
  @include status-mb;
  * + &,
  & + * {
    margin-top: 1rem;
  }
  @include media(md) {
    display: none;
  }
}

// /PRODUCT PAGE
