%h00 {
  font-size: 8rem;
  font-weight: 500;
  line-height: 1.172;
  margin: 0;
  @include media(md) {
    font-size: 12rem;
  }
}
%h00a {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1.172;
  margin: 0;
}
%h00b {
  font-size: 3rem;
  font-weight: 400;
  line-height: 0.967; /* 29px */
  margin: 0;
  @include media(md) {
    font-size: 5rem;
    font-weight: 400;
    line-height: 1.172;
  }
}

%h0 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 0.967; /* 29px */
  margin: 0;
}
%h0a {
  font-size: 3rem;
  font-weight: 400;
  line-height: 0.967; /* 29px */
  margin: 0;
  @include media(xl) {
    font-size: 5.4rem;
    font-weight: 500;
    line-height: 1.1667;
  }
}
%h0b {
  font-size: 3rem;
  font-weight: 400;
  line-height: 0.967; /* 29px */
  margin: 0;
  @include media(md) {
    font-size: 4rem;
    font-weight: 500;
    line-height: 0.967;
  }
}
%h0c {
  font-size: 3rem;
  font-weight: 400;
  line-height: 0.967; /* 29px */
  margin: 0;
  @include media(md) {
    font-size: 4rem;
    font-weight: 400;
    line-height: 0.967;
  }
}
%h1h0 {
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.16; /* 29px */
  margin: 0;
  @include media(md) {
    font-size: 3rem;
    font-weight: 400;
    line-height: 0.967; /* 29px */
  }
}
%h1 {
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.16; /* 29px */
  margin: 0;
}
%h1a {
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.16; /* 29px */
  margin: 0;
  @include media(md) {
    font-size: 4rem;
    font-weight: 400;
    line-height: 0.967;
  }
}
%h2h1 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2; /* 24px */
  margin: 0;
  @include media(md) {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.16; /* 29px */
  }
}
%h2h0 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2; /* 24px */
  margin: 0;
  @include media(md) {
    font-size: 3rem;
    font-weight: 400;
    line-height: 0.967; /* 29px */
  }
}
.h2-lg-h1,
%h2-lg-h1 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2; /* 24px */
  margin: 0;
  @include media(lg) {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.16; /* 29px */
  }
}
%h4h1 {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.1875; /* 19px */
  margin: 0;
  @include media(md) {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.16; /* 29px */
  }
}
%h2 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2; /* 24px */
  margin: 0;
}
%h3 {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.167; /* 21px */
  margin: 0;
}
%h4h3 {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.1875; /* 19px */
  margin: 0;
  @include media(md) {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.167; /* 21px */
  }
}
%h3h1 {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.167; /* 21px */
  margin: 0;
  @include media(md) {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.16; /* 29px */
  }
}
%h3h2 {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.167; /* 21px */
  margin: 0;
  @include media(md) {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2; /* 24px */
  }
}
%h4 {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.1875; /* 19px */
  margin: 0;
}
%h4h2 {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.1875; /* 19px */
  margin: 0;
  @include media(md) {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2; /* 24px */
  }
}

%b_xl {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.1875; /* 19px */
}
%b_xl_l {
  @extend %b_xl;
  font-weight: 300;
}
%b_l {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.143; /* 16px */
}
%b_l_l {
  @extend %b_l;
  font-weight: 300;
}
%b_l_m {
  @extend %b_l;
  font-weight: 500;
}
%b_m_l {
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.154; /* 15px */
}
%b_s {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.167; /* 14px */
}
%b_s_l {
  @extend %b_s;
  font-weight: 300;
}
%b_xs {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.182; /* 13px */
}
%b_xxs {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.172;
}

%b1 {
  @extend %b_l;
}

%h1,
%h2,
%h3,
%h4,
%h5,
%h6,
%h {
  font-family: $ffamilyH;
}

%b1 {
  font-family: $ffamily;
}

.h0 {
  @extend %h0;
}
h1,
.h1 {
  @extend %h1;
}
h2,
.h2 {
  @extend %h2;
}
h3,
.h3 {
  @extend %h3;
}
h4,
.h4 {
  @extend %h4;
}
.b1 {
  @extend %b1;
}
