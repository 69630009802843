.hero {
  &:not(section + section) {
    padding-top: 0;
    padding-bottom: 0;
    // @include media(xl-down) {
    //   padding-top: 0;
    // }
  }
  .brandhead + & {
    @include media(xl) {
      padding-top: 0;
    }
  }
}
