.bnrs {
  &__list {
    @extend %ul_reset;
    display: grid;
    gap: 0.1rem;
    @include media(xl) {
      grid-template-columns: 1fr 1fr 27.3rem;
    }
  }
  &__item {
    display: flex;
    position: relative;
  }
  &__link {
    position: relative;
    display: flex;
    width: 100%;
    &,
    &:hover,
    &:focus {
      color: var(--cl-text);
    }
  }
  &__pic {
    display: block;
    width: 100%;
    height: 34.3rem;
  }
  &__pic-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 40% 80%;
    @include media(md) {
      object-position: 0% 50%;
    }
    @include media(xl) {
      object-position: 20% 70%;
    }
  }
  &__item_special &__pic-img {
    object-position: 50% 50%;
  }
  &__content {
    position: absolute;
    top: 3rem;
    left: 2.5rem;
    right: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__text {
    @extend %h3;
    max-width: 32.5rem;
    @include media(md) {
      max-width: 45rem;
    }
    @include media(xl) {
      max-width: 42rem;
    }
    text-shadow: 1px 1px var(--cl-white);
  }
  &__title {
    @extend %h1;
    text-shadow: 1px 1px var(--cl-white);
  }
  &__item_special &__title {
    text-align: center;
    padding-top: 2rem;
    text-shadow: none;
  }
}
